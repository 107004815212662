import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { PREVIEW_MODE } from '../../../../../others/const';
import Preview from './Preview'
import Data from './Data'
import './index.scss'
import CoffeCup from '../../../../parts/CoffeeCup'

class Template extends Component {

    state = {
        page: 1
    }
    
    setBackgroundColor = backgroundColor => {
        this.props.setSettings({ backgroundColor, backgroundImage: undefined })
        this.setPreviewPage(1)
    }

    setBackgroundImage = backgroundImage => {
        this.props.setSettings({ backgroundImage })
        this.setPreviewPage(1)
    }

    setLogoImage = logoImage => {
        this.props.setSettings({ logoImage })
        this.setPreviewPage(1)
    }

    setCertificateImage = certificateImage => {
        this.props.setSettings({ certificateImage })
        this.setPreviewPage(3)
    }

    setFontPosition = ({ fontPositionX, fontPositionY }) => {
        this.props.setSettings({ _font: { fontPositionX, fontPositionY }})
    }

    setExtraPosition = ({ extraPositionX, extraPositionY }) => {
        this.props.setSettings({ _extra: { extraPositionX, extraPositionY }})
    }

    handleChange = e => {
        const { name, value } = e.target
        this.props.setSettings({ [name]: value })
    }

    setPreviewPage = page => {
        this.setState({ page })
    }
    
    render() {
        const { loading, settings, recipients } = this.props
        
        return (
            <div className='create-template'>
                {loading && <CoffeCup />}
                {!loading &&
                    <Preview
                        {...settings}
                        recipients={recipients}
                        mode={PREVIEW_MODE.template}
                        page={this.state.page}
                        setFontPosition={this.setFontPosition}
                        setExtraPosition={this.setExtraPosition}
                        setPreviewPage={this.setPreviewPage}
                        userData={this.props.userData}
                    />}
                {!loading &&
                    <Data
                        {...settings}
                        disabled={this.props.disabled}
                        setBackgroundColor={this.setBackgroundColor}
                        setBackgroundImage={this.setBackgroundImage}
                        setCertificateImage={this.setCertificateImage}
                        setLogoImage={this.setLogoImage}
                        handleChange={this.handleChange}
                        setPreviewPage={this.setPreviewPage}
                    />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData.payload
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Template))