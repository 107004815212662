import React, { Component } from 'react'
import './Certificate.scss'
import { PREVIEW_MODE } from '../../../../../../others/const';
import { isDevelopment } from '../../../../../../others/util';
import html2canvas from 'html2canvas'
import { 
    postechGolfAlumniList, 
    postechGolfPrefix, 
    bcapitalistList,
    bcapitalistPrefix,
    bcapitalist2nd,
    founders2019Summer,
    posahs
} from '../../../../../../others/temporary';

const LIMIT = 250000
const IMAGE_TYPE = "image/jpeg"

class Certificate extends Component {
    state = {
        height: undefined,
    }

    // TODO
    // 어떻게 더 정리하면 좋을까...
    getDelta = (initLength, length) => {
        if (initLength < LIMIT * 1.1) {
            return 0.001
        }

        if (initLength < LIMIT * 1.5) {
            return 0.01
        }

        if (initLength < LIMIT * 3) {
            return 0.02
        }
        
        if (initLength < LIMIT * 5) {
            return 0.1
        }

        if (length > LIMIT * 5) {
            return 0.2
        }
        else if (length > LIMIT * 1.05) {
            return 0.1
        }
        else {
            return 0.01
        }
    }

    getOptimalImageData = canvas => {
        let quality = 1
        let generated = canvas.toDataURL(IMAGE_TYPE, quality)
        const initLength = generated.length
        while(generated.length > LIMIT) {
            const delta = this.getDelta(initLength, generated.length)
            quality -= delta
            generated = canvas.toDataURL(IMAGE_TYPE, quality) 
        }
        return generated
    }

    onLoad = async e => {
        const { height } = e.currentTarget
        this.setState({ height })

        const element = document.getElementsByClassName('certificate-container')[0]
        const logging = isDevelopment()
        const canvas = await html2canvas(element, { logging })
        const data = this.props.resize 
            ? this.getOptimalImageData(canvas) 
            : canvas.toDataURL(IMAGE_TYPE, 1)

        this.props.callback(data)
    }

    getNameClass = () => {
        if (this.props.mode === PREVIEW_MODE.broof) {
            return 'printed'
        }
        
        return 'drag'
    }

    render() {
        const { height } = this.state
        const projectCode = this.props.projectCode || ""
        const certificateImage = this.props.certificateImage

        const { name } = this.props
        const fontPositionX = this.props.fontPositionX || 0
        const fontPositionY = this.props.fontPositionY || 0

        const { extraInfo } = this.props
        const extraPositionX = this.props.extraPositionX || 0
        const extraPositionY = this.props.extraPositionY || 0
    
        const { fontSize } = this.props

        switch(true) {
            case projectCode.includes(posahs): {
                return (
                    <div className={`certificate-container ${posahs}`} style={{ height }}>
                        <img src={certificateImage} onLoad={this.onLoad} alt='certificate' />
                        <div className={`name`}>
                            {name}
                        </div>
                        <div className={`major`}>
                            {extraInfo}
                        </div>
                    </div>   
                )                
            }
            case projectCode.includes(founders2019Summer): {
                return (
                    <div className={`certificate-container ${founders2019Summer}`} style={{ height }}>
                        <img src={certificateImage} onLoad={this.onLoad} alt='certificate' />
                        <div className={`name`}>
                            {name}
                        </div>
                    </div>   
                )                
            }
            case projectCode.includes(bcapitalist2nd): {
                return (
                    <div className={`certificate-container ${bcapitalist2nd}`} style={{ height }}>
                        <img src={certificateImage} onLoad={this.onLoad} alt='certificate' />
                        <div className={`name`}>
                            {name}
                        </div>
                        <div className={`company`}>
                            {extraInfo}
                        </div>
                    </div>   
                )                
            }
            case projectCode.includes(bcapitalistPrefix): {
                const { code } = this.props
                const bcapitalistData = bcapitalistList.filter(item => item.name === name && item.code === code)[0] || {}
                return (
                    <div className={`certificate-container ${bcapitalistPrefix}`} style={{ height }}>
                        <img src={certificateImage} onLoad={this.onLoad} alt='certificate' />
                        <div className={`name`}>
                            {bcapitalistData.name}
                        </div>
                        <div className={`company`}>
                            {bcapitalistData.company}
                        </div>
                    </div>   
                )                
            }
            case projectCode.includes(postechGolfPrefix): {
                const { code } = this.props
                const postechGolfData = postechGolfAlumniList.filter(item => item.name === name && item.code === code)[0] || {}
                return (
                    <div className={`certificate-container ${postechGolfPrefix}`} style={{ height }}>
                        <img src={certificateImage} onLoad={this.onLoad} alt='certificate' />
                        <div className={`name`}>
                            {postechGolfData.name}
                        </div>
                        <div className={`title`}>
                            {postechGolfData.title}
                        </div>
                        <div className={`date`}>
                            {postechGolfData.date}
                        </div>
                    </div>   
                )
            }            
            default: {
                return (
                    <div className={`certificate-container`} style={{ height }}>
                        <img src={certificateImage} onLoad={this.onLoad} alt='certificate' />
                        <div className={`printed name size-${fontSize}`} style={{
                            top: `${fontPositionY}%`,
                            left: `${fontPositionX}%`
                        }}>
                            <p>{name}</p>
                        </div>
                        <div className={`printed extra`} style={{
                            top: `${extraPositionY}%`,
                            left: `${extraPositionX}%`
                        }}>
                            <p>{extraInfo}</p>
                        </div>
                    </div>
                )
            }                
        }
    }
}

export default Certificate