import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import slide1_1 from '../../../images/slider1-1.png'
import slide1_2 from '../../../images/slider1-2.png'
import slide1_3 from '../../../images/slider1-3.png'
import slide1_4 from '../../../images/slider1-4.png'

import slide2_1 from '../../../images/slider2-1.png'
import slide2_2 from '../../../images/slider2-2.png'
import slide2_3 from '../../../images/slider2-3.png'
import slide2_4 from '../../../images/slider2-4.png'

import withLanguage from '../../../HOC/withLanguage';

import './Slider.scss';

class Slider extends Component {
    render() {
        const { 
            position, 
            i18n 
        } = this.props;
        
        if (position === 'center') {
            return (
                <Carousel showThumbs={false} autoPlay={false} showArrows={false}>
                    <div>
                        <div className='img-box'>
                            <img src={slide1_1} alt='' />
                        </div>
                        <span ref={ref => {if (ref) ref.innerHTML = i18n.slider.work1}}></span>
                    </div>
                    <div>
                        <div className='img-box' style={{ paddingTop: "2px" }}>
                            <img src={slide1_2} alt='' />
                        </div>
                        <span ref={ref => {if (ref) ref.innerHTML = i18n.slider.work2}}></span>
                    </div>
                    <div>
                        <div className='img-box' style={{ paddingTop: "10.3px" }}>
                            <img src={slide1_3} alt='' />
                        </div>
                        <span ref={ref => {if (ref) ref.innerHTML = i18n.slider.work3}}></span>
                    </div>
                    <div>
                        <div className='img-box' style={{ marginTop: "-19px" }}>
                            <img src={slide1_4} alt='' />
                        </div>
                        <span ref={ref => {if (ref) ref.innerHTML = i18n.slider.work4}}></span>
                    </div>
                </Carousel>
            )
        }
        if (position === 'bottom') {
            return (
                <Carousel showThumbs={false} autoPlay={false} showArrows={false}>
                    <div>
                        <img src={slide2_1} alt='' />
                        <span ref={ref => {if (ref) ref.innerHTML = i18n.slider.article1}}></span>
                        <p>{i18n.slider.press1}</p>
                    </div>
                    <div>
                        <img src={slide2_2} alt='' />
                        <span ref={ref => {if (ref) ref.innerHTML = i18n.slider.article2}}></span>
                        <p>{i18n.slider.press2}</p>
                    </div>
                    <div>
                        <img src={slide2_3} alt='' />
                        <span ref={ref => {if (ref) ref.innerHTML = i18n.slider.article3}}></span>
                        <p>{i18n.slider.press3}</p>
                    </div>
                    <div>
                        <img src={slide2_4} alt='' />
                        <span ref={ref => {if (ref) ref.innerHTML = i18n.slider.article4}}></span>
                        <p>{i18n.slider.press4}</p>
                    </div>
                </Carousel>
            )
        }
        return <div />

    }
}

export default withLanguage(Slider)