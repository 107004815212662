import { createAction, handleActions } from 'redux-actions';
import { getLanguage } from '../others/util';

export const SET_USER_DATA = 'SET_USER_DATA';
export const GET_USER_DATA = createAsyncActionType('GET_USER_DATA');
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_POPUP = 'SET_POPUP';
export const GET_HISTORY_LIST = createAsyncActionType('GET_HISTORY_LIST')
export const GET_PROJECT_LIST = createAsyncActionType('GET_PROJECT_LIST');
export const ADD_PROJECT = createAsyncActionType('ADD_PROJECT');
export const CHANGE_PROJECT_STATE = createAsyncActionType('CHANGE_PROJECT_STATE');
export const SAVE_PROJECT = createAsyncActionType('SAVE_PROJECT');
export const ISSUE_PROJECT = createAsyncActionType('ISSUE_PROJECT');
export const COPY_PROJECT = createAsyncActionType('COPY_PROJECT');
export const DELETE_PROJECT = createAsyncActionType('DELETE_PROJECT');
export const GET_BROOF = createAsyncActionType('GET_BROOF');
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_TOAST = 'SET_TOAST'
export const ADD_TOAST = 'ADD_TOAST'
export const CLEAR_TOAST = 'CLEAR_TOAST'

export const setUserData = createAction(SET_USER_DATA);
export const getUserData = createAsyncAction(GET_USER_DATA);
export const clearUserData = createAction(CLEAR_USER_DATA);
export const setLoggedIn = createAction(SET_LOGGED_IN);
export const setPopup = createAction(SET_POPUP);
export const getHistoryList = createAsyncAction(GET_HISTORY_LIST);
export const getProjectList = createAsyncAction(GET_PROJECT_LIST);
export const changeProjectState = createAsyncAction(CHANGE_PROJECT_STATE);
export const issueProject = createAsyncAction(ISSUE_PROJECT);
export const copyProject = createAsyncAction(COPY_PROJECT);
export const deleteProject = createAsyncAction(DELETE_PROJECT);
export const getBroof = createAsyncAction(GET_BROOF);
export const setLanguage = createAction(SET_LANGUAGE);
export const setToast = createAction(SET_TOAST);
export const addToast = createAction(ADD_TOAST);
export const clearToast = createAction(CLEAR_TOAST);

const initialUserData = { loading: false, payload: {} }
const initialLoggedIn = { payload: false }
const initialPopup = { loading: false, payload: undefined }
const initialHistoryList = { loading: false, payload: { list: [] } }
const initialProjectList = { loading: true, payload: { list: {} } }
const initialBroof = { loading: true, payload: {} }
const initialLanguage = { payload: getLanguage() }
const initialToast = { payload: [] }

export const initialState = {
    userData: initialUserData,
    loggedIn: initialLoggedIn,
    popup: initialPopup,
    historyList: initialHistoryList,
    projectList: initialProjectList,
    broof: initialBroof,
    language: initialLanguage,
    toast: initialToast,
}

export const reducer = handleActions({
    ...handleAction(SET_USER_DATA, 'userData'),
    ...handleAsyncAction(GET_USER_DATA, 'userData'),
    ...handleAction(CLEAR_USER_DATA, 'userData', initialUserData),
    ...handleAction(SET_LOGGED_IN, 'loggedIn'),
    ...handleAction(SET_POPUP, 'popup'),
    ...handleAsyncAction(GET_HISTORY_LIST, 'historyList'),
    ...handleAsyncAction(GET_PROJECT_LIST, 'projectList'),
    ...handleAsyncAction(ADD_PROJECT, 'projectList'),
    ...handleAsyncAction(CHANGE_PROJECT_STATE, 'projectList'),
    ...handleAsyncAction(SAVE_PROJECT, 'projectList'),
    ...handleAsyncAction(ISSUE_PROJECT, 'projectList'),
    ...handleAsyncAction(COPY_PROJECT, 'projectList'),
    ...handleAsyncAction(DELETE_PROJECT, 'projectList'),
    ...handleAsyncAction(GET_BROOF, 'broof'),
    ...handleAction(SET_LANGUAGE, 'language'),
    ...handleAction(SET_TOAST, 'toast'),
}, initialState)

function createAsyncActionType(name) {
    return {
        INDEX: `${name}_INDEX`,
        REQUEST: `${name}_REQUEST`,
        SUCCESS: `${name}_SUCCESS`,
        FAILURE: `${name}_FAILURE`,
    }
}

function createAsyncAction(action) {
    let asyncAction = createAction(action.INDEX)
    asyncAction.request = createAction(action.REQUEST)
    asyncAction.success = createAction(action.SUCCESS)
    asyncAction.failure = createAction(action.FAILURE)
    return asyncAction
}

function handleAction(action, reducer, initial) {
    return {
        [action]: (state, { payload }) => ({
            ...state,
            [reducer]: initial ? initial : {
                ...state[reducer],
                payload,
            }
        })
    }
}

function handleAsyncAction(action, reducer) {
    return {
        [action.REQUEST]: (state, { payload }) => ({
            ...state,
            [reducer]: {
                loading: payload,
                payload: state[reducer].payload
            }
        }),
        [action.SUCCESS]: (state, { payload }) => ({
            ...state,
            [reducer]: {
                loading: false,
                payload,
            }
        }),
        [action.FAILURE]: (state, { payload }) => ({
            ...state,
            [reducer]: {
                loading: false,
                payload: state[reducer].payload,
                error: payload
            }
        })
    }
}