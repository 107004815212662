import React from 'react'
import Button, { ButtonType } from './Button'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './Header.scss'
import { setLoggedIn } from '../../redux';
import { ROUTE, LANGUAGE } from '../../others/const';
// import { FaUserCircle } from 'react-icons/fa';
import { FaGlobeAmericas, FaGlobeAsia } from 'react-icons/fa';
import broofLogo from '../../images/broof_logo.png';
import withLanguage from '../../HOC/withLanguage';
import ApiManager from '../../ApiManager';

const Header = ({ loggedIn, setLoggedIn, history, location, userData, i18n, language, setLanguage }) => {
    const isHome = location.pathname === ROUTE.home
    const { username } = userData
    const isKorean = language === LANGUAGE.KO
    const isMobile = window.innerWidth < 500

    return (
        <div className={`header-container${isHome ? ' home' : ' logged-in'}`}>
            <div
                className={`front${isHome ? '' : ' cursor'}`}
                onClick={() => { history.push(ROUTE.home) }}
            >
                <img src={broofLogo} alt='logo'></img>
            </div>
            {!isMobile && <div className='middle'>
                <Button
                    content={i18n.header.issue}
                    type={ButtonType.TEXT}
                    style={{ width: 60 }}
                    color='#fff'
                    onClick={() => {
                        if (loggedIn) {
                            history.push(`${ROUTE.project}`)
                        }
                        else {
                            history.push(`${ROUTE.login}?redirect=certificate`)
                        }
                    }}
                />
                <Button
                    content={i18n.header.plans}
                    type={ButtonType.TEXT}
                    color='#fff'
                    onClick={() => {
                        history.push(`${ROUTE.payment}`)
                    }}
                />
            </div>}
            <div className='back'>
                {isHome && !loggedIn && !isMobile &&
                    <Button
                        content={i18n.header.signup}
                        type={ButtonType.TEXT}
                        color='#fff'
                        onClick={() => {
                            history.push(ROUTE.signup)
                        }}
                    />
                }
                {isHome && !loggedIn && !isMobile &&
                    <Button
                        content={i18n.header.login}
                        type={ButtonType.FILL}
                        color='white'
                        onClick={() => {
                            history.push(ROUTE.login)
                        }}
                    />
                }
                {isHome && loggedIn && !isMobile &&
                    <Button
                        content={i18n.header.logout}
                        type={ButtonType.FILL}
                        color='#fff'
                        onClick={() => {
                            ApiManager.unsetToken()
                            setLoggedIn(false)
                            history.push(ROUTE.login)
                        }}
                    />
                }
                {!isHome && loggedIn &&
                    <div
                        className='user'
                        onClick={() => {
                            history.push(ROUTE.information)
                        }}
                    >
                        {/* {username}<FaUserCircle/> */}
                        {username}
                    </div>
                }
                <Button
                    content={<div>
                        <span style={{
                            position: 'relative',
                            top: 1.5,
                            marginRight: 7
                        }}>
                            {isKorean ? <FaGlobeAsia/> : <FaGlobeAmericas/>}
                        </span> 
                        {language.toUpperCase()}
                    </div>}
                    type={ButtonType.TEXT}
                    color='white'
                    onClick={() => {
                        if (isKorean) {
                            setLanguage(LANGUAGE.EN)
                        }
                        else {
                            setLanguage(LANGUAGE.KO)
                        }
                    }}
                />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn.payload,
        userData: state.userData.payload
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLoggedIn: payload => dispatch(setLoggedIn(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Header)))