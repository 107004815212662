import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { addToast, clearToast } from '../../../redux'
import withLanguage from '../../../HOC/withLanguage';
import { MdClose } from 'react-icons/md'
import './index.scss'
import { delay } from '../../../others/util';
import { TOAST_TIME } from '../../../others/const';

const Item = ({ id, clearToast, text, doLater, error, i18n }) => {
    let destroyed = false
    const [out, setOut] = useState(false)

    const clearItself = async () => {
        setOut(true)
        await delay(500) // index.scss 에 정의된 애니메이션 타이밍
        clearToast(id)
    }

    useEffect(() => {
        (async () => {
            await delay(TOAST_TIME)
            if (!destroyed) {
                clearItself()
            }
        })();

        return () => {
            destroyed = true
        }
    }, [id])

    return (
        <div className={['toast-item', error && 'error', out && 'out'].join(' ')}>
            <div>{text}</div>
            {doLater && <div className='undo'>{i18n.toast.undo}</div>}
            <div className='toast-close'
                onClick={() => { clearItself() }}
            >
                <MdClose />
            </div>
        </div>
    )
}

const Toast = ({ toast, i18n, clearToast }) => {
    return (
        <div className='toast-area'>
            {toast.map(item => (
                <Item key={item.id} i18n={i18n} {...item} clearToast={clearToast} />
            ))}
        </div>
    )
}

function mapStateToProps(state) {
    return { toast: state.toast.payload }
}

function mapDispatchToProps(dispatch) {
    return {
        addToast: (payload) => dispatch(addToast(payload)),
        clearToast: (payload) => dispatch(clearToast(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Toast)))
