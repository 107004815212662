import React, { Component } from 'react'
import './Page2.scss'
import moment from 'moment'
import { COLOR_SET, PREVIEW_MODE } from '../../../../../../others/const';
import Certificate from './Certificate';
import { delay, getExtraInfo } from '../../../../../../others/util';
import ApiManager from '../../../../../../ApiManager';

class Page2 extends Component {
    
    certificateLoaded = async data => {
        await delay(1000)
        const { sample } = this.props
        if (sample) {
            this.props.setPreviewPage(3)
            return
        }

        const { projectCode, name, code, i18n } = this.props
        const { ERROR } = i18n
        const { result, error } = await ApiManager.postBroofs({ projectCode, name, code, data })

        if (result) {
            const { transactionHash } = result
            const extraInfo = getExtraInfo(result)
            this.props.updateState('extraInfo', extraInfo)
            this.props.updateState('transactionHash', transactionHash)
            this.props.setPreviewPage(3)
        }
        else if (error) {
            alert(`${this.props.type} ${ERROR[error]}`)
            this.props.setPreviewPage(1)
        }
    }

    render() {
        const { i18n, mode } = this.props
        const backgroundColor = this.props.backgroundColor || i18n.TEMPLATE_SETTINGS.backgroundColor
        const type = this.props.type || i18n.TEMPLATE_SETTINGS.type
        const projectName = this.props.projectName || i18n.TEMPLATE_SETTINGS.projectName
        const color = backgroundColor === COLOR_SET.white ? '#000' : '#fff'
        const ownerAlias = this.props.ownerAlias || this.props.userData.alias || i18n.TEMPLATE_SETTINGS.ownerAlias

        return (
            <div className='project-create-preview-page two' style={{ color }}>
                {mode === PREVIEW_MODE.broof && 
                    <Certificate {...this.props} callback={this.certificateLoaded} delay resize/>
                }
                <div className='background' style={{ backgroundColor }}></div>
                <div
                    className='top half'
                    style={{ color }}
                    ref={ref => { if (ref) ref.innerHTML = i18n.page2.issuing(type) }}
                >
                </div>
                <div className='bottom half'>
                    <div className='detail'>
                        <div
                            className='title'
                            style={{ color }}
                        >
                            {i18n.page2.record}
                        </div>
                        <table style={{ color }}>
                            <tbody>
                                <tr>
                                    <td>{i18n.page2.alias}</td>
                                    <td>{ownerAlias}</td>
                                </tr>
                                <tr>
                                    <td>{i18n.page2.time}</td>
                                    <td>{moment().format('YYYY.MM.DD HH:mm:ss')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n.page2.contents}</td>
                                    <td>{this.props.name || i18n.page1.placeholderName}{i18n.page2.from} <br /> {projectName} {type}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Page2