import React from 'react'
import './InputSet.scss'

const InputSet = ({ name, title, context, type, value, handleChange, handleKeyPress, handleFocus, handleBlur, error, msg, eye, check }) => (
    <div className={`parts-input-set${context ? ` ${context}` : ''}`}>
        <span className={error && "error"}>{title}</span>
        <input
            className={`parts-input${error ? ' error' : ''}`} // TODO 유틸로 만들기
            type={type}
            name={name}
            value={value}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
        {check}
        {msg && <p className="msg">{msg}</p>}
        {eye && <div className="eye">{eye}</div>}
        {error && <p ref={ref => { if (ref) ref.innerHTML = error }}>{error}</p>}
    </div>
)

export default InputSet;