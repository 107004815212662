import axios from 'axios'
import { API_HOST, CUSTOM_EVENT, PROJECT_STATE, EXPIRED_JWT_MESSAGES } from "../others/const";
import { execute, transformServerLanguage, devConsole } from '../others/util';
import jsonwebtoken from 'jsonwebtoken'
import moment from 'moment';

const StorageKey = 'broof-jwt'
const LoginDuration = 7 * 24 * 60 * 60 // 7 Days
const LoginExpiredError = 'LOGIN_EXPIRED'
const RefreshRatio = 1 - (1 / 6)

// TODO
// 전체 api 에 토큰 만료, 네트워크 단절 케이스 추가
class ApiManager {
    constructor(baseURL, jwt) {
        this.instance = axios.create({ baseURL });
        if (jwt) {
            this.setToken(jwt)
        }

        this.loginTime = undefined
    }

    setToken = token => {
        this.jwt = token
        this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem(StorageKey, token)
    }

    unsetToken = () => {
        this.jwt = undefined
        this.instance.defaults.headers.common['Authorization'] = undefined
        localStorage.removeItem(StorageKey)
     
        this.loginTime = undefined
    }

    refreshToken = async oldToken => {
        devConsole('%coldToken', "font-weight: bold; color: red;", oldToken.substr(-10))
        const response = await this.instance.get(`/api/v0/utilities/token-refresh/${oldToken}`)
        const { data: newToken } = response.data
        this.setToken(newToken)
        devConsole('%cnewToken', "font-weight: bold; color: blue;", newToken.substr(-10))
    }

    checkTime = async () => {
        if (!this.jwt) return

        const now = moment().unix()
        if (!this.loginTime) {
            this.loginTime = now
        }
        else if (now - this.loginTime > LoginDuration) {
            throw Error(LoginExpiredError)
        }

        if (this.jwt) {
            const { exp, iat } = jsonwebtoken.decode(this.jwt)
            const expireIn = exp - iat
            const untilExpire = exp - now
            if (untilExpire > 0 && untilExpire < expireIn * RefreshRatio) {
                await this.refreshToken(this.jwt)
            }
        }
    }

    callApi = async ({ method, url, body, config, handleResponse, handleError }, errorTest) => {
        try {
            if (errorTest && url.includes(errorTest)) {
                throw Error()
            }
            
            await this.checkTime()

            if (this.jwt) {
                const splitted = url.split('/')
                devConsole(`%c/${splitted[splitted.length - 1]}`, "font-weight: bold;", this.jwt.substr(-10))    
            }

            const response = await this.instance[method](url, body, config)
            const { status } = response
            if (status >= 200 && status <= 299) {
                return execute(handleResponse, response)
            }
            else {
                throw Error({ response })
            }
        }
        catch (e) {
            // console.error(e)

            if (e.message === LoginExpiredError || (
                e.response && 
                e.response.status === 401 &&
                e.response.data && 
                EXPIRED_JWT_MESSAGES.includes(e.response.data.message))) {
                window.dispatchEvent(new Event(CUSTOM_EVENT.expired))
                return {}
            }

            return execute(handleError, e)
        }
    }

    postUsersLogin = async ({ password, username }) => {
        return await this.callApi({
            method: 'post',
            url: '/api/v0/users/login',
            body: { password, username },
            handleResponse: response => {
                this.loginTime = moment().unix()
                const { user: result, jwt } = response.data.data
                this.setToken(jwt)
                return { result }
            },
            handleError: e => {
                if (e.response && e.response.status === 401) {
                    switch (e.response.data.message) {
                        case 'Invalid username or password':
                            return { error: 'invalidPassword' }
                        case 'Account does not exist':
                            return { error: 'noUsername' }
                        default:
                    }
                }
                else if (e.response && e.response.status === 403) {
                    return { error: 'unauthorized' }
                }

                return { error: 'networkError' }
            }
        })
    }

    getProducts = async () => {
        return await this.callApi({
            method: 'get',
            url: '/api/v0/products',
            handleResponse: response => { 
                return response.data.data 
            },
            handleError: () => { 
                return [] 
            }
        })
    }

    getBroofsStatsCurrent = async () => {
        return await this.callApi({
            method: 'get',
            url: '/api/v0/broofs/stats/current',
            handleResponse: response => { 
                return response.data.data 
            },
            handleError: () => {
                return {
                    latestBroofs: [],
                    totalBroofsIssued: 0
                }
            }
        })
    }

    getUsersCurrentBasicInfo = async () => {
        return await this.callApi({
            method: 'get',
            url: '/api/v0/users/current/basic-info',
            handleResponse: response => {
                const { data: result } = response.data
                return { result }
            },
            handleError: () => { 
                return {} 
            }
        })
    }

    postUsersCurrentPassword = async ({ currentPassword, newPassword }) => {
        return await this.callApi({
            method: 'post',
            url: '/api/v0/users/current/password',
            body: { currentPassword, newPassword },
            handleResponse: response => { 
                return response.data 
            },
            handleError: e => {
                if (e.response && e.response.data) {
                    switch (e.response.data.message) {
                        case 'The current password is wrong':
                            return { error: 'invalidPassword' }
                        case 'New password must be different from current password. Please try again.':
                            return { error: 'differentPassword' }
                        default:
                    }
                }

                return { error: 'networkError' }
            }
        })
    }

    getUtilitiesIcxPrice = async () => {
        return await this.callApi({
            method: 'get',
            url: '/api/v0/utilities/icx-price',
            handleResponse: response => { 
                const { currentIcxPrice: result } = response.data
                return { result }
            },
            handleError: () => { 
                return { error: 'icxFailed' } 
            }
        })
    }

    postUsersSignup = async (payload, language) => {
        return await this.callApi({
            method: 'post',
            url: `/api/v0/users/signup/${transformServerLanguage(language)}`,
            body: payload,
            handleResponse: response => { 
                const { data: result } = response.data
                return { result }
            },
            handleError: e => {
                if (e.response && e.response.data) {
                    switch (e.response.data.message) {
                        case 'Username already exists. Please choose a different username':
                            return { error: 'alreadyUsername' }
                        default:
                    }
                }
    
                return { error: 'networkError' }
            }
        })
    }

    getProjects = async () => {
        return await this.callApi({
            method: 'get',
            url: `/api/v0/projects`,
            handleResponse: response => {
                const { data: result } = response.data
                return { result }
            },
            handleError: () => { 
                return { result: [] } 
            }
        })
    }

    postProjectsPausedStatus = async ({ projectId, shouldPause }) => {
        return await this.callApi({ 
            method: 'post',
            url: `/api/v0/projects/${projectId}/paused-status`,
            body: { shouldPause },
            handleResponse: () => {
                return { result: shouldPause ? PROJECT_STATE.PAUSED : PROJECT_STATE.IN_PROGRESS }
            },
            handleError: () => { 
                return { error: 'changeFailed' }
            }
        })
    }

    getUsersCurrentTransactions = async () => {
        return await this.callApi({
            method: 'get',
            url: '/api/v0/users/current/transactions',
            handleResponse: response => {
                const { content: result } = response.data
                return { result }            
            },
            handleError: () => {
                return { error: 'loadFailed' }
            }
        })
    }

    projectClone = async ({ projectId }) => {
        return await this.callApi({
            method: 'post',
            url: `/api/v0/projects/clone/${projectId}`,
            handleResponse: response => {
                const { data: result } = response.data
                return { result }
            },
            handleError: () => {
                return { error: true }
            }
        })
    }

    deleteProjects = async ({ projectId }) => {
        return await this.callApi({
            method: 'post',
            url: `/api/v0/projects/deletion/${projectId}`,
            handleResponse: () => {
                return { result: true }            
            },
            handleError: () => {
                return { error: true }
            }
        })
    }

    postProjectsSettings = async ({ projectId, body }) => {
        return await this.callApi({
            method: 'post',
            url: `/api/v0/projects/${projectId}/settings`,
            body,
            handleResponse: () => {
                return { result: true }            
            },
            handleError: () => {
                return { error: true }
            }
        })
    }

    postUsersCurrentTransactions = async payload => {
        return await this.callApi({
            method: 'post',
            url: '/api/v0/users/current/transactions',
            body: payload,
            handleResponse: () => {
                return { result: true }
            },
            handleError: e => {
                if (e.response && e.response.status) {
                    switch (e.response.status) {
                        case 409:
                            return { error: 'paymentAlreadyUsed' }
                        case 404:
                            return { error: 'paymentNotExist' }
                        case 502:
                            return { error: 'invalidResponse' }
                        case 417:
                            return { error: 'paymentDiffer' }
                        case 412:
                            return { error: 'paymentStillProcessed' }
                        default:
                    }

                    return { error: 'paySuccessCountFailure' }      
                }
            }
        })
    }

    getProjectsAvailability = async ({ projectCode, projectId }) => {
        return await this.callApi({
            method: 'get',
            url: `/api/v0/projects/availability/${projectCode}/${projectId}`,
            handleResponse: response => {
                return response.data.data
            },
            handleError: () => {
                return false
            }
        })
    }

    postProjects = async ({ projectId, recipients, settings }) => {
        return await this.callApi({ 
            method: 'post',
            url: `/api/v0/projects/${projectId}`,
            body: { recipients, settings },
            handleResponse: response => {
                const { data: result } = response.data
                return { result }
            },
            handleError: () => {
                return { error: 'saveFailed' }
            }
        })
    }

    postProjectsPurchase = async ({ projectId }) => {
        return await this.callApi({ 
            method: 'post',
            url: `/api/v0/projects/purchase/${projectId}`,
            handleResponse: response => {
                const { data: result } = response.data
                return { result }
            },
            handleError: () => {
                return { error: 'issueFailed' }
            }
        })
    }

    getProjectsSettings = async ({ projectId }) => {
        return await this.callApi({ 
            method: 'get',
            url: `/api/v0/projects/${projectId}/settings`,
            handleResponse: response => {
                const { data: result } = response.data
                return { result }
            },
            handleError: () => {
                return { error: true }
            }
        })
    }

    getProjectsRecipients = async ({ projectId }) => {
        return await this.callApi({ 
            method: 'get',
            url: `/api/v0/projects/${projectId}/recipients?pageSize=${500}`,
            handleResponse: response => {
                const { content } = response.data
                return {
                    result: (content || []).map(item => ({
                        broofId: item.broofId,
                        name: item.recipientName,
                        code: item.recipientCode,
                        extra: item.extra,
                        issueAt: item.issueAt,
                        transactionHash: item.transactionHash,
                    }))
                }
            },
            handleError: () => {
                return { result: [] }
            }
        })
    }

    getProjectsCodeSettings = async ({ projectCode }) => {
        return await this.callApi({ 
            method: 'get',
            url: `/api/v0/projects/code/${projectCode}/settings`,
            handleResponse: response => {
                const { data: result } = response.data
                return { result }
            },
            handleError: () => {
                return { error: true }
            }
        })
    }

    postProjectsImage = async ({ projectId, files, type }) => {
        const body = new FormData();
        body.append("img", files.fileList[0])
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };

        return await this.callApi({ 
            method: 'post',
            url: `/api/v0/projects/${projectId}/images/${type}`,
            body,
            config,
            handleResponse: () => {
                return { result: true }
            },
            handleError: () => {
                return { error: true }
            }
        })
    }

    postProjectsRecipients = async ({ projectId, body }) => {
        return await this.callApi({ 
            method: 'post',
            url: `/api/v0/projects/${projectId}/recipients`,
            body,
            handleResponse: () => {
                return { result: true }
            },
            handleError: () => {
                return { error: true }
            }
        })
    }

    postProjectsRecipientsDeletionGroup = async ({ projectId, body }) => {
        return await this.callApi({ 
            method: 'post',
            url: `/api/v0/projects/${projectId}/recipients/deletion/group`,
            body,
            handleResponse: () => {
                return { result: true }
            },
            handleError: () => {
                return { error: true }
            }
        })
    }

    postProjectsRecipientsDeletionAll = async ({ projectId }) => {
        return await this.callApi({ 
            method: 'post',
            url: `/api/v0/projects/${projectId}/recipients/deletion/all`,
        })
    }

    postUsersEmailAccountVerification = async (token, language) => {
        return await this.callApi({ 
            method: 'post',
            url: `/api/v0/users/email/account-verification/${transformServerLanguage(language)}`,
            body: { token }
        })
    }

    getUsersVerification = async token => {
        return await this.callApi({ 
            method: 'get',
            url: `/api/v0/users/verification/${token}`,
            handleResponse: response => {
                const { data: result } = response.data
                return { result }
            },
            handleError: e => {
                if (e.response &&
                    e.response.status === 403 &&
                    e.response.data.message.includes('Expired token')) {
                    return { error: 'expiredToken' }
                }
                else {
                    return { error: 'invalidToken' }
                }
            }
        })
    }

    getBroofs = async ({ projectCode, code, name }) => {
        return await this.callApi({
            method: 'get',
            url: `/api/v0/broofs/${projectCode}?code=${code}&name=${name}`,
            handleResponse: response => { 
                const { data: result } = response.data
                return { result }
            },
            handleError: () => { 
                return { error: 'invalidUser' } 
            }
        })
    }

    postBroofs = async ({ projectCode, code, data, name }) => {
        return await this.callApi({
            method: 'post',
            url: `/api/v0/broofs/${projectCode}?code=${code}&name=${name}`,
            body: { code, data, name },
            handleResponse: response => { 
                const { data: result } = response.data
                return { result }
            },
            handleError: e => {
                if (e.response && e.response.data) {
                    switch (e.response.data.message) {
                        case 'The project is currently not ongoing. Unable to issue broof':
                            return { error: 'projectExpired' }
                        case 'Project is currently paused. Please contact the project administrator':
                            return { error: 'projectPaused' }
                        case 'Project has not started yet. Please wait until the start date.':
                            return { error: 'projectStandby' }
                        case 'The owner of this project currently has no balance.':
                            return { error: 'paymentRequired' }
                        default:
                    }
                }

                return { error: 'transactionFailed' }
            }
        })
    }
}

export default new ApiManager(API_HOST, localStorage.getItem(StorageKey))
