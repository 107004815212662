import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import './DivInput.scss'
import moment from 'moment';

class DivInput extends Component {
    state = {
        value: '',
        typing: !this.props.divFocus,
        error: false,
    }

    inputElement = undefined

    componentDidMount() {
        if (this.props.focus) {
            this.inputFocus()
        }
    }

    inputFocus = () => {
        if (this.inputElement) {
            this.inputElement.focus()
        }
    }

    onDivClick = () => {
        this.setState({ typing: true }, this.inputFocus)
    }

    handleChange = e => {
        const {
            setValue,
            onChangeCallback
        } = this.props

        const { value } = e.target
        this.setState({ value, error: false }, () => {
            if (typeof onChangeCallback === 'function') {
                onChangeCallback(value)
            }
            if (typeof setValue === 'function') {
                setValue(value)
            }
        })
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleEnter()
        }
    }

    handleEnter = async () => {
        const {
            setValue,
            regexr,
            validator
        } = this.props
        const value = this.state.value || this.props.value || ""
        if (!value ||
            (regexr && !regexr.test(value)) ||
            (validator && !validator(value))) {
            this.setState({ error: true })
        }
        else if (typeof setValue === 'function') {
            this.setState({ typing: false, error: false }, () => {
                setValue(value)
            })
        }
    }

    handleDate = (date) => {
        const {
            onChangeCallback, moment, format
        } = this.props
        const value = moment(date).format(format)
        this.setState({ value, error: false }, () => {
            onChangeCallback(value)
            this.handleEnter()
        })
    }

    render() {
        const {
            className,
            title,
            div,
            input,
            placeholder,
            date
        } = this.props

        const {
            typing,
            error,
        } = this.state

        const value = this.state.value || this.props.value || ""

        if ((div || !typing) && !input) {
            return (
                <div
                    className={`div-input${className ? ` ${className}` : ``}${div ? ` disabled` : ``}`}
                    onClick={this.onDivClick}
                    title={title}
                >
                    {value}
                </div>
            )
        }
        else if (date) {
            const { minDate, maxDate } = date
            const format = "yyyy.MM.dd"
            return (
                <DatePicker
                    className={`div-input${className ? ` ${className}` : ``}${error ? ` error` : ``}`}
                    placeholderText={placeholder}
                    onKeyPress={this.handleKeyPress}
                    onBlur={this.handleEnter}
                    selected={moment(value, format).isValid() ? new Date(value) : ''}
                    onChange={this.handleDate}
                    dateFormat={format}
                    minDate={minDate}
                    maxDate={maxDate}                    
                />
            )
        }
        else {
            return (
                <input
                    className={`div-input${className ? ` ${className}` : ``}${error ? ` error` : ``}`}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    onBlur={this.handleEnter}
                    value={value}
                    placeholder={placeholder}
                    ref={ref => { this.inputElement = ref }}
                    onClick={this.props.onClick}
                />
            )
        }
    }
}

export default DivInput