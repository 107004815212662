import { put, takeLatest, call, all, select } from 'redux-saga/effects'
import { GET_HISTORY_LIST, getHistoryList, addToast } from '../redux';
import ApiManager from '../ApiManager'
import i18n from '../others/i18n';

function* getHistoryListAsync() {
    yield put(getHistoryList.request(false))

    const { result, error } = yield call(ApiManager.getUsersCurrentTransactions)        
    if (result) {
        yield put(getHistoryList.success({ list: result }))
        return
    }

    yield put(getHistoryList.failure(error))
        
    const language = yield select(state => state.language.payload)
    const text = i18n[language].ERROR[error]
    if (text) {
        yield put(addToast({ text, error: !result }))
    }
}

function* watchGetHistoryList() {
    yield takeLatest(GET_HISTORY_LIST.INDEX, getHistoryListAsync)
}

export default function* root() {
    yield all([
        watchGetHistoryList(),
    ])
}