import React, { Component } from 'react'
import { MdDelete, MdModeEdit, MdCheckBox, MdCheck, MdCheckBoxOutlineBlank, MdIndeterminateCheckBox, MdLaunch } from 'react-icons/md'
import './Table.scss'
import { TRACKER_URL } from '../../others/const';
import { codeInputTest } from '../../others/regexr';
import { getRandomSerial, pad } from '../../others/util';
import withLanguage from '../../HOC/withLanguage';

class Table extends Component {
    getAllCheckedIcon = () => {
        const { checkedList } = this.props
        if (checkedList.length === 0) {
            return MdCheckBoxOutlineBlank
        }

        const { recipients } = this.props
        const availableList = recipients.filter(item => !item.transactionHash)
        if (checkedList.length < availableList.length) {
            return MdIndeterminateCheckBox
        }

        return MdCheckBox
    }

    render() {
        const { className, checkedList, list, disabled, i18n } = this.props
        const AllCheckedIcon = this.getAllCheckedIcon()
        return (
            <div className={`parts-table${className ? ` ${className}` : ``}`}>
                <table className='head'>
                    <thead>
                        <tr>
                            <th>
                                <div
                                    className={disabled ? 'disabled' : ''}
                                    onClick={() => { this.props.toggleAllChecked() }}
                                >
                                    <AllCheckedIcon />
                                </div>
                            </th>
                            <th></th>
                            <th>{i18n.table.name}</th>
                            <th>{i18n.table.password}</th>
                            <th>{i18n.table.extra}</th>
                            <th>{i18n.table.status}</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
                <div className='body-wrapper'>
                    <table>
                        <tbody>
                            {list.map((item, index) => {
                                const { broofId } = item
                                const checked = checkedList.includes(broofId)
                                return <TableData
                                    key={broofId}
                                    index={index}
                                    item={item}
                                    checked={checked}
                                    toggleChecked={this.props.toggleChecked}
                                    updateRecipients={this.props.updateRecipients}
                                    deleteRecipients={this.props.deleteRecipients}
                                    removeTemporary={this.props.removeTemporary}
                                    disabled={this.props.disabled}
                                />
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

// TODO 밖으로 뺄 것인가?
class TableData extends Component {
    state = {
        edit: this.props.item.temporary,
        nameEdit: this.props.item.name || '',
        nameError: false,
        extraInfoEdit: this.props.item.extraInfo || '',
        extraInfoError: false,
        codeEdit: this.props.item.code || pad(getRandomSerial()),
        codeError: false,
    }

    handleChange = e => {
        const { name, value } = e.target
        if (name === 'codeEdit' && !codeInputTest.test(value)) {
            return
        }
        this.setState({ [name]: value })
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.update()
        }
    }

    toggleEdit = () => {
        const { edit } = this.state
        if (edit) {
            this.update()
        }
        else {
            this.setState({ edit: true })
        }
    }

    update = () => {
        const { 
            nameEdit, 
            extraInfoEdit,
            codeEdit,
        } = this.state
        
        let nameError = false, 
            extraInfoError = false,
            codeError = false 

        if (nameEdit === '') {
            nameError = true
        }
        // if (extraInfoEdit === '') {
        //     extraInfoError = true
        // }
        if (codeEdit === '') {
            codeError = true
        }

        if (nameError) {
            this.nameInput.focus()
        }
        else if (extraInfoError) {
            this.extraInfoInput.focus()
        }
        else if (codeError) {
            this.codeInput.focus()
        }

        if (nameError || extraInfoError || codeError) {
            this.setState({ nameError, extraInfoError, codeError })
            return
        }

        const { item, index } = this.props
        const { broofId, transactionHash, temporary } = item

        this.setState({ edit: false }, () => {
            const recipient = {
                broofId,
                name: nameEdit,
                extraInfo: extraInfoEdit,
                code: codeEdit,
                transactionHash,
            }
            if (temporary) {
                this.props.removeTemporary()
                recipient.temporary = true
                this.props.updateRecipients([recipient])
            }
            else {
                recipient.index = index
                this.props.updateRecipients([recipient])
            }
        })
    }

    remove = () => {
        if (this.props.item.temporary) {
            this.props.removeTemporary()
        }
        else {
            this.props.deleteRecipients([this.props.item.broofId])
        }
    }

    // TODO
    // 변수명 헷갈린다 다시 해라
    getDisabled = () => {
        const { transactionHash, temporary } = this.props.item

        return {
            checkBox: temporary,
            txLink: !transactionHash,
            editPen: !!transactionHash,
        }
    }

    render() {
        const { item, checked, toggleChecked, disabled, index } = this.props
        const { edit, nameEdit, nameError, extraInfoEdit, extraInfoError, codeEdit, codeError } = this.state
        const { broofId, name, extraInfo, code, transactionHash } = item
        const { checkBox, txLink, editPen, removeX } = this.getDisabled()
        return (
            <tr key={broofId}>
                <td>
                    <div
                        className={(checkBox || disabled) ? 'disabled' : ''}
                        onClick={() => { toggleChecked(broofId) }}
                    >
                        {checked ?
                            <MdCheckBox />
                            :
                            <MdCheckBoxOutlineBlank />
                        }
                    </div>
                </td>
                <td>{index + 1}</td>
                <td>
                    {edit ?
                        <input
                            autoFocus="autoFocus"
                            ref={ref => this.nameInput = ref}
                            name='nameEdit'
                            className={nameError ? 'error' : ''}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            value={nameEdit}
                        />
                        :
                        name
                    }
                </td>
                <td>
                    {edit ?
                        <input
                            ref={ref => this.codeInput = ref}
                            name='codeEdit'
                            className={codeError ? 'error' : ''}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            value={codeEdit}
                        />
                        :
                        code
                    }
                </td>
                <td>
                    {edit ?
                        <input
                            ref={ref => this.extraInfoInput = ref}
                            name='extraInfoEdit'
                            className={extraInfoError ? 'error' : ''}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            value={extraInfoEdit}
                        />
                        :
                        extraInfo
                    }
                </td>
                <td>
                    <div
                        className={txLink ? 'disabled' : ''}
                        onClick={() => {
                            window.open(`${TRACKER_URL}transaction/${transactionHash}`, '_blank')
                        }}
                    >
                        <MdLaunch />
                    </div>
                </td>
                <td>
                    <div>
                        <span
                            className={(editPen || disabled) ? 'disabled' : ''}
                            onClick={this.toggleEdit}
                        >
                            {edit ?
                                <MdCheck />
                                :
                                <MdModeEdit />
                            }
                        </span>
                        <span
                            className={(removeX || disabled) ? 'disabled' : ''}
                            onClick={this.remove}
                        >
                            <MdDelete />
                        </span>
                    </div>
                </td>
                
                
            </tr>
        )
    }
}

export default withLanguage(Table)