import React, { Component } from 'react'
import Button, { ButtonType } from '../../../../../parts/Button';
import Privacy from '../../../../../parts/Privacy'
import logoSample from '../../../../../../images/logo_sample.png'
import logoSampleDark from '../../../../../../images/logo_sample_dark.png'
import { COLOR_SET, PREVIEW_MODE } from '../../../../../../others/const';
import './Page1.scss'
import ApiManager from '../../../../../../ApiManager';
import { getImageSet, getExtraInfo } from '../../../../../../others/util';

class Page1 extends Component {
    state = {
        backStyle: {},
        toggle: false,
        check: false
    }

    backPanel = null

    onBackLoad = e => {
        // TODO
        // 정리할 수 있을 것 같음
        const { naturalWidth, naturalHeight } = e.currentTarget
        const { offsetWidth: backWidth, offsetHeight: backHeight } = this.backPanel
        const heightRatio = backHeight / naturalHeight
        const widthRatio = backWidth / naturalWidth

        if ((naturalWidth >= naturalHeight && (backHeight / (naturalHeight * backWidth / naturalWidth)) > 1) ||
            (naturalWidth < naturalHeight && (backWidth / (naturalWidth * backHeight / naturalHeight)) > 1)) {
            const adjust = 0 - ((naturalWidth * heightRatio) - backWidth) / 2
            this.setState({ backStyle: { height: '100%', position: 'relative', left: `${adjust}px` } })
        }
        else {
            const adjust = 0 - ((naturalHeight * widthRatio) - backHeight) / 2
            this.setState({ backStyle: { width: '100%', position: 'relative', top: `${adjust}px` } })
        }
    }

    // FIXME
    // 로고 이미지 없을 때는 안보여 주는 걸로
    // state 로 판단해서 저장 안된 거 만 표시하는 걸로
    getLogoImage = (isWhite, mode, logoImage) => {
        if (mode !== PREVIEW_MODE.template) {
            if (logoImage) {
                return logoImage
            }

            return undefined
        }
        else {
            if (logoImage) {
                return logoImage
            }

            if (isWhite) {
                return logoSampleDark
            }

            return logoSample
        }
    }

    // TODO
    // disabled 상태 정리
    getDisabled = () => {
        return !this.props.name || !this.props.code
    }

    onNextClick = async () => {
        if (this.props.mode !== PREVIEW_MODE.broof) {
            return
        }

        const disabled = this.getDisabled()
        if (disabled) {
            return
        }

        const { projectCode, name, code, sample, i18n } = this.props
        const { ERROR } = i18n
        const { result, error } = await ApiManager.getBroofs({ projectCode, name, code })
        if (result && result.transactionHash) {
            const extraInfo = getExtraInfo(result)
            this.props.updateState('extraInfo', extraInfo)
            this.props.updateState('transactionHash', result.transactionHash)
            this.props.setPreviewPage(sample ? 2 : 3)
        }
        else if (result && !result.transactionHash) {
            const extraInfo = getExtraInfo(result)
            this.props.updateState('extraInfo', extraInfo)
            this.props.setPreviewPage(2)
        }
        else if (error) {
            alert(ERROR[error])
        }
    }

    onClickCheck = () => {
        this.setState({ check: !this.state.check })
    }

    onClickToggle = () => {
        this.setState({ toggle: !this.state.toggle })
    }

    render() {
        const { mode, i18n } = this.props

        const { backStyle } = this.state
        const broofMode = mode === PREVIEW_MODE.broof
        const backgroundColor = this.props.backgroundColor || i18n.TEMPLATE_SETTINGS.backgroundColor
        const type = this.props.type || i18n.TEMPLATE_SETTINGS.type
        const { backgroundImage, logoImage } = getImageSet(this.props)
        const backgroundColorValue = backgroundImage ? COLOR_SET.white : backgroundColor

        const templateTitle = this.props.templateTitle || (broofMode ? "" : i18n.TEMPLATE_SETTINGS.templateTitle)
        const templateSubTitle = this.props.templateSubTitle || (broofMode ? "" : i18n.TEMPLATE_SETTINGS.templateSubTitle)
        const isWhite = backgroundColor === COLOR_SET.white
        const bottomColor = isWhite ? '#000' : backgroundColor
        const logoImageSrc = this.getLogoImage(isWhite, mode, logoImage)
        const color = isWhite ? '#000' : '#fff'
        const disabled = this.getDisabled()

        return (
            <div className={`project-create-preview-page one${this.props.mode === PREVIEW_MODE.broof ? ' broof' : ''}`}>
                <div className={`top${isWhite ? ' cap' : ''}`} style={{ backgroundColor: backgroundColorValue, color }} ref={ref => { if (ref) this.backPanel = ref }}>
                    {backgroundImage && <img className='back' style={backStyle} src={backgroundImage} alt='back' onLoad={this.onBackLoad} />}
                    {logoImageSrc && <img className='icon' src={logoImageSrc} alt='logo' />}
                    <p style={{ color }} ref={ref => { if (ref) ref.innerHTML = templateTitle.replace(/\n/g, '<br/>') }}></p>
                    <p style={{ color }} ref={ref => { if (ref) ref.innerHTML = templateSubTitle.replace(/\n/g, '<br/>') }}></p>
                </div>
                <div className='bottom'>
                    <input type='text' name='fake-name' autoComplete="off" style={{ display: 'none' }}/>
                    <input type='password' name='fake-code' autoComplete="new-password" style={{ display: 'none' }}/>
                    <p>{i18n.page1.enterInfo(type)}</p>
                    <div style={{ color: bottomColor }}>
                        {i18n.page1.name}
                        <input
                            name='name'
                            placeholder={i18n.page1.placeholderName}
                            style={{ borderBottom: `solid 1px ${bottomColor}` }}
                            autoComplete="off"
                            value={this.props.name}
                            onChange={this.props.handleChange}
                        />
                    </div>
                    <div style={{ color: bottomColor }}>
                        {i18n.page1.code}
                        <input
                            name='code'
                            type='password'
                            placeholder={i18n.page1.placeholderCode}
                            style={{ borderBottom: `solid 1px ${bottomColor}` }}
                            autoComplete="off"
                            value={this.props.code}
                            onChange={this.props.handleChange}
                        />
                    </div>
                    {mode === PREVIEW_MODE.broof &&
                        <Privacy
                            color={bottomColor}
                            onClickCheck={this.onClickCheck}
                            onClickToggle={this.onClickToggle}
                            className='issued'
                            i18n={i18n}
                        />
                    }
                    <Button
                        content={i18n.page1.next}
                        type={ButtonType.LINE}
                        color={bottomColor}
                        style={{ width: '100%' }}
                        disabled={
                            (disabled && (mode === PREVIEW_MODE.broof)) || 
                            mode === PREVIEW_MODE.popup || 
                            this.state.check === false
                        }
                        onClick={this.onNextClick}
                    />
                    <div className='blank'></div>
                </div>
            </div>
        )
    }
}

export default Page1