import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Router from './components/Router';
import { Provider } from 'react-redux'
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { setDocumentTitle } from './others/util';
import gtag from './others/gtag';
import { BROOF_HOST } from './others/const';

const { origin, pathname } = window.location

console.log(origin)

if (origin.includes("https://broof.io")) {
    window.location.replace(`${BROOF_HOST}${pathname}`)
}

gtag('js', new Date());
gtag('config', 'UA-141099579-1');
gtag('config', 'UA-141099579-2');

const { payload: language } = store.getState().language
setDocumentTitle(language)

ReactDOM.render(<Provider store={store}><Router /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister()
