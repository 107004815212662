import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Lefter from '../../parts/Lefter'
import './index.scss'
import DivInput from '../../parts/DivInput'
import { ROUTE } from '../../../others/const'
import Button from '../../parts/Button';
import History from './History'
import { getHistoryList } from '../../../redux';
import withLanguage from '../../../HOC/withLanguage';

class Information extends Component {
    async componentDidMount() {
        this.props.getHistoryList()
    }

    onChangeClick = () => {
        this.props.history.push(ROUTE.change)
    }
    
    render() {
        const { 
            userData, 
            list,
            i18n
        } = this.props

        return (
            <div className='information-container has-menu-container'>
                <Lefter />
                <div className='information-history'>
                    <div className='contents'>
                        <div className='top'>
                            <DivInput
                                className='name'
                                value={userData.alias}
                                div
                            />
                            <span className='right'>
                                <DivInput
                                    className='id'
                                    value={userData.username}
                                    div
                                />
                                <Button onClick={this.onChangeClick} 
                                    content={i18n.information.change} 
                                    style={{ width: 140 }}
                                />
                            </span>
                        </div>
                        <div className='tab'>
                            {Object.keys(i18n.information.tab).map((key, index) => {
                                const tab = i18n.information.tab[key]
                                const { icon, title } = tab
                                const TabIcon = icon
                                return (
                                    <div key={index}
                                        className={'selected'}
                                    >
                                        <TabIcon />{title}
                                    </div>
                                )
                            })}
                        </div>
                        <div className='board'>
                            <History
                                {...this.state}
                                list={list}
                                className='table-box'
                            />
                            <div className='bottom'>
                                <span className='count' ref={ref => {if (ref) ref.innerHTML = i18n.information.count(list.length)}}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData.payload,
        list: state.historyList.payload.list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getHistoryList: payload => dispatch(getHistoryList(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Information)))