import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './index.scss'
import { ROUTE } from '../../../others/const';
import PaymentDescription from '../../../images/payment_description.svg'
import Ticket from './Ticket'
import PayManager from '../../../PayManager';
import ApiManager from '../../../ApiManager';
import { setPopup, setUserData, setLoggedIn, addToast } from '../../../redux'
import Footer from '../../parts/Footer'
import withLanguage from '../../../HOC/withLanguage';
import gtag from '../../../others/gtag';
import CoffeeCup from '../../parts/CoffeeCup';
import { devConsole } from '../../../others/util';

const Payment = ({ 
    i18n, 
    setPopup, 
    addToast, 
    history, 
    userData,
    loggedIn 
}) => {
    
    const [loading, setLoading] = useState(true)
    const [ticketList, setTicketList] = useState([])

    const updateCount = async (ticket, paymentCode) => {
        const { productId } = ticket
        const { result, error } = await ApiManager.postUsersCurrentTransactions({ 
            paymentMethod: 1,
            paymentCode,
            productId
        })

        if (result) {
            history.push(ROUTE.information)
            const { count, bonus } = ticket
            addToast({ text: i18n.payment.resultToast(count + bonus) })
        }
        else if (error && i18n.ERROR[error]) {
            setPopup({
                ...i18n.payment.ask(i18n.ERROR[error])
            })
        }
    }

    const onPayClick = async ticket => {
        devConsole(`%cloggedIn`, "font-weight: bold;", loggedIn)
        if (!loggedIn) {
            history.push(`${ROUTE.login}?redirect=payment`)
            return
        }

        gtag('event', 'pay')

        const { result, error } = await PayManager.payByKrw(ticket, {
            name: i18n.payment.ticketText(ticket.count + ticket.bonus),
            buyer: {
                email: userData.username,
                name: userData.alias
            }
        });
        
        if (result) {
            const { imp_uid: paymentCode } = result
            updateCount(ticket, paymentCode)
        }
        else if (error && i18n.ERROR[error]) {
            setPopup({
                ...i18n.payment.retry(i18n.ERROR[error]),
                confirmCallback: () => {
                    onPayClick(ticket)
                }
            })
        }
    }

    useEffect(() => {
        (async () => {
            const ticketList = await ApiManager.getProducts()
            setTicketList(ticketList)
            setLoading(false)
        })()
    }, [])

    return [
        <div key='div' className='containers-payment'>
            <div className='title'>
                {i18n.payment.title}
            </div>
            <div className='description'>
                <div ref={ref => { if (ref) ref.innerHTML = i18n.payment.leftDescription }}></div>
                <img src={PaymentDescription} alt='description' />
                <div ref={ref => { if (ref) ref.innerHTML = i18n.payment.rightDescription }}></div>
            </div>
            <div className='space'></div>
            <div className='tickets'>
                {loading && <CoffeeCup margin={'98px auto 158px'} />}
                {!loading && ticketList.map(ticket => (
                    <Ticket
                        key={ticket.productId}
                        {...ticket}
                        onPayClick={() => { onPayClick(ticket) }}
                    />
                ))}
            </div>
        </div>,
        <Footer key='footer' />
    ]
}

function mapStateToProps(state) {
    return {
        userData: state.userData.payload,
        loggedIn: state.loggedIn.payload,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setPopup: payload => dispatch(setPopup(payload)),
        setUserData: payload => dispatch(setUserData(payload)),
        setLoggedIn: payload => dispatch(setLoggedIn(payload)),
        addToast: payload => dispatch(addToast(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Payment)))