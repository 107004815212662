import { fork, all } from 'redux-saga/effects'
import projectSage from './project'
import historySaga from './history'
import userSaga from './user'
import broofSaga from './broof'
import toastSaga from './toast'

export default function* root() {
    yield all([
        fork(projectSage),
        fork(historySaga),
        fork(userSaga),
        fork(broofSaga),
        fork(toastSaga)
    ])
}