function getBaseUrl() {
    return `${window.location.origin}/`
}

function getTrackerUrl() {
    switch(process.env.REACT_APP_ENV) {
        case 'internal':
        case 'dev':
        case 'dev2':
            return "https://trackerdev.icon.foundation/"
        case 'prod':
        default:
            return "https://tracker.icon.foundation/"
    }
}

function getNid() {
    switch(process.env.REACT_APP_ENV) {
        case 'internal':
        case 'dev':
        case 'dev2':
            return 2
        case 'prod':
        default:
            return 1
    }
}

export const BROOF_HOST = "https://www.broof.io"

function getHost(api) {
    switch(process.env.REACT_APP_ENV) {
        case 'internal':
            return "http://localhost:8080"
        case 'dev':
            return "http://54.180.118.127:8080"
        case 'dev2':
            return "http://15.164.165.243:8080"
        case 'prod':
        default:
            return api ? '' : BROOF_HOST
    }
}

export const DISCLAIMER = 'https://docs.google.com/document/d/1Qzh3sn1BGJut4m03MsFugyGpDJmcMSqlVN7W4O_rUG4/edit#'
export const POLICY = 'https://docs.google.com/document/d/1jBuSl9OPdteYyylwnfkcypEWuqbQNhzwjJywkP4gWIk'
export const TERMS = 'https://docs.google.com/document/d/1m_Pe-dx8amFlx0i_IEaQHfRus8W2zDwVnZ9V_QSswOI'
export const FAQ = 'https://docs.google.com/spreadsheets/d/1JjoEf6r1QhqWWOg15c3dBwYI6LZr0HeW'
export const EMAIL = 'hello@broof.io'
export const SINGIN_FORM = 'https://forms.gle/BpTn8UMTTB5cbRR27/'
export const MEDIUM = 'https://medium.com/@hellobroof'
export const BASE_URL = getBaseUrl()
export const TRACKER_URL = getTrackerUrl()
export const NID = getNid()
export const HOST = getHost()
export const API_HOST = getHost(true)

export const ROUTE = {
    home: '/',
    login: '/login',
    signup: '/signup',
    change: '/change',
    project: '/certificate',
    information: '/information',
    payment: '/payment',
    oops: '/oops'
}

export const PREVIEW_MODE = {
    broof: 'broof',
    popup: 'popup',
    template: 'template'
}

export const PROJECT_ROUTE = {
    create: '/create',
    edit: '/edit',
}

export const PAYMENT_TYPE = {
    krw: 'KRW',
    icx: 'ICX'
}

export const GA_ID = ['UA-141099579-1', 'UA-141099579-2']

export const PROJECT_STATE = {
    PAYMENT_REQUIRED: 'PAYMENT_REQUIRED',
    STANDBY: 'STANDBY',
    IN_PROGRESS: 'IN_PROGRESS',
    PAUSED: 'PAUSED',
    FINISHED: 'FINISHED',
}

export const COLOR_SET = {
    dark: '#323e52',
    blue: '#0008ff',
    green: '#14ba5c',
    yellow: '#fbaf23',
    red: '#b70015',
    grey: '#d8d8d8',
    white: '#fff',
    image: 'image'
}

export const dotYYYYMMDD = 'YYYY.MM.DD'
export const dashYYYYMMDD = 'YYYY-MM-DD'

export const TEMPLATE_RECIPIENTS = []

export const IAMPORT_ERROR = {
    userCancel: '사용자가 결제를 취소하셨습니다'
}

export const TICKET_LIST = [{
    count: 30,
    bonus: 0,
    price: 24000,
    discount: 5 / 8,
    highlight: false
}, {
    count: 60,
    bonus: 10,
    price: 48000,
    discount: 5 / 8,
    highlight: true
}, {
    count: 120,
    bonus: 30,
    price: 96000,
    discount: 5 / 8,
    highlight: false
}]

export const LANGUAGE = {
    EN: 'en',
    KO: 'ko'
}

export const TOAST_TIME = 3000
export const TOAST_LENGTH = 5

export const ICONEX_WEBSTORE = "https://chrome.google.com/webstore/search/iconex?hl=ko"

export const CUSTOM_EVENT = {
    expired: 'expired'
}

export const EXPIRED_JWT_MESSAGES = ['Expired JWT token', 'Invalid JWT signature', "Sorry, You're not authorized to access this resource."]