import React, { Component } from 'react';
import { connect } from 'react-redux'
import { setLanguage } from '../redux';
import i18n from '../others/i18n';
import { setDocumentTitle } from '../others/util';

const withLanguage = WrappedComponent => {
    return connect(mapStateToProps, mapDispatchToProps)(
        class extends Component {

            setLanguage = payload => {
                setDocumentTitle(payload)
                this.props.setLanguage(payload)
            }

            render() {
                return (
                    <WrappedComponent
                        {...this.props}
                        language={this.props.language}
                        setLanguage={this.setLanguage}
                        i18n={i18n[this.props.language]}
                    />
                )
            }
        }
    )
}

function mapStateToProps(state) {
	return {
		language: state.language.payload,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		setLanguage: payload => dispatch(setLanguage(payload)),
	}
}

export default withLanguage