import React from 'react'
import { setClassName, execute } from '../../others/util'
import './Button.scss'

export const ButtonType = {
    TEXT: 'text',
    LINE: 'line',
    FILL: 'fill'
}

const Button = ({
    name,
    disabled,
    onClick,
    content,
    style,
    type,
    color,
}) => {

    const _style = { ...style }
    const themeColor = '#0008ff'
    const _color = color || themeColor

    switch (type) {
        case ButtonType.TEXT:
            _style.color = _color
            break

        case ButtonType.LINE:
            _style.border = `solid 1px ${_color}`
            _style.color = _color
            break

        case ButtonType.FILL:
        default:
            _style.backgroundColor = _color
            _style.color = color === 'white' || color === '#fff' || color === '#ffffff' ? themeColor : 'white'
    }

    return (
        <button
            name={name}
            style={_style}
            className={setClassName(['_parts-button', disabled && 'disabled'])}
            onClick={e => { execute(onClick, e) }}
        >
            {content}
        </button>
    )
}

export default Button;