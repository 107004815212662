import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import InputSet from '../parts/InputSet'
import Button from '../parts/Button'
import { ROUTE } from '../../others/const';
import ApiManager from '../../ApiManager';
import { setPopup } from '../../redux';
import './Signup.scss'
import { requestAddress } from '../../others/connect'
import { emailTest, passwordTest } from '../../others/regexr'
import Footer from '../parts/Footer'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { MdCheck } from 'react-icons/md'
import Privacy from '../parts/Privacy'
import withLanguage from '../../HOC/withLanguage';
import gtag from '../../others/gtag';

// TODO Login, change, signup 합치기
class Signup extends Component {
    state = {
        alias: '',
        aliasError: '',
        username: '',
        usernameError: '',
        password: '',
        passwordError: '',
        confirm: '',
        confirmError: '',
        defaultError: '',
        address: '',
        passwordVisible: false,
        confirmPasswordVisible: false,
        passwordStatus: false,
        confirmPasswordStatus: false,
        check: false
    }

    handleChange = e => {
        const { name, value } = e.target
        // this.setState({ [name]: value, [`${name}Error`]: '' }, () => {
        this.setState({
            [name]: value,
            aliasError: '',
            usernameError: '',
            passwordError: '',
            confirmError: '',
            defaultError: '',
        }, () => {
            if (name === 'password') {
                const { password } = this.state;
                if (passwordTest.test(password)) {
                    this.setState({ passwordStatus: true })
                }
                else {
                    this.setState({ passwordStatus: false })
                }
            }

            if (name === "confirm") {
                const { password, confirm } = this.state;
                if (password === confirm) {
                    this.setState({ confirmPasswordStatus: true })
                }
                else {
                    this.setState({ confirmPasswordStatus: false })
                }
            }
        })
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.onConfirmClick()
        }
    }

    onConfirmClick = async () => {
        const { i18n, language } = this.props
        const { ERROR } = i18n
        const { alias, username, password, confirm, address } = this.state

        if (!username) {
            this.setState({ usernameError: i18n.signup.emailError })
            return
        }

        if (!emailTest.test(username)) {
            this.setState({ usernameError: i18n.signup.emailForm })
            return
        }

        if (!password) {
            this.setState({ passwordError: i18n.signup.passwordError })
            return
        }

        if (!confirm) {
            this.setState({ confirmError: i18n.signup.passwordConfirm })
            return
        }

        if (!passwordTest.test(password)) {
            this.setState({ passwordError: i18n.signup.passwordForm })
            return
        }

        if (password !== confirm) {
            this.setState({ confirmError: i18n.signup.passwordDifferent })
            return
        }

        if (!alias) {
            this.setState({ aliasError: i18n.signup.nameError })
            return
        }

        gtag('event', 'signup_ok')
        const { result, error } = await ApiManager.postUsersSignup({ alias, username, password, address }, language)

        if (error) {
            switch (error) {
                case ERROR.alreadyUsername:
                    this.setState({ usernameError: ERROR[error] })
                    break;
                default:
                    this.setState({ defaultError: ERROR[error] })
            }
        }
        else {
            const { username } = result
            this.props.setPopup({
                ...i18n.signup.checkEmail(username),
                confirmCallback: () => {
                    this.props.history.push(ROUTE.login)
                    const emailHost = username.split('@')[1]
                    window.open(`https://${emailHost}`, '_blank')
                },
                cancelCallback: () => {
                    this.props.history.push(ROUTE.login)
                },
            })
        }
    }

    handleFocus = () => {
        const { i18n } = this.props
        this.setState({ passwordMsg: i18n.signup.passwordForm })
    }

    onAddressClick = async () => {
        const address = await requestAddress()
        this.setState({ address })
    }

    onLinkClick = () => {
        this.props.history.push('/login')
    }

    onClickPasswordEye = () => {
        this.setState({
            passwordVisible: !this.state.passwordVisible
        })
    }

    onClickConfirmPasswordEye = () => {
        this.setState({
            confirmPasswordVisible: !this.state.confirmPasswordVisible
        })
    }

    onClickCheck = () => {
        this.setState({
            check: !this.state.check
        })
    }

    render() {
        // const { defaultError, address } = this.state
        const { i18n } = this.props
        const { defaultError } = this.state
        return (
            <Fragment>
                <div className='no-menu-container'>
                    {/* <h2 className='sign-up-title' ref={ref => { if (ref) ref.innerHTML = i18n.signup.title }}> </h2> */}
                    <h2 className='sign-up-title'>{i18n.signup.title}</h2>
                    <div className='block' />
                    <InputSet
                        name='username'
                        title={i18n.signup.emailTitle}
                        context='change'
                        type='text'
                        value={this.state.username}
                        error={this.state.usernameError}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                    />
                    <InputSet
                        name='password'
                        title={i18n.signup.passwordTitle}
                        context='change'
                        type={this.state.passwordVisible ? 'text' : 'password'}
                        value={this.state.password}
                        error={this.state.passwordError}
                        handleFocus={this.handleFocus}
                        handleBlur={this.handleBlur}
                        msg={this.state.passwordMsg}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                        eye={this.state.passwordVisible ? <FaEye onClick={this.onClickPasswordEye} /> : <FaEyeSlash onClick={this.onClickPasswordEye} />}
                        check={<div className={`check ${this.state.passwordStatus ? 'active' : ''}`}><MdCheck /></div>}
                    />
                    <InputSet
                        name='confirm'
                        title={i18n.signup.passwordConfirmTitle}
                        context='change'
                        type={this.state.confirmPasswordVisible ? 'text' : 'password'}
                        value={this.state.confirm}
                        error={this.state.confirmError}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                        eye={this.state.confirmPasswordVisible ? <FaEye onClick={this.onClickConfirmPasswordEye} /> : <FaEyeSlash onClick={this.onClickConfirmPasswordEye} />}
                        check={<div className={`check ${this.state.confirmPasswordStatus ? 'active' : ''}`}><MdCheck /></div>}
                    />
                    <div className="division" />
                    <InputSet
                        name='alias'
                        title={i18n.signup.nameTitle}
                        context='change'
                        type='text'
                        value={this.state.alias}
                        error={this.state.aliasError}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                    />
                    {/* <div className='iconex-connect'>
                        <span className='title'>{i18n.signup.wallet}<span className="option"> ({i18n.signup.option})</span></span>
                        {address ?
                            <div className='address'>{address}</div>
                            :
                            <Button onClick={this.onAddressClick} content={i18n.signup.iconex} type={ButtonType.LINE} />
                        }
                    </div> */}
                    <Privacy onClickCheck={this.onClickCheck} i18n={i18n} />
                    <Button
                        onClick={this.onConfirmClick}
                        content={i18n.signup.confirmText}
                        style={{ width: '100%' }}
                        disabled={this.state.check === false && true}
                    />
                    {defaultError && <p>{defaultError}</p>}
                    <button className="link-btn" onClick={this.onLinkClick}>{i18n.signup.login}</button>
                </div>
                <Footer />
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        setPopup: (payload) => dispatch(setPopup(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Signup)))