import React, { Component } from 'react'
import './History.scss'
import moment from 'moment'
import withLanguage from '../../../HOC/withLanguage';

// TODO Table 이랑 합치고 모듈화
class History extends Component {
    render() {
        const { 
            className, 
            list,
            i18n
        } = this.props

        return (
            <div className={`history-table${className ? ` ${className}` : ``}`}>
                <table className='head'>
                    <thead>
                        <tr>
                            <th>{i18n.history.time}</th>
                            <th>{i18n.history.detail}</th>
                            <th>{i18n.history.issue}</th>
                            <th>{i18n.history.count}</th>
                        </tr>
                    </thead>
                </table>
                <div className='body-wrapper'>
                    <table>
                        <tbody>
                            {list.map((item, index) => (
                                <tr key={index}>
                                    <td>{moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss')}</td>
                                    <td>{item.purchase ? i18n.history.purchased : item.details || "-"}</td>
                                    <td className={item.purchase ? 'blue' : 'red'}>{item.purchase ? '+' : '-'}{item.amount}</td>
                                    <td>{item.purchase ? '-' : item.issueCount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default withLanguage(History)