import { MdPayment, MdAssignment, MdWeb, MdPeople } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { ROUTE, COLOR_SET } from "../const";

export default {
	route: {
		expired: {
			title: `아이고!<br/>너무 오래 자리를 비우셨네요.`,
			description: `자리를 비우신 동안 로그아웃 되었습니다.<br/>다시 로그인 해주세요.`,
			confirmText: '로그인',
		}
	},
	toast: {
		undo: '실행 취소',
	},
	USER_GUIDE: 'https://docs.google.com/document/d/1GzbCLmy06L89OI960ZoVXkA8-prpnfLf_uK4gkq4QCc/edit',
	CSV_HEADER: ['#', '이름', '비밀번호', '추가 정보', '발급 결과'],
	CSV_TEMPLATE: [
		[
			'이름', 
			'비밀번호 (입력하지 않거나 4자리 숫자가 아닐 경우 자동으로 생성되어 업로드 됩니다.)', 
			'추가 정보 (선택 사항)',
			// '이메일 (선택 사항)', 
			// '전화 번호 (선택 사항)', 
			// 'ICX 지갑 주소 (선택 사항)'
		], [
			'홍길동', 
			'1922', 
			'broof 개발자',
			// 'hello@broof.io', 
			// '010-0000-0000', 
			// 'hx0000000000000000000000000000000000000000'
		]
	],
	ERROR: {
		alreadyUsername: '동일한 이메일이 존재합니다.',
		icxFailure: 'ICX 결제를 실패하였습니다.',
		krwFailure: '결제를 실패하였습니다.',
		expiredJwt: '로그인 세션이 만료되었습니다.',
		issueFailed: '구매 요청에 실패하였습니다.',
		saveFailed: '저장에 실패하였습니다.',
		deleteFailed: '삭제에 실패하였습니다.',
		icxFailed: 'ICON 가격을 가져오지 못했습니다.',
		changeFailed: '발급 상태를 변경하지 못했습니다.',
		invalidUser: '발급자 정보를 확인해 주세요.',
		transactionFailed: '발급 요청을 실패하였습니다. 다시 시도해 주세요.',
		projectExpired: '발급 기간이 만료되었습니다.',
		projectPaused: '발급이 중단 되었습니다. 관리자에게 문의해 주세요.',
		projectStandby: '발급 대기 중입니다.',
		paymentRequired: '발급 기관의 이용권이 부족합니다. 관리자에게 문의해 주세요.',
		postUserDataFailed: '사용자 정보 변경에 실패하였습니다.',
		
		paymentAlreadyUsed: '이미 처리 완료된<br />결제 내역입니다.',
		paymentNotExist: '결제 내역을 확인할 수 없습니다.',
		invalidResponse: '비정상적인 결제 내역입니다.',
		paymentDiffer: '결제 금액과 구매 내역이<br />상이합니다.',
		paymentStillProcessed: '결제 진행중 입니다.',
		paySuccessCountFailure: '구매 내역 업데이트에<br />실패 하였습니다.',

		invalidPassword: '비밀번호를 확인해 주세요.',
		noUsername: '아이디를 확인해 주세요.',
		networkError: '네트워크 연결 상태를 확인해 주세요.',
		unauthorized: '이메일 인증 후 로그인 해 주세요.',
		differentPassword: '새로운 암호는 기존 암호와 같을 수 없습니다.',

		loadFailed: '이용권 사용 내역을 불러오지 못하였습니다.',
 	},
	TEMPLATE_SETTINGS: {
		projectName: '제목 없음',
		templateTitle: '제목글을 입력해 주세요.',
		templateSubTitle: '설명글을 입력해 주세요.',
		backgroundColor: COLOR_SET.blue,
		type: '수료증',
		ownerAlias: 'broof',
		backgroundImage: undefined,
		logoImage: undefined,
		certificateImage: undefined,
	},	
	PROJECT_STATE_LABEL: {
		PAYMENT_REQUIRED: '임시 저장',
		STANDBY: '발급 대기',
		IN_PROGRESS: '발급 중',
		PAUSED: '발급 중단',
		FINISHED: '발급 완료',
		EXPIRED: '발급 만료'
	},
	document: {
		title: 'broof - 브루프, 블록체인 증서 발급 서비스'
	},
	privacy: {
		title: '개인정보 수집 ・ 이용 동의',
		purpose: '아이콘루프(이하 ‘회사’)는 개인정보를 아래와 같이 수집 및 이용합니다.',
		purposeThead: '목적',
		purposeTbody: issued => issued ? '인증서 조회 및 발급' : '기관 등록',
		infoThead: '항목',
		infoTbody: issued => issued ? '이름, 이메일 주소, 생년월일, 인증서 정보 등' : "(필수) 이메일주소, 단체명(회사명)",
		periodThead: '보유기간',
		periodTbody: issued => issued ? '서비스 해지시' : '회원 탈퇴까지',
		cautions1: '* 서비스 제공을 위한 최소한의 개인정보이므로 동의를 해주셔야 서비스 이용이 가능합니다.',
		cautions2: issued => `* ${issued ? `인증서 종류에 따라 수집하는 내용이 상이할 수 있습니다. ` : ``}자세한 내용은 '개인정보 처리방침'을 참고해주세요.`
	},
	broof: {
		errorTitle: '아이고!<br/>길을 잘못 드셨어요.',
		errorDescription: 'URL을 다시 한 번 확인해 보세요.',
	},
	page1: {
		enterInfo: type => `${type} 발급을 위해 아래의 정보를 입력해 주세요.`,
		name: '이름',
		placeholderName: '홍길동',
		placeholderExtraInfo: '추가 정보',
		code: '비밀번호',
		placeholderCode: '전달받은 4자리 번호',
		next: '다음',
	},
	page2: {
		issuing: type => `블록체인으로 ${type}을(를) 발급 중입니다.`,
		record: '블록체인 기록 내역',
		alias: '발급자',
		time: '일시',
		contents: '내역',
		from: '님의',
	},
	page3: {
		move: '이동하기',
		upload: `증서 이미지를 올리고<br />발급자의 이름을 이동시켜 주세요.`,
		check: type => `ICON Tracker에서 ${type} 확인하기`,
		description: type => `${type} 이미지를 길게 누르면 핸드폰에 저장하실 수 있습니다.`,
	},
	home: {
		start: '시작하기',
		status: total => `총 ${total} 개의 노력과 시간을 보관 중입니다.`,
		service: '블록체인 증서 발급 서비스',
		description1: `당신의 노력과 시간을<br />영원히 보관합니다.`,
		description2: mobile => `아무도 조작할 수 없는<br />${mobile ? '블록체인 증서로' : '블록체인으로 증명서를 발행해'}`,
		description3: `지워질 걱정없이<br />자료를 보관하고`,
		description4: mobile => `모바일로 어디서나 쉽게<br />${mobile ? '발급 / 조회 / 증명해 보세요' : '발급 / 조회 / 증명할 수 있습니다.'}`,
		workTitle: '작동 방식',
		work1: '발급 기관이 업로드한 증명서 발급 명단과<br />발급자의 입력 정보가 일치하면',
		work2: '증명서가 발급됩니다.',
		work3: '그리고 이 증명서를 <br />블록체인에 저장해',
		work4: '시간이 지나도 원본 그대로를<br />발급 / 조회 / 증명할 수 있습니다.',
		praise: [{
			text: '블록체인으로 위촉장을 발급하니 시민들의 반응이 훨씬 좋더라고요.',
			agency: '서울특별시',
			project: '블록체인 거버넌스단 위촉장 발급'
		}, {
			text: '블록체인으로 수료증을 받으니 더 특별한 학위를 받은 것 같아요.',
			agency: '스터디파이',
			project: 'ICON SCORE 개발 과정'
		}],
		statusBottom: total => `총 ${total} 개의<br /><span>블록체인 증서가 이미 발급되었습니다.</span>`,
	},
	slider: {
		work1: `발급기관이 업로드한 증명서 발급 명단과<br />발급자의 입력 정보가 일치하면`,
		work2: `증명서가 발급됩니다.`,
		work3: `그리고 이 증명서를<br />블록체인에 저장해`,
		work4: `시간이 지나도 원본 그대로를<br />발급 / 조회 / 증명할 수 있습니다.`,
		article1: '서울시, 블록체인 거버넌스단에<br />`블록체인 위촉장` 발급',
		article2: '포스텍, ‘블록체인 최고경영자<br /> 과정’ 1기 수료식 개최',
		article3: 'ICON’s public blockchain<br /> used by Seoul Citizens Hall',
		article4: '스터디파이, ICON SCORE 개발<br />과정 수료증 발급',
		press1: '매일경제, 2019.02.08',
		press2: '블록체인 투데이, 2019.05.31',
		press3: 'Twitter',
		press4: 'ICON Tracker',
	},
	history: {
		time: '일시',
		detail: '내역',
		issue: '구매 / 발급',
		count: '발급 갯수',
		purchased: '이용권 구매'
	},
	information: {
		change: '비밀번호 변경',
		count: count => `총 ${count} 건`,
		tab: {
			usage: {
				icon: MdPayment,
				title: '이용권 사용 내역',
				hash: '#usage'
			}
		}
	},
	payment: {
		resultToast: count => `${count}개의 이용권이 충전되었습니다.`,
		ticketText: (count, bonus) => `이용권 ${count}건${bonus ? ` + ${bonus}건` : ``}`,
		notChrome: {
			title: `아이고!<br/>크롬 브라우저가 아니네요.`,
			description: `ICX 결제는 ICONex가 설치된 크롬에서만 가능합니다.`,
			confirmText: '확인',
		},
		notInstalled: {
			title: `아이고!<br/>ICONex가 설치되어 있지 않네요.`,
			description: 'ICX 결제는 ICONex가 설치되어야 가능합니다.',
			confirmText: '확인',
		},
		notWallet: {
			title: `아이고!<br/>ICONex에 ICON 지갑이 없네요.`,
			description: 'ICX 결제는 ICONex에 ICON 지갑이 있어야 가능합니다.',
			confirmText: '확인',
		},
		typeError: error => ({
			title: `아이고!<br/>${error}`,
			description: `원화로 결제 하시거나 잠시 후 다시 시도해 주세요.`,
			confirmText: '확인'
		}),
		retry: error => ({
			title: `아이고!<br/>${error}`,
			description: `다시 시도해 주세요.`,
			confirmText: '다시 시도',
			cancelText: '취소'
		}),
		ask: error => ({
			title: `아이고!<br/>${error}`,
			description: `관리자에게 문의 해 주세요.`,
			confirmText: '확인',
		}),
		notAddress: address => ({
			title: `아이고!<br/>회원님의 계정에 연결된<br/>ICON 지갑이 ICONex에<br/>설치되지 않았습니다.`,
			description: `아래 주소의 ICON 지갑을 ICONex에 추가해 주세요.<br/><b>${address}</b>`,
			confirmText: '확인'
		}),
		notConnected: {
			title: `아이고!<br/>회원님의 계정이 ICON 지갑에<br/>연결되지 않았습니다.`,
			description: `ICONex 내 ICON 지갑과 회원님의 계정을<br/>연결하고 다시 시도해 주세요.`,
			confirmText: 'ICON 지갑 연결',
		},
		testPeriod: {
			title: `아이고!<br/>현재는 테스트 결제 기간입니다.`,
			description: `실제 이용권은 충전되지 않으며<br/>결제 내역은 익일 자동 취소 처리 됩니다.`,
			confirmText: '확인',
		},
		testCompleted: {
			title: `테스트 결제가 완료되었습니다.`,
			description: `결제 내역은 익일 자동 취소 처리 됩니다.`,
			confirmText: '확인',
		},
		title: `당신의 노력과 시간을 영원히 보관합니다.`,
		leftDescription: `무한 템플릿 생성<br />로고, 색, 소개 문구 등 자유로운 편집<br />증명서 발급 통계 및 관리`,
		rightDescription: `모바일에서 가입없이 1초 만에 증명서 발급<br />접근 암호 인증 설정<br />블록체인을 통한 증명서 영구 보관`,
		icxBonus: 'ICX 로 결제하면 이용권 추가 제공!',
		type: currency => `${currency ? '원화' : 'ICX'}로 결제하기`,
		upbit: `* Upbit 기준`
	},
	ticket: {
		krw: '원',
		open: '오픈 특가',
		pay: '결제하기'
	},
	people: {
		searchPlaceholder: '이름이나 비밀번호를 입력하세요',
		templateFileName: '템플릿 명단.csv',
		downloadFileName: projectName => `${projectName} 명단.csv`,
		count: count => `총 ${count} 명`,
		deduct: `* 인원 당 1개의 이용권이 차감됩니다.`
	},
	preview: {
		previous: '이전 페이지',
		next: '다음 페이지',
		page: '페이지'
	},
	data: {
		type: '종류',
		background: '색상',
		logo: '로고',
		logoRecommend: '* 권장사항 : 이미지 높이 20px / PNG 형식',
		attach: '파일 첨부',
		title: '타이틀',
		subTitle: '서브 타이틀',
		certificate: '증서 이미지',
		certificateRecommend: '* 권장사항 : JPG, PNG 형식',
		alert: {
			title: '개인 민감 정보 주의',
			description: '블록체인 특성 상 데이터의 수정 및 삭제가<br />불가능 합니다.<br /><br />업로드할 증서 이미지에 <b>개인 민감 정보<br />(주민 등록 번호, 여권 번호, 주소, 전화 번호 등)</b>가<br />포함되지 않았는지 다시 한 번 확인 해 주세요.',
			confirmText: '확인',
			cancelText: '취소'
		}
	},
	confirm: {
		already: '이미 존재하는 URL 입니다.',
		short: {
			title: '아이고!<br/>이용권이 모자라요.',
			description: `발급 받는 인원 당 1개의 이용권이 필요해요.<br/>이용권을 구매하시면 바로 준비할게요.`,
			confirmText: '이용권 구매하기',
		},
		retry: error => ({
			title: `아이고!<br/>${error}`,
			description: `다시 시도해 주세요.`,
			confirmText: '확인',
		}),
		wrongDate: '날짜 형식이 잘못되었습니다.',
		startForm: '시작 날짜를 정확히 설정해 주세요.',
		startToday: '시작 날짜는 오늘 이전으로 설정할 수 없습니다.',
		startEnd: '시작 날짜는 종료 날짜 이후로 설정할 수 없습니다.',
		endForm: '종료 날짜를 정확히 설정해 주세요.',
		endStart: '종료 날짜는 시작 날짜 이전으로 설정할 수 없습니다.',
		endToday: '종료 날짜는 오늘 이전으로 설정할 수 없습니다.',
		urlForm: 'URL 형식이 잘못되었습니다.',
		information: count => `총 ${count} 명에게<br />증서를 발급합니다.`,
		whenWhere: '언제, 어디로 보내실 건가요?',
		period: '기간',
		causion: '인원당 1개의 이용권이 차감됩니다.',
		issue: '발급하기',
	},
	create: {
		noName: {
			title: `아이고!<br/>제목 없는 증서가 발급될 뻔 했어요.`,
			description: `증서 이름을 설정해 주세요.`,
			confirmText: '확인'
		},
		noType: {
			title: `아이고!<br/>증서 종류를 작성하지 않으셨네요.`,
			description: `증서 종류를 설정해 주세요.`,
			confirmText: '확인'
		},
		noLogo: {
			title: `아이고!<br/>로고 이미지를 빼먹으셨네요.`,
			description: `ICON에서 발급한 척 할뻔 했어요.<br/>템플릿 탭에서 로고 이미지를 첨부해주세요.`,
			confirmText: '확인',
		},
		noTitle: {
			title: `아이고!<br/>타이틀을 작성하지 않으셨네요.`,
			description: `타이틀을 작성해 주세요.`,
			confirmText: '확인'
		},
		noSubTitle: {
			title: `아이고!<br/>서브 타이틀을 작성하지 않으셨네요.`,
			description: `서브 타이틀을 작성해 주세요.`,
			confirmText: '확인'
		},
		noCertificate: {
			title: '아이고!<br/>증서 이미지를 빼먹으셨네요.',
			description: `증명서 없는 증서가 발급될 뻔 했어요.<br/>템플릿 탭에서 증서 이미지를 올려주세요.`,
			confirmText: '증서 이미지 올리기',
		},
		noRecipient: {
			title: '아이고!<br/>받는 분들이 누구인지 모르겠어요.',
			description: `받는 사람 올리시는 것을 잊으셨네요.<br/>명단 탭에서 받는 사람 명단을 올려 주세요.`,
			confirmText: '명단 올리기',
		},
		change: '이름 변경',
		toList: '목록으로',
		issue: '발급하기',
		tab: {
			template: {
				icon: MdWeb,
				title: '증서',
				hash: '#template'
			},
			people: {
				icon: MdPeople,
				title: '명단',
				hash: '#list'
			}
		},
		saveImageFail: '이미지 저장에 실패하였습니다.\n다시 시도해 주세요.',
		addRecipients: count => `${count} 명의 명단이 추가되었습니다.`,
		addRecipientsFail: '명단 추가에 실패하였습니다.\n다시 시도해 주세요.',
		deleteRecipients: count => `${count} 명의 명단이 삭제되었습니다.`,
		deleteRecipientsFail: '명단 삭제에 실패하였습니다.\n다시 시도해 주세요.',
	},
	card: {
		copied: '(복사됨)',
		retry: error => ({
			title: `아이고!<br/>${error}`,
			description: `다시 시도해 주세요.`,
			confirmText: '확인'
        }),
        pause: '발급 중지',
		template: '템플릿 편집',
		recipient: '명단 편집',
		copy: '증명서 복사',
		delete: '증명서 삭제',
		resume: '발급 재개',
		finish: '설정을 마무리 해주세요.',
		start: day => `${day}일 후 발급을 시작합니다.`,
		new: '새 증명서 만들기',
		copySuccess: '프로젝트가 복사되었습니다.',
        copyFail: '프로젝트 복사에 실패하였습니다.',
        deleteProject: () => ({
            title: '정말 삭제합니다?',
            description: '이번 생애에 데이터를 다시 못 볼 수도 있으니<br />신중히 고민해 주세요.',
            confirmText: '네',
            cancelText: '으악, 안되요!'
        }),
        deleteSuccess: '프로젝트가 삭제되었습니다.',
        deleteFail: '프로젝트 삭제에 실패하였습니다.'
	},
	list: {
		keep: '당신의 노력과 시간을 보관하세요.'
	},
	change: {
		currentPasswordError: '현재 비밀번호를 입력해 주세요.',
		newPasswordError: '새로운 비밀번호를 입력해 주세요.',
		confirmError: '새로운 비밀번호를 다시 한 번 입력해 주세요.',
		sameError: '비밀번호가 일치하지 않습니다.',
		currentTitle: '현재 비밀번호',
		newTitle: '새 비밀번호',
		confirmTitle: '비밀번호 확인',
		confirmText: '확인'
	},
	login: {
		successToast: '로그인 되었습니다.',
		precious: email => ({
			title: '아무나 안 알려드립니다.<br/>당신의 정보는 소중하니까요.',
			description: `<b>${email}</b>로 <b>기관명</b>을 적어 보내주시면<br/>인증 후 아이디와 비밀번호를 안내해 드리겠습니다.`,
			confirmText: '메일 보내기',
		}),
		congratulation: () => ({
			title: '축하합니다!<br/>broof 회원이 되셨어요!',
			description: `로그인하고 첫번째 블록체인 증서를<br />발급해 보세요.`,
			confirmText: '로그인 하기',
		}),
		expiredToken: () => ({
			title: '아이고!<br/>한 발 늦으셨네요.',
			description: `이메일 인증 기한이 지났어요.<br />인증 메일을 다시 보내세요.`,
			confirmText: '인증 메일 보내기',
		}),
		usernameError: '아이디를 입력해 주세요.',
		passwordError: '비밀번호를 입력해 주세요.',
		idTitle: '아이디',
		passwordTitle: '비밀번호',
		confirmText: '확인',
		signup: '회원가입',
		find: '아이디 / 비밀번호 찾기'
	},
	signup: {
		emailError: '이메일을 입력해 주세요.',
		emailForm: '이메일 양식에 맞춰서 입력해 주세요.',
		passwordError: '비밀번호를 입력해 주세요.',
		passwordConfirm: '비밀번호를 한번 더 입력해 주세요.',
		passwordForm: '특수 문자, 영어 소문자, 숫자를 모두 포함해 주세요. (6~50자)',
		passwordDifferent: '입력하신 비밀번호와 다릅니다. 확인해주세요.',
		nameError: '기관명을 입력해 주세요.',
		_congratulation: (alias, username) => ({
			title: '축하합니다!<br/>이제 증명서를 발급해볼까요?',
			description: `기관명: ${alias}<br/>이메일: ${username}`,
			confirmText: '증명서 발급하러 가기',
		}),
		checkEmail: username => ({
			title: '가입하신 이메일 주소로<br />인증 메일을 전송했습니다.',
			description: `이메일에서 인증 버튼을 눌러 완료해 주세요.<br /><b>${username}</b>`,
			confirmText: '이메일 확인하기',
		}),
		title: `#broof로 블록체인 증서를 발급하기로 하셨군요!\n역시 앞서나가는 선택이십니다.`,
		emailTitle: '이메일',
		passwordTitle: '비밀번호',
		passwordConfirmTitle: '비밀번호 확인',
		nameTitle: '기관명',
		wallet: '지갑 연결',
		option: '선택',
		iconex: 'ICONex 연결하기',
		confirmText: '확인',
		login: '로그인'
	},
	footer: {
		keep: `당신의 시간과 노력을 영원히 보관합니다.`,
		information: `(주) 아이콘루프  |  대표 김종협\n서울특별시 중구 청계천로 100, 3층 | 02-6105-8122\n사업자등록번호 647-81-00375\n통신판매업신고  제 2019-서울중구-0854호`,
		plan: '이용 요금 안내',
		guide: '발급기관 가이드',
		notice: '공지사항 & 뉴스',
		terms: '이용 약관',
		policy: '개인 정보 처리 방침',
		disclaimer: '이용 면책 조항',
	},
	header: {
		start: '시작하기',
		pcOnly: '아이고!\n아쉽지만 서비스 이용은 PC에서만 가능해요.',
		login: '로그인',
		logout: '로그아웃',
		signup: '회원 가입',
		issue: '발급',
		plans: '요금 안내',
	},
	lefter: {
		hello: '안녕하세요.',
		user: name => `${name}`,
		new: '새 증명서 만들기',
		mine: '내 이용권',
		buy: '이용권 구매하기',
		balance: balance => `<span>${balance}</span><span>개</span>`,
		menu: {
			project: {
				icon: MdAssignment,
				name: '증명서',
				route: ROUTE.project
			},
			information: {
				icon: FaUserCircle,
				name: '내 정보',
				route: ROUTE.information
			}
		},
		logout: '로그아웃'
	},
	table: {
		name: '이름',
		password: '비밀 번호',
		extra: '추가 정보',
		status: '발급 현황'
	}
}