import React from 'react'
import './Footer.scss'
import footerLogo from '../../images/broof_footer_logo.png';
import { withRouter } from 'react-router'
import { EMAIL, POLICY, MEDIUM, TERMS, FAQ, ROUTE, DISCLAIMER } from '../../others/const'
import withLanguage from '../../HOC/withLanguage';

class Footer extends React.Component {

    goToPaymentPage = () => {
        this.props.history.push(ROUTE.payment);
    }

    viewUsageGuide = () => {
        window.open(this.props.i18n.USER_GUIDE, '_blank');
    }

    render(){
        const { i18n } = this.props
        return (
            <div className='footer'>

                {/* Footer left section */}
                <div>
                    <div>
                        <img src={footerLogo} alt='logo'></img>
                        <span>{i18n.footer.keep}</span>
                    </div>
                    <span>{i18n.footer.information}</span>
                </div>

                {/* Footer right div */}
                <div>
                    <span>
                        <a href={MEDIUM} rel="noopener noreferrer" target='_blank'>{i18n.footer.notice}</a>
                        &emsp;|&emsp;
                        <a href={TERMS} rel="noopener noreferrer" target='_blank'>{i18n.footer.terms}</a>
                        &emsp;|&emsp;
                        <a href={POLICY} rel="noopener noreferrer" target='_blank'>{i18n.footer.policy}</a>
                        &emsp;|&emsp;
                        <a href={DISCLAIMER} rel="noopener noreferrer" target='_blank'>{i18n.footer.disclaimer}</a>
                        &emsp;|&emsp;
                        <a href={FAQ} rel="noopener noreferrer" target='_blank'>FAQ</a>
                        &emsp;|&emsp;
                        <a href={`mailto:${EMAIL}`}>{EMAIL}</a> <span role="img" aria-label="">😍</span>
                    </span>
                    <span>
                        <button onClick={this.goToPaymentPage}>{i18n.footer.plan}</button>
                        <button onClick={this.viewUsageGuide}>{i18n.footer.guide}</button>
                    </span>
                </div>
            </div>
        )    
    }
}

export default withRouter(withLanguage(Footer))