import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MdInsertPhoto } from 'react-icons/md'
import { COLOR_SET } from '../../../../../others/const';
import Button, { ButtonType } from '../../../../parts/Button'
import ReactFileReader from 'react-file-reader';
import './Data.scss'
import withLanguage from '../../../../../HOC/withLanguage';
import { setPopup } from '../../../../../redux';

class Data extends Component {
    handleBackgroundFile = file => {
        this.props.setBackgroundImage(file)
    }

    handleLogoFile = file => {
        this.props.setLogoImage(file)
    }

    handleCertificateFile = file => {
        this.props.setCertificateImage(file)
    }


    triggerID = 'certificate-image-trigger'
    
    alertCertificateImage = () => {
        this.props.setPopup({
            ...this.props.i18n.data.alert,
            confirmCallback: () => {
                const element = document.getElementById(this.triggerID)
                if (element) {
                    element.click()
                }
            }
        })
    }

    render() {
        const { 
            type,
            templateTitle,
            templateSubTitle,
            disabled,
            i18n
        } = this.props

        return (
            <div className='project-create-data'>
                <div>
                    <span>{i18n.data.type}</span>
                    <input
                        name='type'
                        placeholder={type || i18n.TEMPLATE_SETTINGS.type}
                        value={type || ''}
                        onChange={this.props.handleChange}
                        disabled={disabled}
                    />
                </div>
                <div className='background'>
                    <span>{i18n.data.background}</span>
                    <span className='color'>
                        {Object.keys(COLOR_SET).map((key, index) => {
                            const backgroundColor = COLOR_SET[key]
                            const isImage = key === 'image'
                            return (
                                !isImage ?
                                    <div key={index}
                                        className={disabled ? 'disabled' : ''}
                                        style={{ backgroundColor }}
                                        onClick={() => { this.props.setBackgroundColor(backgroundColor) }}
                                    >
                                    </div>
                                    :
                                    <ReactFileReader
                                        key={index}
                                        handleFiles={this.handleBackgroundFile}
                                        multipleFiles={false}
                                        fileTypes={[".jpg", ".png", ".gif"]}
                                        base64={true}
                                        disabled={disabled}
                                    >
                                        <div style={{ backgroundColor }}>
                                            <MdInsertPhoto />
                                        </div>
                                    </ReactFileReader>
                            )
                        })}
                    </span>
                </div>
                <div>
                    <span>{i18n.data.logo}</span>
                    <span className='has-button'>
                        <span>{i18n.data.logoRecommend}</span>
                        <ReactFileReader
                            handleFiles={this.handleLogoFile}
                            multipleFiles={false}
                            fileTypes={[".jpg", ".png"]}
                            base64={true}
                            disabled={disabled}
                        >
                            <Button content={i18n.data.attach} type={ButtonType.LINE} />
                        </ReactFileReader>
                    </span>
                </div>
                <div>
                    <span>{i18n.data.title}</span>
                    <textarea
                        name='templateTitle'
                        className='title'
                        value={templateTitle || ''}
                        placeholder={templateTitle || i18n.TEMPLATE_SETTINGS.templateTitle}
                        onChange={this.props.handleChange}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <span>{i18n.data.subTitle}</span>
                    <textarea
                        name='templateSubTitle'
                        className='sub-title'
                        value={templateSubTitle || ''}
                        placeholder={templateSubTitle || i18n.TEMPLATE_SETTINGS.templateSubTitle}
                        onChange={this.props.handleChange}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <span>{i18n.data.certificate}</span>
                    <span className='has-button'>
                        <span>{i18n.data.certificateRecommend}</span>
                        <Button content={i18n.data.attach} 
                            type={ButtonType.LINE} 
                            onClick={this.alertCertificateImage}
                        />
                        <ReactFileReader
                            handleFiles={this.handleCertificateFile}
                            multipleFiles={false}
                            fileTypes={[".jpg", ".png"]}
                            base64={true}
                            disabled={disabled}
                        >
                            <button id={this.triggerID} style={{ width: 0, padding: 0 }}></button>
                        </ReactFileReader>
                    </span>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        setPopup: payload => dispatch(setPopup(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(Data))