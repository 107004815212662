import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { MdAdd, MdMoreHoriz, MdPlayCircleFilled, MdDelete, MdWeb, MdPeople, MdLibraryAdd, MdMoreVert, MdPauseCircleFilled } from 'react-icons/md'
import { ROUTE, PROJECT_ROUTE, PROJECT_STATE, dotYYYYMMDD } from '../../../others/const';
import { numberWithCommas, makeBroofIoUrl, getTodayDate, isSameOrBeforeDate } from '../../../others/util';
import './Card.scss'
import { deleteProject, setPopup, changeProjectState, copyProject, addToast } from '../../../redux';
import ApiManager from '../../../ApiManager'
import moment from 'moment';
import withLanguage from '../../../HOC/withLanguage';
import gtag from '../../../others/gtag';

class ProjectCard extends Component {
    state = {
        more: false,
    }

    // CHECK
    // 순서 중요
    toggleStatus = async () => {
        const { data, i18n } = this.props
        const { ERROR } = i18n
        const { state, projectId } = data
        const shouldPause = state !== PROJECT_STATE.PAUSED
        const callback = ({ error }) => {
            if (error) {
                this.props.setPopup({
                    ...i18n.card.retry(ERROR[error])
                })
            }
        }
        this.props.changeProjectState({
            api: ApiManager.postProjectsPausedStatus,
            payload: { projectId, shouldPause },
            projectId,
            callback
        })
    }

    // CHECK
    // 순서 중요
    actions = [{
        title: this.props.i18n.card.pause,
        icon: MdPauseCircleFilled,
        red: true,
        onClick: this.toggleStatus
    }, {
        title: this.props.i18n.card.template,
        icon: MdWeb,
        // TODO
        // 중복 제거
        onClick: () => {
            const { projectId } = this.props.data
            const url = `${ROUTE.project}${PROJECT_ROUTE.edit}/${projectId}${this.props.i18n.create.tab.template.hash}`
            this.props.history.push(url)
        }
    }, {
        title: this.props.i18n.card.recipient,
        icon: MdPeople,
        onClick: () => {
            const { projectId } = this.props.data
            const url = `${ROUTE.project}${PROJECT_ROUTE.edit}/${projectId}${this.props.i18n.create.tab.people.hash}`
            this.props.history.push(url)
        }
    }, {
        title: this.props.i18n.card.copy,
        icon: MdLibraryAdd,
        disabled: false, 
        onClick: () => {
            this.toggleMore()
            const { projectId } = this.props.data
            this.props.copyProject({ projectId })
        }
    }, {
        title: this.props.i18n.card.delete,
        icon: MdDelete,
        onClick: () => {
            this.toggleMore()
            this.props.setPopup({
                ...this.props.i18n.card.deleteProject(),
                confirmCallback: () => {
                    const { projectId } = this.props.data
                    this.props.deleteProject({ projectId })
                }
            })
        }
    }]

    toggleMore = () => {
        this.setState({ more: !this.state.more })
    }

    onNewClick = () => {
        gtag('event', 'new')
        this.props.history.push(`${ROUTE.project}${PROJECT_ROUTE.create}`)
    }

    getActionList = () => {
        const result = [...this.actions]

        const resume = {
            title: this.props.i18n.card.resume,
            icon: MdPlayCircleFilled,
            onClick: this.toggleStatus
        }

        switch (this.props.data.state) {
            case PROJECT_STATE.PAYMENT_REQUIRED:
            case PROJECT_STATE.FINISHED:
                result[0] = resume
                result[0].disabled = true
                return result

            case PROJECT_STATE.PAUSED:
                result[0] = resume
                return result

            default:
                return result
        }
    }

    getBottomText = () => {
        const { i18n } = this.props
        const { startDate, state, issueCount, numOfRecipients } = this.props.data
        const isSameOrBefore = isSameOrBeforeDate(startDate)

        switch (true) {
            case state === PROJECT_STATE.PAYMENT_REQUIRED:
                return i18n.card.finish

            case state === PROJECT_STATE.STANDBY:
            case state === PROJECT_STATE.PAUSED && !isSameOrBefore:
                return i18n.card.start(moment(startDate, dotYYYYMMDD).diff(getTodayDate(), 'day'))

            case state === PROJECT_STATE.PAUSED && isSameOrBefore:
            case state === PROJECT_STATE.IN_PROGRESS:
            case state === PROJECT_STATE.FINISHED:
                return `${numberWithCommas(issueCount)} / ${numberWithCommas(numOfRecipients)}`

            default:
                return ''
        }
    }

    render() {
        const { data, i18n } = this.props
        const { more } = this.state // TODO more 한번에 정리

        if (data) {
            const { projectCode, projectName, startDate, endDate, state, issueCount, numOfRecipients } = data
            const url = makeBroofIoUrl(projectCode)
            const progress = numOfRecipients ? Math.round(issueCount / numOfRecipients * 100) : 0
            const MoreIcon = more ? MdMoreVert : MdMoreHoriz
            const isTemporary = state === PROJECT_STATE.PAYMENT_REQUIRED
            const actionList = this.getActionList()
            const bottomText = this.getBottomText()
            const label = (state === PROJECT_STATE.FINISHED) && (issueCount !== numOfRecipients) ? i18n.PROJECT_STATE_LABEL.EXPIRED : i18n.PROJECT_STATE_LABEL[state]
            const isSameOrBefore = isSameOrBeforeDate(startDate)
            const styleType = state === PROJECT_STATE.PAUSED && !isSameOrBefore ? `${PROJECT_STATE.PAUSED} scheduled` : state

            return (
                <div className={`project-card data ${styleType}${more ? ' available' : ''}`}>
                    <div className='top' onClick={() => {
                        const { projectId } = this.props.data
                        const url = `${ROUTE.project}${PROJECT_ROUTE.edit}/${projectId}${i18n.create.tab.template.hash}`
                        this.props.history.push(url)
                    }}>
                        {!isTemporary && <a rel="noopener noreferrer" href={url} target="_blank">{url}</a>}
                        <h2>{projectName || i18n.TEMPLATE_SETTINGS.projectName}</h2>
                        {!isTemporary && <p>{`${startDate} ~ ${endDate}`}</p>}
                    </div>
                    <div className='bottom' onClick={() => {
                        const { projectId } = this.props.data
                        const url = `${ROUTE.project}${PROJECT_ROUTE.edit}/${projectId}${i18n.create.tab.template.hash}`
                        this.props.history.push(url)
                    }}>
                        <div className='progress'><div style={{ width: `${progress}%` }}></div></div>
                        <span className='label'>{label}</span>
                        {!isTemporary && <span className='percentage'>{`${progress} %`}</span>}
                        <p>{bottomText}</p>
                    </div>
                    <div className='more'>
                        <div onClick={this.toggleMore}><MoreIcon /></div>
                        {actionList.map((action, index) => {
                            const { icon, onClick, title, disabled, red } = action
                            const ActionIcon = icon
                            return more && <div className={`${disabled ? 'disabled' : red ? 'red' : ''}`} key={index} title={title} onClick={onClick}><ActionIcon /></div>
                        })}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='project-card new' onClick={this.onNewClick}>
                    <MdAdd />
                    <p>{i18n.card.new}</p>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        deleteProject: payload => dispatch(deleteProject(payload)),
        setPopup: payload => dispatch(setPopup(payload)),
        changeProjectState: payload => dispatch(changeProjectState(payload)),
        copyProject: payload => dispatch(copyProject(payload)),
        addToast: payload => dispatch(addToast(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(ProjectCard)))
