const postechGolfAlumniText = `
[{
	"name": "김화룡",
	"code": "0991",
	"date": "2019. 08. 27 (21차)",
	"title": "스코어 우승 / 아시아나 CC"
}, {
	"name": "김종균",
	"code": "9183",
	"date": "2019. 08. 27 (21차)",
	"title": "스코어 우승 / 아시아나 CC"
}, {
	"name": "현석진",
	"code": "5910",
	"date": "2019. 08. 27 (21차)",
	"title": "신페리오 우승 / 아시아나 CC"
}, {
	"name": "김화룡",
	"code": "9926",
	"date": "2019. 08. 27 (21차)",
	"title": "신페리오 준우승 / 아시아나 CC"
}, {
	"name": "박재홍",
	"code": "2021",
	"date": "2019. 08. 27 (21차)",
	"title": "신페리오 준우승 / 아시아나 CC"
}, {
	"name": "홍길동",
	"code": "1579",
	"date": "2019. 08. 27 (21차)",
	"title": "테스트 발급"
}, {
	"name": "김화룡",
	"code": "8702",
	"date": "2017. 08. 27 (21차)",
	"title": "메달리스트 / 아시아나 CC"
}, {
	"name": "김종균",
	"code": "6593",
	"date": "2017. 08. 27 (21차)",
	"title": "메달리스트 / 아시아나 CC"
}, {
	"name": "현석진",
	"code": "9780",
	"date": "2017. 08. 27 (21차)",
	"title": "우승 / 아시아나 CC"
}, {
	"name": "김화룡",
	"code": "2955",
	"date": "2017. 08. 27 (21차)",
	"title": "준우승 / 아시아나 CC"
}, {
	"name": "박재홍",
	"code": "7120",
	"date": "2017. 08. 27 (21차)",
	"title": "준우승 / 아시아나 CC"
}, {
	"name": "유주현",
	"code": "5468",
	"date": "2017. 08. 27 (21차)",
	"title": "롱기스트 / 아시아나 CC"
}, {
	"name": "차준회",
	"code": "6508",
	"date": "2017. 08. 27 (21차)",
	"title": "롱기스트 / 아시아나 CC"
}, {
	"name": "김경선",
	"code": "2137",
	"date": "2017. 08. 27 (21차)",
	"title": "3위 / 아시아나 CC"
}, {
	"name": "유주현",
	"code": "0074",
	"date": "2017. 08. 27 (21차)",
	"title": "니어리스트 / 아시아나 CC"
}, {
	"name": "박상태",
	"code": "6935",
	"date": "2017. 08. 27 (21차)",
	"title": "니어리스트 / 아시아나 CC"
}, {
	"name": "홍길동",
	"code": "2713",
	"date": "2017. 08. 27 (21차)",
	"title": "니어리스트 / 아시아나 CC"
}, {
	"name": "유주현",
	"code": "1435",
	"date": "2017. 03. 21 (1차)",
	"title": "스코어 우승 / 남촌 CC"
}, {
	"name": "유주현",
	"code": "0526",
	"date": "2017. 03. 21 (1차)",
	"title": "신페리오 우승 / 남촌 CC"
}, {
	"name": "차준회",
	"code": "5484",
	"date": "2017. 04. 20 (2차)",
	"title": "스코어 우승"
}, {
	"name": "홍성호",
	"code": "4987",
	"date": "2017. 04. 20 (2차)",
	"title": "신페리오 우승"
}, {
	"name": "유주현",
	"code": "7145",
	"date": "2017. 05. 18 (3차)",
	"title": "스코어 우승 / 아시아나 CC"
}, {
	"name": "박재홍",
	"code": "6525",
	"date": "2017. 05. 18 (3차)",
	"title": "신페리오 우승 / 아시아나 CC"
}, {
	"name": "현석진",
	"code": "6323",
	"date": "2017. 05. 18 (3차)",
	"title": "신페리오 준우승 / 아시아나 CC"
}, {
	"name": "장민",
	"code": "1408",
	"date": "2017. 05. 18 (3차)",
	"title": "신페리오 3등 / 아시아나 CC"
}, {
	"name": "김화룡",
	"code": "2379",
	"date": "2017. 06. 27 (4차)",
	"title": "스코어 우승 / 블루원 용인 CC"
}, {
	"name": "김화룡",
	"code": "1188",
	"date": "2017. 06. 27 (4차)",
	"title": "신페리오 우승  / 블루원 용인 CC"
}, {
	"name": "박재홍",
	"code": "0573",
	"date": "2017. 06. 27 (4차)",
	"title": "신페리오 준우승  / 블루원 용인 CC"
}, {
	"name": "유주현",
	"code": "1964",
	"date": "2017. 06. 27 (4차)",
	"title": "신페리오 3등  / 블루원 용인 CC"
}, {
	"name": "김화룡",
	"code": "1264",
	"date": "2017. 07. 25 (5차)",
	"title": "스코어 우승 / 아시아나 CC"
}, {
	"name": "현석진",
	"code": "2679",
	"date": "2017. 07. 25 (5차)",
	"title": "신페리오 우승 / 아시아나 CC"
}, {
	"name": "장민",
	"code": "0309",
	"date": "2017. 07. 25 (5차)",
	"title": "신페리오 우승 / 아시아나 CC"
}, {
	"name": "차준회",
	"code": "6887",
	"date": "2017. 07. 25 (5차)",
	"title": "신페리오 3등 / 아시아나 CC"
}, {
	"name": "김화룡",
	"code": "5829",
	"date": "2017. 09. 26 (6차)",
	"title": "스코어 우승 / 신라 CC"
}, {
	"name": "김화룡",
	"code": "6085",
	"date": "2017. 09. 26 (6차)",
	"title": "신페리오 우승 / 신라 CC"
}, {
	"name": "홍성호",
	"code": "2359",
	"date": "2017. 09. 26 (6차)",
	"title": "신페리오 준우승 / 신라 CC"
}, {
	"name": "유주현",
	"code": "6938",
	"date": "2017. 09. 26 (6차)",
	"title": "신페리오 3등 / 신라 CC"
}, {
	"name": "유주현",
	"code": "4383",
	"date": "2017. 10. 24 (7차)",
	"title": "스코어 우승 / 신라 CC"
}, {
	"name": "유주현",
	"code": "0761",
	"date": "2017. 10. 24 (7차)",
	"title": "신페리오 우승 / 신라 CC"
}, {
	"name": "박재홍",
	"code": "4577",
	"date": "2017. 11. 28 (8차)",
	"title": "스코어 우승 / 남촌 CC"
}, {
	"name": "장민",
	"code": "0413",
	"date": "2017. 11. 28 (8차)",
	"title": "신페리오 우승 / 남촌 CC"
}, {
	"name": "유주현",
	"code": "8892",
	"date": "2017. 11. 28 (8차)",
	"title": "신페리오 준우승 / 남촌 CC"
}, {
	"name": "박재홍",
	"code": "6313",
	"date": "2017. 11. 28 (8차)",
	"title": "신페리오 3등 / 남촌 CC"
}, {
	"name": "김화룡",
	"code": "8605",
	"date": "2018. 03. 27 (9차)",
	"title": "스코어 우승 / 신라 CC"
}, {
	"name": "김화룡",
	"code": "1418",
	"date": "2018. 03. 27 (9차)",
	"title": "신페리오 우승 / 신라 CC"
}, {
	"name": "유주현",
	"code": "5027",
	"date": "2018. 03. 27 (9차)",
	"title": "신페리오 준우승 / 신라 CC"
}, {
	"name": "현석진",
	"code": "1556",
	"date": "2018. 03. 27 (9차)",
	"title": "신페리오 3등 / 신라 CC"
}, {
	"name": "김화룡",
	"code": "3635",
	"date": "2018. 04. 24 (10차)",
	"title": "스코어 우승 / 신라 CC"
}, {
	"name": "장민",
	"code": "6941",
	"date": "2018. 04. 24 (10차)",
	"title": "신페리오 우승 / 신라 CC"
}, {
	"name": "고승용",
	"code": "2827",
	"date": "2018. 04. 24 (10차)",
	"title": "신페리오 준우승 / 신라 CC"
}, {
	"name": "김화룡",
	"code": "7930",
	"date": "2018. 04. 24 (10차)",
	"title": "신페리오 3등 / 신라 CC"
}, {
	"name": "김화룡",
	"code": "6412",
	"date": "2018. 05. 23 (11차)",
	"title": "스코어 우승 / 골드 CC"
}, {
	"name": "홍성호",
	"code": "0153",
	"date": "2018. 05. 23 (11차)",
	"title": "신페리오 우승 / 골드 CC"
}, {
	"name": "박재홍",
	"code": "5565",
	"date": "2018. 05. 23 (11차)",
	"title": "신페리오 준우승 / 골드 CC"
}, {
	"name": "유주현",
	"code": "3907",
	"date": "2018. 05. 23 (11차)",
	"title": "신페리오 3등 / 골드 CC"
}, {
	"name": "유주현",
	"code": "3724",
	"date": "2018. 07. 24 (12차)",
	"title": "스코어 우승 / 한성 CC"
}, {
	"name": "현석진",
	"code": "0006",
	"date": "2018. 07. 24 (12차)",
	"title": "신페리오 우승 / 한성 CC"
}, {
	"name": "박재홍",
	"code": "9316",
	"date": "2018. 07. 24 (12차)",
	"title": "신페리오 준우승 / 한성 CC"
}, {
	"name": "차준회",
	"code": "9713",
	"date": "2018. 07. 24 (12차)",
	"title": "신페리오 3등 / 한성 CC"
}, {
	"name": "송병창",
	"code": "4976",
	"date": "2018. 09. 18 (14차)",
	"title": "스코어 우승 / 이포 CC"
}, {
	"name": "장민",
	"code": "2678",
	"date": "2018. 09. 18 (14차)",
	"title": "신페리오 우승 / 이포 CC"
}, {
	"name": "김종균",
	"code": "6002",
	"date": "2018. 11. 17 (16차)",
	"title": "스코어 우승 / 큐로경기 CC"
}, {
	"name": "김종균",
	"code": "2355",
	"date": "2018. 11. 17 (16차)",
	"title": "신페리오 우승 / 큐로경기 CC"
}, {
	"name": "고승용",
	"code": "4090",
	"date": "2018. 11. 17 (16차)",
	"title": "신페리오 준우승 / 큐로경기 CC"
}, {
	"name": "김철호",
	"code": "8715",
	"date": "2018. 11. 17 (16차)",
	"title": "신페리오 3등 / 큐로경기 CC"
}, {
	"name": "김화룡",
	"code": "8195",
	"date": "2019. 03. 26 (17차)",
	"title": "스코어 우승 / 아시아나 CC"
}, {
	"name": "김종균",
	"code": "9357",
	"date": "2019. 03. 26 (17차)",
	"title": "신페리오 우승 / 아시아나 CC"
}, {
	"name": "차준회",
	"code": "1940",
	"date": "2019. 03. 26 (17차)",
	"title": "신페리오 준우승 / 아시아나 CC"
}, {
	"name": "유주현",
	"code": "0826",
	"date": "2019. 03. 26 (17차)",
	"title": "신페리오 준우승 / 아시아나 CC"
}, {
	"name": "김화룡",
	"code": "4888",
	"date": "2019. 04. 23 (18차)",
	"title": "스코어 우승 / 아시아나 CC"
}, {
	"name": "김화룡",
	"code": "5899",
	"date": "2019. 04. 23 (18차)",
	"title": "신페리오 우승 / 아시아나 CC"
}, {
	"name": "김종균",
	"code": "2800",
	"date": "2019. 04. 23 (18차)",
	"title": "신페리오 준우승 / 아시아나 CC"
}, {
	"name": "함일한",
	"code": "1175",
	"date": "2019. 04. 23 (18차)",
	"title": "신페리오 준우승 / 아시아나 CC"
}, {
	"name": "김종균",
	"code": "3576",
	"date": "2019. 05. 21 (19차)",
	"title": "스코어 우승 / 아시아나 CC"
}, {
	"name": "유주현",
	"code": "4101",
	"date": "2019. 05. 21 (19차)",
	"title": "신페리오 우승 / 아시아나 CC"
}, {
	"name": "정성일",
	"code": "0876",
	"date": "2019. 05. 21 (19차)",
	"title": "신페리오 준우승 / 아시아나 CC"
}, {
	"name": "김종균",
	"code": "3795",
	"date": "2019. 05. 21 (19차)",
	"title": "신페리오 3등 / 아시아나 CC"
}, {
	"name": "홍원기",
	"code": "3484",
	"date": "2019. 06. 08",
	"title": "스코어 우승 / 써닝포인트 CC"
}, {
	"name": "고병철",
	"code": "2749",
	"date": "2019. 06. 08",
	"title": "신페리오 우승 / 써닝포인트 CC"
}, {
	"name": "김영국",
	"code": "5430",
	"date": "2019. 06. 08",
	"title": "신페리오 준우승 / 써닝포인트 CC"
}, {
	"name": "김민철",
	"code": "2552",
	"date": "2019. 06. 25 (20차)",
	"title": "스코어 우승 / 아시아나 CC"    
}, {
	"name": "김종균",
	"code": "5742",
	"date": "2019. 06. 25 (20차)",
	"title": "신페리오 우승 / 아시아나 CC"    
}, {
	"name": "이창호",
	"code": "2477",
	"date": "2019. 06. 25 (20차)",
	"title": "신페리오 준우승 / 아시아나 CC"    
}, {
	"name": "김경선",
	"code": "1609",
	"date": "2019. 06. 25 (20차)",
	"title": "신페리오 3등 / 아시아나 CC"    
}]
` 

const bcapitalistText = `
[{
	"name": "조석준",
	"code": "4369",
	"company": "기후변화저널"
}, {
	"name": "오상배",
	"code": "7554",
	"company": "라이프애비뉴파트너스"
}, {
	"name": "톰구",
	"code": "4068",
	"company": "메모랜덤 캐피탈"
}, {
	"name": "윤중식",
	"code": "9991",
	"company": "메트라이프"
}, {
	"name": "유재범",
	"code": "5400",
	"company": "몬스터큐브"
}, {
	"name": "하동우",
	"code": "6560",
	"company": "미디움"
}, {
	"name": "김지운",
	"code": "0951",
	"company": "바모센트"
}, {
	"name": "최만연",
	"code": "4846",
	"company": "블랙록자산운용"
}, {
	"name": "김지은",
	"code": "9519",
	"company": "블랙록자산운용"
}, {
	"name": "강욱태",
	"code": "9506",
	"company": "블록웨어"
}, {
	"name": "홍봉락",
	"code": "5003",
	"company": "블록웨어"
}, {
	"name": "남영우",
	"code": "6701",
	"company": "블록체인익스체인지"
}, {
	"name": "김원삼",
	"code": "7994",
	"company": "산업은행"
}, {
	"name": "김종현",
	"code": "8449",
	"company": "산업은행"
}, {
	"name": "임창도",
	"code": "0743",
	"company": "산업은행"
}, {
	"name": "정은수",
	"code": "9383",
	"company": "슈로더"
}, {
	"name": "정의석",
	"code": "7177",
	"company": "신영증권"
}, {
	"name": "이성호",
	"code": "3099",
	"company": "신한금융지주회사"
}, {
	"name": "윤하리",
	"code": "2488",
	"company": "신한은행"
}, {
	"name": "김윤지",
	"code": "2424",
	"company": "에이티넘인베스트먼트"
}, {
	"name": "임시규",
	"code": "8768",
	"company": "에코스인베스트먼트"
}, {
	"name": "박경근",
	"code": "8324",
	"company": "엑시스소프트"
}, {
	"name": "민문호",
	"code": "3969",
	"company": "오썸피아"
}, {
	"name": "오승환",
	"code": "5307",
	"company": "웰릭스캐피탈"
}, {
	"name": "이승재",
	"code": "6278",
	"company": "유안타"
}, {
	"name": "박상우",
	"code": "8196",
	"company": "유안타"
}, {
	"name": "강소희",
	"code": "7314",
	"company": "유안타"
}, {
	"name": "박민호",
	"code": "0893",
	"company": "유진자산운용"
}, {
	"name": "박천웅",
	"code": "8364",
	"company": "이스트스프링자산운용코리아"
}, {
	"name": "조원선",
	"code": "3643",
	"company": "인큐블록"
}, {
	"name": "김장환",
	"code": "9527",
	"company": "자인"
}, {
	"name": "김두호",
	"code": "1498",
	"company": "저축은행중앙회"
}, {
	"name": "이준",
	"code": "2829",
	"company": "전자신문"
}, {
	"name": "최종연",
	"code": "4906",
	"company": "케이앤투자파트너스"
}, {
	"name": "문정환",
	"code": "5393",
	"company": "키스톤컨설팅"
}, {
	"name": "나윤서",
	"code": "9128",
	"company": "테크앤홀딩스 기획이사"
}, {
	"name": "양윤수",
	"code": "6042",
	"company": "투비코"
}, {
	"name": "김승구",
	"code": "4439",
	"company": "펜타시큐리티"
}, {
	"name": "이하준",
	"code": "9324",
	"company": "하이자산운용"
}, {
	"name": "임명수",
	"code": "4760",
	"company": "한국블록체인스타트업협회"
}, {
	"name": "임영창",
	"code": "1288",
	"company": "한국여신전문금융업협회"
}, {
	"name": "김학준",
	"code": "0714",
	"company": "한국예탁결제원"
}, {
	"name": "노태승",
	"code": "5106",
	"company": "한국투자공사"
}, {
	"name": "김기연",
	"code": "0224",
	"company": "한국투자공사"
}, {
	"name": "조준환",
	"code": "6125",
	"company": "한국투자신탁운용"
}, {
	"name": "심재환",
	"code": "8437",
	"company": "한국투자신탁운용"
}, {
	"name": "김빈나",
	"code": "1703",
	"company": "한국투자신탁운용"
}, {
	"name": "안혁",
	"code": "0255",
	"company": "한국투자증권"
}, {
	"name": "김승식",
	"code": "3206",
	"company": "한국M&A센터"
}, {
	"name": "이재권",
	"code": "6100",
	"company": "한남"
}, {
	"name": "정준",
	"code": "5341",
	"company": "한화투자증권"
}, {
	"name": "오승택",
	"code": "1581",
	"company": "헤이스팅스자산운용 주식회사"
}, {
	"name": "김기범",
	"code": "1256",
	"company": "헥슬란트"
}, {
	"name": "민황기",
	"code": "7507",
	"company": "Aisys Global"
}, {
	"name": "김근아",
	"code": "2937",
	"company": "BRP"
}, {
	"name": "최영석",
	"code": "0522",
	"company": "Buzzling"
}, {
	"name": "이주영",
	"code": "2838",
	"company": "CJ올리브네트웍스"
}, {
	"name": "이신혜",
	"code": "9940",
	"company": "GBIC"
}, {
	"name": "최재경",
	"code": "9597",
	"company": "Go together"
}, {
	"name": "이안나",
	"code": "0520",
	"company": "IDG Capital"
}, {
	"name": "임동민",
	"code": "3439",
	"company": "KB국민은행"
}, {
	"name": "시헌",
	"code": "7957",
	"company": "KB국민은행"
}, {
	"name": "오승주",
	"code": "3402",
	"company": "KB증권"
}, {
	"name": "이현석",
	"code": "6273",
	"company": "KB증권"
}, {
	"name": "이효진",
	"code": "1979",
	"company": "The Bitwin Global"
}, {
	"name": "이레이나",
	"code": "8476",
	"company": "Xperiment Edge"
}]
`
export const postechGolfAlumniList = JSON.parse(postechGolfAlumniText)
export const postechGolfPrefix = 'postech-golf'
export const bcapitalistList = JSON.parse(bcapitalistText)
export const bcapitalistPrefix = 'bcapitalist'
export const bcapitalist2nd = 'bcapitalist-2nd'
export const founders2019Summer = 'founders-2019-summer'
export const posahs = 'posahs'