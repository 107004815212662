import moment from 'moment'
import { parse } from 'papaparse';
import { isCsv, codeTest } from './regexr';
import { BASE_URL, dashYYYYMMDD, dotYYYYMMDD, TRACKER_URL, LANGUAGE, ROUTE } from './const';
import uuidv1 from 'uuid/v1'
import i18n from './i18n';

export function transformServerLanguage(language) {
    return language === LANGUAGE.EN ? 'en' : 'kor'
}

export function isProjectCode() {
    const routes = Object.values(ROUTE)
    if ((routes.includes(window.location.pathname) ||
        window.location.pathname === '/') ||
        window.location.pathname.includes(ROUTE.project)) {
        return false
    }
    return true
}

export function setRef(content) {
    return ref => { if (ref) ref.innerHTML = content }
}

export function execute(func, param) {
    if (typeof func === 'function') {
        return func(param)
    }
    return false
}

export function setClassName(list) {
    if (Array.isArray(list)) {
        return list.join(' ')
    }
    else {
        return list
    }
}

export function hideName(name) {
    let result = name[0]
    for (let i = 1; i < name.length; i++) {
        result += '*'
    }
    return result
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function isSameOrBeforeDate(date) {
    return moment(date, dotYYYYMMDD).isSameOrBefore(getTodayDate())
}

export function changeDashToDot(date) {
    if (moment(date, dotYYYYMMDD).isValid()) {
        return date
    }
    return moment(date, dashYYYYMMDD).format(dotYYYYMMDD)
}

export function getTodayDate() {
    return moment(moment().format(dotYYYYMMDD), dotYYYYMMDD)
}

export function getPayAmount(price, currency, discount) {
    const original = currency ? Math.ceil(price / currency) : price
    if (discount) {
        return Math.ceil(Number(original) * discount)
    }
    else {
        return original
    }
}

export function getRealPrice(ticket, currency) {
    const { price, discount } = ticket
    const original = currency ? Math.ceil(price / currency) : price
    if (discount) {
        return Math.ceil(Number(original) * discount)
    }
    else {
        return original
    }
}

export function getPayCount(count, bonus, currency) {
    const finalBonus = getBonus(bonus, currency)
    return count + finalBonus
}

export function getBonus(bonus, currency) {
    return currency ? bonus + 5 : bonus
}

export function numberWithCommas(number) {
    if (isNaN(number)) {
        return number
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function convertYYYYMMDD(date) {
    const m = moment.isMoment(date) ? date : moment(date)
    return m.format('YYYY.MM.DD')
}

export function makeBroofIoUrl(query) {
    return `${BASE_URL}${query}`
}

export async function parseCsv(file) {
    if (!isCsv.test(file.name)) {
        throw new Error('File type must be CSV.')
    }

    return new Promise((resolve, reject) => {
        parse(file, {
            complete: (results) => {
                resolve(results.data)
            },
            error: (e) => {
                reject(e)
            }
        });
    })
}

export function handleRecipients(data) {
    const result = []
    data.forEach(item => {
        if (!(isSameCsvHeader(item) && item[0]) && item[0] !== "") {
            const broofId = uuidv1()
            const name = item[0]
            const code = item[1]
            const _code = (code && codeTest.test(code)) ? code : getRandomSerial()
            const extraInfo = item[2] || ''
            const email = item[3] || ''
            const phone = item[4] || ''
            const address = item[5] || ''
            result.push({
                broofId,
                name,
                code: pad(_code, 4),
                extraInfo,
                email,
                phone,
                address
            })
        }
    })
    return result
}

export function makeCsvData(header, recipients) {
    if (Array.isArray(recipients) && recipients.length > 0) {
        let csv = [];
        csv.push(header);
        recipients.forEach((el, index) => {
            const { name, code, extraInfo, transactionHash } = el
            csv.push([index + 1, name, code, extraInfo, `${TRACKER_URL}transaction/${transactionHash}` || ''])
        })
        return csv
    }
    else {
        return []
    }
}

export function isSameCsvHeader(item) {
    return (item[0] === i18n.ko.CSV_TEMPLATE[0][0] || item[0] === i18n.en.CSV_TEMPLATE[0][0]) 
        && (item[1] === i18n.ko.CSV_TEMPLATE[0][1] || item[0] === i18n.en.CSV_TEMPLATE[0][1])
}

export function getRandomSerial(min = 0, max = 9999) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function pad(num, width = 4) {
    const str = String(num);
    return str.length >= width ? str : new Array(width - str.length + 1).join("0") + str;
}

export function startsWith(text, search) {
    if (typeof text !== 'string') {
        return false
    }

    return text.indexOf(search) === 0
}

export function scrollToTop() {
    let intervalId;
    const scroll = () => {
        if (window.pageYOffset === 0) {
            clearInterval(intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }
    intervalId = setInterval(scroll, 1)
}

export function iOS() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return true
    }

    return false
}

export function isMobile() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true;
    }

    return false;
}

export function isDevelopment() {
    return !(process.env.REACT_APP_ENV === 'prod' && process.env.NODE_ENV === 'production')
}

export function getImageSet(props) {
    const result = {}
    Object.keys(props).forEach(key => {
        if (!key.includes('Image') || !props[key]) {
            return
        }

        if (typeof props[key] === 'string') {
            result[key] = props[key]
            return
        }

        const { base64 } = props[key]
        result[key] = base64
    })

    return result
}

export function getExtraPosition(settings) {
    const { extra } = settings || {}
    const { fontPositionX, fontPositionY } = extra && extra[0] ? extra[0] : {}
    const extraPositionX = fontPositionX || 70.1
    const extraPositionY = fontPositionY || 0
    return {
        extraPositionX,
        extraPositionY
    }
}

export function getExtraInfo(data) {
    const { extra } = data
    const { str } = extra && extra[0] ? extra[0] : {}
    return str || ''
}

export function setExtra(recipient) {
    const copied = { ...recipient }
    const { extraInfo } = copied
    delete copied.extraInfo
    copied.extra = [{
        idx: 0,
        str: extraInfo
    }]
    return copied
}

export function getLanguage() {
    if (!navigator.languages) {
        return LANGUAGE.KO
    }

    if (navigator.languages.includes('ko') || 
        navigator.languages.includes('ko-KR')) {
        return LANGUAGE.KO
    }

    return LANGUAGE.EN
}

export function setDocumentTitle(language) {
    document.title = i18n[language || LANGUAGE.EN].document.title
}

export function devConsole() {
    const _isDevelopment = isDevelopment()
    if (!_isDevelopment) return

    const { length } = arguments
    if (!length) return
    
    switch(length) {
        case 10:
            console.log(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5], arguments[6], arguments[7], arguments[8], arguments[9])
            break
        case 9:
            console.log(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5], arguments[6], arguments[7], arguments[8])
            break
        case 8:
            console.log(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5], arguments[6], arguments[7])
            break
        case 7:
            console.log(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5], arguments[6])
            break
        case 6:
            console.log(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5])
            break
        case 5:
            console.log(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4])
            break
        case 4:
            console.log(arguments[0], arguments[1], arguments[2], arguments[3])
            break
        case 3:
            console.log(arguments[0], arguments[1], arguments[2])
            break
        case 2:
            console.log(arguments[0], arguments[1])
            break
        case 1:
            console.log(arguments[0])
            break
        default:
            console.log(arguments)
            break
    }
}