import { put, takeLatest, call, all, select } from 'redux-saga/effects'
import {
    GET_PROJECT_LIST,
    getProjectList,
    ISSUE_PROJECT,
    issueProject,
    DELETE_PROJECT,
    deleteProject,
    CHANGE_PROJECT_STATE,
    changeProjectState,
    copyProject,
    COPY_PROJECT,
    addToast,
} from '../redux';
import ApiManager from '../ApiManager'
import { delay } from '../others/util'
import i18n from '../others/i18n';

function* getProjectListAsync() {
    yield put(getProjectList.request(true))
    yield delay(2000)

    try {
        const { result } = yield call(ApiManager.getProjects)
        const list = {}
        result.reverse().forEach(item => { list[item.projectId] = { ...item } })
        yield put(getProjectList.success({ list }))
    }
    catch (e) {
        yield put(getProjectList.failure(e))
    }
}

function* changeProjectStateAsync(action) {
    yield put(changeProjectState.request(false))

    try {
        const list = { ...yield select(state => state.projectList.payload.list) }
        const { api, payload, projectId, callback } = action.payload
        const { result, error } = yield call(api, payload)
        if (result) {
            list[projectId] = {
                ...list[projectId],
                state: result
            }
            yield put(changeProjectState.success({ list }))
        }
        else {
            yield put(changeProjectState.failure(error))
        }

        if (typeof callback === 'function') {
            callback({ result, error })
        }
    }
    catch (e) {
        console.log(e)
        yield put(changeProjectState.failure(e))
    }
}

function* issueProjectAsync(action) {
    yield put(issueProject.request(false))

    try {
        const list = { ...yield select(state => state.projectList.payload.list) }
        const { projectId, callback } = action.payload
        const { result, error } = yield call(ApiManager.postProjectsPurchase, { projectId })

        if (typeof callback === 'function') {
            callback({ result, error })
        }

        if (error) {
            yield put(issueProject.failure(error))
            return
        }

        list[projectId] = { ...result }
        yield put(issueProject.success({ list }))
    }
    catch (e) {
        yield put(issueProject.failure(e))
    }
}

function* copyProjectAsync(action) {
    yield put(copyProject.request(false))
    try {
        const list = { ...yield select(state => state.projectList.payload.list) }
        const { projectId } = action.payload
        const { result, error } = yield call(ApiManager.projectClone, { projectId })

        const language = yield select(state => state.language.payload)
        const { projectName: oldName, projectId: newId } = result
        const projectName = [oldName || i18n[language].TEMPLATE_SETTINGS.projectName, i18n[language].card.copied].join(' ')        
        const { result: nameResult } = yield call(ApiManager.postProjectsSettings, { projectId: newId, body: { projectName }})
        if (nameResult) {
            result.projectName = projectName
        }

        const text = i18n[language].card[result ? 'copySuccess' : 'copyFail']
        if (text) {
            yield put(addToast({ text, error: !nameResult }))
        }

        if (error) {
            yield put(copyProject.failure(error))
            return
        }

        list[newId] = { ...result }
        yield put(copyProject.success({ list }))
    }
    catch (e) {
        yield put(copyProject.failure(e))
    }
}

function* deleteProjectAsync(action) {
    yield put(deleteProject.request(false))

    try {
        const list = { ...yield select(state => state.projectList.payload.list) }
        const { projectId } = action.payload
        const { result, error } = yield call(ApiManager.deleteProjects, { projectId })

        const language = yield select(state => state.language.payload)
        const text = i18n[language].card[result ? 'deleteSuccess' : 'deleteFail']
        if (text) {
            yield put(addToast({ text, error: !result }))
        }

        if (error) {
            yield put(deleteProject.failure(error))
            return
        }

        delete list[projectId]
        yield put(deleteProject.success({ list }))
    }
    catch (e) {
        deleteProject.failure(e)
    }
}

function* watchGetProjectList() {
    yield takeLatest(GET_PROJECT_LIST.INDEX, getProjectListAsync)
}

function* watchUpdateProject() {
    yield takeLatest(CHANGE_PROJECT_STATE.INDEX, changeProjectStateAsync)
}

function* watchIssueProject() {
    yield takeLatest(ISSUE_PROJECT.INDEX, issueProjectAsync)
}

function* watchCopyProject() {
    yield takeLatest(COPY_PROJECT.INDEX, copyProjectAsync)
}

function* watchDeleteProject() {
    yield takeLatest(DELETE_PROJECT.INDEX, deleteProjectAsync)
}

export default function* root() {
    yield all([
        watchGetProjectList(),
        watchUpdateProject(),
        watchIssueProject(),
        watchCopyProject(),
        watchDeleteProject(),
    ])
}