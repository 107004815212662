import axios from 'axios'
// import { delay } from '../others/util';

function getHost() {
    switch(process.env.REACT_APP_ENV) {
        case 'internal':
        case 'dev':
            return 'http://lb-dpass-issuer-1820190136.ap-northeast-2.elb.amazonaws.com'
        case 'prod':
        default:
            return "https://i-broof.broof.io"
    }
}

const HOST = getHost()

class DpassConnector {
    constructor(baseURL) {
        this.instance = axios.create({ baseURL });
    }

    open = async ({ projectCode, recipientCode, recipientName }) => {
        try {
            const reqToken = await this.getReqToken({ projectCode, recipientCode, recipientName })
            if (reqToken) {
                this.checkOutCredential(reqToken)
            }
            else {
                throw new Error('Token is invalid')
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    getReqToken = async ({ projectCode, recipientCode, recipientName }) => {
        try {
            const response = await this.instance.post(`/v1/reqToken`, {
                projectCode, 
                recipientCode, 
                recipientName 
            })

            const { reqToken } = response.data.result
            return reqToken
        }
        catch (e) {
            console.error(e)
            return ''
        }
    }

    checkOutCredential = async reqToken => {
        const requestUrl = `${HOST}/v1/credential/${reqToken}`
        const data = btoa(JSON.stringify({ requestUrl }))
        const url = `dpass://checkOutCredential?data=${data}`
        // alert(`${reqToken}\n\n${requestUrl}\n\n${url}`)
        window.location.href = url
    }
}

export default new DpassConnector(HOST)
