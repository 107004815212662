import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { dotYYYYMMDD, ROUTE, BASE_URL, PREVIEW_MODE } from '../../../../others/const';
import Preview from './Template/Preview'
import "react-datepicker/dist/react-datepicker.css";
import './Confirm.scss'
import Button from '../../../parts/Button';
import DivInput from '../../../parts/DivInput';
import moment from 'moment'
import { setPopup, issueProject } from '../../../../redux';
import { queryTest, dateTest } from '../../../../others/regexr';
import ApiManager from '../../../../ApiManager';
import { getTodayDate, delay } from '../../../../others/util';
import gtag from '../../../../others/gtag';
import { addDays } from 'date-fns';
import withLanguage from '../../../../HOC/withLanguage';

class Confirm extends Component {
    state = {
        projectCode: this.props.settings.projectCode || '',
        startDate: this.props.settings.startDate || '',
        endDate: this.props.settings.endDate || '',
        dateError: this.props.settings.dateError || '',
        projectCodeError: this.props.settings.projectCodeError || '',
    }

    onIssueConfirmClick = async () => {
        const { i18n } = this.props
        const { ERROR } = i18n
        const { projectCode, startDate, endDate } = this.state

        if (!this.validateStartDate(startDate) || !this.validateEndDate(endDate) || !this.validateProjectCode(projectCode)) {
            return
        }

        const { userData, recipients, projectId } = this.props
        const { balance } = userData

        const projectCodeResult = await ApiManager.getProjectsAvailability({ projectCode, projectId })
        if (!projectCodeResult) {
            this.setState({ projectCodeError: i18n.confirm.already })
            return
        }

        if (!balance || balance < recipients.length) {
            this.props.setPopup({
                ...i18n.confirm.short,
                confirmCallback: () => {
                    this.props.history.push(ROUTE.payment)
                }
            })
            return
        }

        const callback = ({ result, error }) => {
            if (result) {
                this.props.setPopup(undefined)
                this.props.history.push(ROUTE.project)
            }
            else if (error) {
                this.props.setPopup({
                    ...i18n.confirm.retry(ERROR[error])
                })
            }
        }

        await delay(1000)

        gtag('event', 'issue_popup')
        this.props.issueProject({ projectId, callback })
    }

    validateStartDate = date => {
        const { i18n } = this.props
        const { endDate } = this.state

        if (!dateTest.test(date)) {
            this.setState({ dateError: i18n.confirm.wrongDate })
            return false
        }

        const _startDate = moment(date, dotYYYYMMDD)
        if (!_startDate.isValid()) {
            this.setState({ dateError: i18n.confirm.startForm })
            return false
        }

        if (!_startDate.isSameOrAfter(getTodayDate())) {
            this.setState({ dateError: i18n.confirm.startToday })
            return false
        }

        if (endDate && _startDate.isAfter(moment(endDate, dotYYYYMMDD))) {
            this.setState({ dateError: i18n.confirm.startEnd })
            return false
        }

        return true
    }

    validateEndDate = date => {
        const { i18n } = this.props
        const { startDate } = this.state

        if (!dateTest.test(date)) {
            this.setState({ dateError: i18n.confirm.wrongDate })
            return false
        }

        const _endDate = moment(date, dotYYYYMMDD)
        if (!_endDate.isValid()) {
            this.setState({ dateError: i18n.confirm.endForm })
            return false
        }

        if (startDate && _endDate.isBefore(moment(startDate, dotYYYYMMDD))) {
            this.setState({ dateError: i18n.confirm.endStart})
            return false
        }

        if (!_endDate.isSameOrAfter(getTodayDate())) {
            this.setState({ dateError: i18n.confirm.endToday })
            return false
        }

        return true
    }

    validateProjectCode = projectCode => {
        const { i18n } = this.props

        if (!queryTest.test(`${projectCode}`)) {
            this.setState({ projectCodeError: i18n.confirm.urlForm })
            return false
        }

        return true
    }

    onChangeCallback = (key, errorKey, data) => {
        this.setState({ [key]: data, [errorKey]: '' }, () => {
            this.props.setSettings({ [key]: data })
        })
    }

    render() {
        const { recipients, i18n } = this.props
        const { dateError, startDate, endDate, projectCode, projectCodeError } = this.state
        const disabled = !startDate || !endDate || !projectCode || dateError || projectCodeError
        const todayDate = new Date()
        const _startDate = startDate ? moment(startDate, dotYYYYMMDD).toDate() : todayDate
        return (
            <div className='issue-popup'>
                <Preview {...this.props.settings} mode={PREVIEW_MODE.popup} />
                <div className='right-content'>
                    <div className='count' ref={ref => {if (ref) ref.innerHTML = i18n.confirm.information(recipients.length)}}></div>
                    <div className='question'>{i18n.confirm.whenWhere}</div>
                    <div className='title'>{i18n.confirm.period}</div>
                    <div className={`period${dateError ? ' error' : ''}`}>
                        <DivInput
                            className='date start'
                            validator={this.validateStartDate}
                            value={startDate}
                            placeholder={moment().format(dotYYYYMMDD)}
                            onChangeCallback={date => { this.onChangeCallback('startDate', 'dateError', date) }}
                            moment={moment}
                            format={dotYYYYMMDD}
                            date={{
                                minDate: todayDate,
                                maxDate: endDate ? moment(endDate, dotYYYYMMDD).toDate() : null
                            }}
                        />
                        <span className='dash'>~</span>
                        <DivInput
                            className='date end'
                            validator={this.validateEndDate}
                            value={this.state.endDate}
                            placeholder={moment().format(dotYYYYMMDD)}
                            onChangeCallback={date => { this.onChangeCallback('endDate', 'dateError', date) }}
                            moment={moment}
                            format={dotYYYYMMDD}
                            date={{
                                minDate: _startDate,
                                maxDate: addDays(_startDate, 30)
                            }}
                        />
                    </div>
                    {dateError && <p className='period-error'>{dateError}</p>}
                    <div className='title'>
                        URL
                    </div>
                    <div className='link'>
                        <span>{BASE_URL}</span>
                        <DivInput
                            className='query'
                            validator={this.validateProjectCode}
                            value={this.state.projectCode}
                            placeholder='untitled'
                            onChangeCallback={code => { this.onChangeCallback('projectCode', 'projectCodeError', code) }}
                            input
                        />
                    </div>
                    {projectCodeError && <p className='query-error'>{projectCodeError}</p>}
                    <div className='causion'>{i18n.confirm.causion}</div>
                    <Button content={i18n.confirm.issue} 
                        style={{ width: '100%' }} 
                        onClick={this.onIssueConfirmClick} 
                        disabled={disabled} 
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData.payload
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setPopup: payload => dispatch(setPopup(payload)),
        issueProject: payload => dispatch(issueProject(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Confirm)))