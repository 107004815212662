import { put, takeLatest, call, all } from 'redux-saga/effects'
import { GET_USER_DATA, getUserData } from '../redux';
import ApiManager from '../ApiManager'

function* getUserDataAsync() {
    yield put(getUserData.request(false))

    try {
        const { result, error } = yield call(ApiManager.getUsersCurrentBasicInfo)
        if (result) {
            yield put(getUserData.success(result))
        }
        else {
            throw Error(error)
        }         
    }
    catch (e) {
        yield put(getUserData.failure(e))
    }
}

function* watchGetUserData() {
    yield takeLatest(GET_USER_DATA.INDEX, getUserDataAsync)
}

export default function* root() {
    yield all([
        watchGetUserData(),
    ])
}