import React, { Component } from 'react'
import { MdLaunch } from 'react-icons/md'
import './Page3.scss'
import { COLOR_SET, PREVIEW_MODE, PROJECT_STATE, TRACKER_URL } from '../../../../../../others/const';
import DpassConnector from '../../../../../../DpassConnector';
import dpassLogo from '../../../../../../images/dpass-logo.png'
import Certificate from './Certificate';
import Setter from './Setter';
import { delay, getImageSet } from '../../../../../../others/util';

class Page3 extends Component {
    state = {
        height: undefined,
        generated: null,
        placeholderLoaded: false
    }

    onLoad = e => {
        const { height } = e.currentTarget
        this.setState({ height })
    }

    onLinkClick = () => {
        if (this.props.mode !== PREVIEW_MODE.broof) {
            return
        }

        const { transactionHash } = this.props
        window.open(`${TRACKER_URL}transaction/${transactionHash}`, '_blank')
    }

    certificateLoaded = async generated => {
        await delay(500)
        this.setState({ generated })
    }

    onDpassClick = async () => {
        const { projectCode, name, code } = this.props
        await DpassConnector.open({
            projectCode,
            recipientName: name,
            recipientCode: code
        })
    }

    onPlaceholderLoad = () => {
        this.setState({ placeholderLoaded: true })
    }

    render() {
        const { height, generated, placeholderLoaded } = this.state
        const { recipients, i18n, mode } = this.props
        
        const { certificateImage } = getImageSet(this.props)
        const type = this.props.type || i18n.TEMPLATE_SETTINGS.type
        const backgroundColor = this.props.backgroundColor || i18n.TEMPLATE_SETTINGS.backgroundColor
        const color = backgroundColor === COLOR_SET.white ? '#000' : backgroundColor

        const printed = mode !== PREVIEW_MODE.template || (this.props.state && this.props.state !== PROJECT_STATE.PAYMENT_REQUIRED)
        const broofMode = mode === PREVIEW_MODE.broof
        const viewBottom = !broofMode || (broofMode && placeholderLoaded)

        const placeholderData = recipients && recipients[0] ? recipients && recipients[0] : {}
        const placeholderName = placeholderData.name || i18n.page1.placeholderName
        const placeholderExtraInfo = placeholderData.extraInfo || i18n.page1.placeholderExtraInfo

        return (
            <div className='project-create-preview-page three'>
                {broofMode && !generated &&
                    <Certificate {...this.props} callback={this.certificateLoaded} />
                }
                {broofMode &&
                    <div className='certificate-image placeholder' style={{ height }} >
                        <img src={certificateImage} alt='placeholder' onLoad={this.onPlaceholderLoad} />
                    </div>
                }
                {broofMode && generated &&
                    <div className='certificate-image printed' style={{ height }} >
                        <img src={generated} alt='certificate' />
                    </div>
                }
                {!broofMode &&
                    <div className='certificate-setter' style={{ height }} ref={ref => { if (ref) { this.dragContainer = ref } }}>
                        {!certificateImage && <span ref={ref => { if (ref) ref.innerHTML = i18n.page3.upload }}></span>}
                        {certificateImage && <img src={certificateImage} onLoad={this.onLoad} alt='certificate' />}
                        {certificateImage && <Setter 
                            subClass='name'
                            data={placeholderName}
                            move={i18n.page3.move}
                            printed={printed}
                            dragContainer={this.dragContainer}
                            positionX={this.props.fontPositionX}
                            positionY={this.props.fontPositionY}
                            setPosition={(fontPositionX, fontPositionY) => {
                                this.props.setFontPosition({
                                    fontPositionX,
                                    fontPositionY
                                })
                            }}
                        />}
                        {certificateImage && <Setter 
                            subClass='extra'
                            data={placeholderExtraInfo}
                            move={i18n.page3.move}
                            printed={printed}
                            dragContainer={this.dragContainer}
                            positionX={this.props.extraPositionX}
                            positionY={this.props.extraPositionY}
                            setPosition={(extraPositionX, extraPositionY) => {
                                this.props.setExtraPosition({
                                    extraPositionX,
                                    extraPositionY
                                })
                            }}
                        />}
                    </div>
                }
                {viewBottom &&
                    <p className='link' onClick={this.onLinkClick}>
                        {i18n.page3.check(type)}
                        <span style={{ color }}><MdLaunch /></span>
                    </p>
                }
                {viewBottom && <p className='description' ref={ref => { if (ref) ref.innerHTML = i18n.page3.description(type) }}></p>}
                {viewBottom &&
                    <p className='dpass' onClick={this.onDpassClick}>
                        <img src={dpassLogo} alt='dpass-logo'/> <span>Connect with DPASS</span>
                    </p>            
                }
            </div>
        )
    }
}

export default Page3