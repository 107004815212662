import { put, takeLatest, call, all } from 'redux-saga/effects'
import ApiManager from '../ApiManager'
import { GET_BROOF, getBroof } from '../redux';
import { delay, getExtraPosition } from '../others/util'
import image2base64 from 'image-to-base64';

function* getBroofAsync(action) {
    yield put(getBroof.request(true))
    yield delay(1000)

    try {        
        const { projectCode } = action.payload
        const { result, error } = yield call(ApiManager.getProjectsCodeSettings, { projectCode })

        if (result) {
            const { projectName, certificateImage } = result
            document.title = projectName

            const imageData = yield call(image2base64, certificateImage)
            const splitted = certificateImage.split('.')
            const ext = splitted[splitted.length - 1]
            result.certificateImage = `data:image/${ext};base64,${imageData}`

            const { extraPositionX, extraPositionY } = getExtraPosition(result)
            result.extraPositionX = extraPositionX
            result.extraPositionY = extraPositionY

            yield put(getBroof.success(result))
        }
        else {
            yield put(getBroof.failure(error))
        }
    }
    catch (e) {
        yield put(getBroof.failure(e))
    }
}

function* watchGetBroof() {
    yield takeLatest(GET_BROOF.INDEX, getBroofAsync)
}

export default function* root() {
    yield all([
        watchGetBroof(),
    ])
}