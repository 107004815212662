import { IconBuilder, IconAmount, IconConverter } from 'icon-sdk-js'
import { requestJsonRpc } from '../others/connect'
import { NID, IAMPORT_ERROR } from '../others/const';
import { isDevelopment, getRealPrice, devConsole } from '../others/util';

const _isDevelopment = isDevelopment()
const IMP_ID =  !_isDevelopment ? 'imp06415401' : 'imp92419553'
const TEAM_ADDRESS = 'hxbcd8047785d35bd0e04ec0b1f0421e5efa54a784'

class PayManager {
    constructor(impId) {
        window.IMP.init(impId)

        this.name = ''
        this.buyer = {}
        this.redirect = ''
    }

    setImpInformation = ({ name, buyer, redirect }) => {
        if (name) this.name = name
        if (buyer) this.buyer = buyer
        if (redirect) this.redirect = redirect
    }

    payByIcon = async (ticket, currency, address) => {
        const amount = getRealPrice(ticket, currency)
        const { IcxTransactionBuilder } = IconBuilder;
        const transaction = new IcxTransactionBuilder()
            .from(address)
            .to(TEAM_ADDRESS)
            .value(IconAmount.of(amount, IconAmount.Unit.ICX).toLoop())
            .stepLimit(IconConverter.toHex(1000000))
            .timestamp((new Date()).getTime() * 1000)
            .nid(IconConverter.toHex(NID))
            .version(IconConverter.toHex(3))
            .build();
        const rawTransaction = IconConverter.toRawTransaction(transaction);
        try {
            const { result } = await requestJsonRpc(rawTransaction)
            if (result) {
                return { result }
            }
            else {
                throw Error()
            }
        }
        catch (e) {
            return { error: 'icxFailure' }
        }
    }

    payByKrw = async (ticket, config) => {
        const { name, buyer, redirect } = config
        const amount = getRealPrice(ticket)
        const requestPay = () => {
            return new Promise((resolve, reject) => {
                window.IMP.request_pay({
                    pg: 'inicis',
                    pay_method: 'card',
                    merchant_uid: 'merchant_' + new Date().getTime(),
                    name,
                    amount,
                    buyer_email: buyer.email,
                    buyer_name: buyer.name,
                    buyer_tel: buyer.tel,
                    buyer_addr: buyer.addr,
                    buyer_postcode: buyer.postcode,
                    m_redirect_url: redirect || ''
                }, rsp => {
                    if (rsp.success) {
                        resolve(rsp)
                    }
                    else {
                        reject(rsp.error_msg)
                    }
                });
            })
        }

        try {
            const result = await requestPay()
            return { result }
        }
        catch (e) {
            devConsole(e)
            switch(e) {
                case IAMPORT_ERROR.userCancel:
                    return {}
                default:
                    return { error: 'krwFailure' }
                }
        }
    }
}

export default new PayManager(IMP_ID)
