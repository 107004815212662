import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import InputSet from '../parts/InputSet'
import Button from '../parts/Button'
import { ROUTE } from '../../others/const';
import './Change.scss'
import ApiManager from '../../ApiManager';
import Footer from '../parts/Footer'
import withLanguage from '../../HOC/withLanguage';

class Change extends Component {
    state = {
        currentPassword: '',
        currentPasswordError: '',
        newPassword: '',
        newPasswordError: '',
        confirm: '',
        confirmError: ''
    }

    handleChange = e => {
        const { name, value } = e.target
        // this.setState({ [name]: value, [`${name}Error`]: '' })
        this.setState({ 
            [name]: value, 
            currentPasswordError: '',
            newPasswordError: '',
            confirmError: '',
        })
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.onConfirmClick()
        }
    }

    onConfirmClick = async () => {
        const { currentPassword, newPassword, confirm } = this.state
        const { i18n } = this.props
        const { ERROR, change } = i18n
        const { 
            currentPasswordError,
            newPasswordError,
            confirmError,
            sameError
        } = change

        if (!currentPassword) {
            this.setState({ currentPasswordError })
            return
        }
        
        if (!newPassword) {
            this.setState({ newPasswordError })
            return
        }
        
        if (!confirm) {
            this.setState({ confirmError })
            return
        }
        
        if (newPassword !== confirm) {
            this.setState({ confirmError: sameError })
            return
        }
        
        const { result, error } = await ApiManager.postUsersCurrentPassword({ currentPassword, newPassword })

        if (result) {
            this.props.history.push(ROUTE.information)
        }
        else if (error) {
            switch (error) {
                case 'invalidPassword':
                    this.setState({ currentPasswordError: ERROR[error] })
                    break;
                case 'differentPassword':
                    this.setState({ newPasswordError: ERROR[error] })
                    break;
                default:
                    this.setState({ defaultError: ERROR[error] })
            }
        }
   }

    render() {
        const { i18n } = this.props
        return (
            <Fragment>
                <div className='no-menu-container'>
                    <InputSet 
                        name='currentPassword' 
                        title={i18n.change.currentTitle} 
                        context='change' 
                        type='password' 
                        value={this.state.currentPassword}
                        error={this.state.currentPasswordError}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                    />
                    <InputSet 
                        name='newPassword' 
                        title={i18n.change.newTitle} 
                        context='change' 
                        type='password' 
                        value={this.state.newPassword}
                        error={this.state.newPasswordError}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                    />
                    <InputSet 
                        name='confirm' 
                        title={i18n.change.confirmTitle} 
                        context='change' 
                        type='password' 
                        value={this.state.confirm}
                        error={this.state.confirmError}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                    />
                    <Button
                        onClick={this.onConfirmClick}
                        content={i18n.change.confirmText}
                        style={{ width: '100%' }}
                    />
                </div>
                <Footer />
            </Fragment>            
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Change)))