import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import Project from './containers/Project'
import ProjectEdit from './containers/ProjectEdit'
import Information from './containers/Information'
import Payment from './containers/Payment'
import Login from './containers/Login'
import Signup from './containers/Signup'
import Oops from './containers/Oops'
import Home from './containers/Home'
import Change from './containers/Change'
import Popup from './containers/Popup'
import Toast from './containers/Toast'
import Header from './parts/Header'
import { ROUTE, PROJECT_ROUTE, CUSTOM_EVENT } from '../others/const'
import ApiManager from '../ApiManager';
import { setUserData, setPopup, setLoggedIn, getUserData } from '../redux';
import Broof from './containers/Broof';
import withLanguage from '../HOC/withLanguage';
import { isProjectCode } from '../others/util';

const HomeRoute = (loggedIn, RouteComponent) => {
    if (loggedIn) {
        return RouteComponent
    }
    else {
        return <Redirect to={ROUTE.login} />
    }
}

class Router extends Component {
    async componentWillMount() {
        const _isProjectCode = isProjectCode()
        if (_isProjectCode) {
            return
        }
        
        window.addEventListener(CUSTOM_EVENT.expired, () => {
            ApiManager.unsetToken()
            this.props.setLoggedIn(false)
            if (window.location.pathname !== ROUTE.home) {
                this.props.setPopup(this.props.i18n.route.expired)    
            }
        }, false)
    }

    componentDidMount() {
        if (this.props.loggedIn) {
            this.props.getUserData()
        }
    }

    render() {
        const { loggedIn, popup } = this.props
        const _isProjectCode = isProjectCode()
        return (
            window.location.pathname === ROUTE.oops ?
                <Oops />
                :
                <div className={popup ? 'has-popup': ''}>
                    <BrowserRouter>
                        {_isProjectCode && <Broof />}
                        {!_isProjectCode && <Header />}
                        {!_isProjectCode &&
                            <Switch>
                                <Route exact path={ROUTE.home} component={Home} />
                                <Route exact path={`${ROUTE.payment}`} component={Payment}/>                               
                                {!loggedIn && <Route exact path={ROUTE.login} component={Login} />}
                                {!loggedIn && <Route exact path={ROUTE.signup} component={Signup} />}
                                {HomeRoute(loggedIn, <Route exact path={ROUTE.login} render={() => (<Redirect to={ROUTE.home} />)} />)}
                                {HomeRoute(loggedIn, <Route exact path={ROUTE.change} component={Change} />)}
                                {HomeRoute(loggedIn, <Route exact path={ROUTE.project} component={Project} />)}
                                {HomeRoute(loggedIn, <Route exact path={`${ROUTE.project}${PROJECT_ROUTE.create}`} component={Project} />)}
                                {HomeRoute(loggedIn, <Route exact path={`${ROUTE.project}${PROJECT_ROUTE.edit}/:projectId`} component={ProjectEdit} />)}
                                {HomeRoute(loggedIn, <Route exact path={`${ROUTE.information}`} component={Information} />)}
                                {/* {HomeRoute(loggedIn, <Route exact path={`${ROUTE.payment}`} component={Payment} />)} */}
                            </Switch>
                        }
                        {popup && <Popup />}
                        <Toast />
                    </BrowserRouter>                    
                </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn.payload,
        popup: state.popup.payload,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData: payload => dispatch(setUserData(payload)),
        setPopup: payload => dispatch(setPopup(payload)),
        setLoggedIn: payload => dispatch(setLoggedIn(payload)),
        getUserData: payload => dispatch(getUserData(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(Router))