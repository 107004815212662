import { MdPayment, MdAssignment, MdWeb, MdPeople } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { ROUTE, COLOR_SET } from "../const";

export default {
	route: {
		expired: {
			title: `Oooops!<br/>You've been away for so long.`,
			description: `You have been logged out while you were away. Please log in again.`,
			confirmText: '로그인',
		}
	},
	toast: {
		undo: 'Undo',
	},
	USER_GUIDE: 'https://drive.google.com/file/d/1Expz-eBz09ke0ctIoXQNjp1yCT7nL2Jj/view',
	CSV_HEADER: ['#', 'Name', 'Password', 'Extra info', 'Issue result'],
	CSV_TEMPLATE: [
		[
			'Name', 
			'Password (If you do not enter it or it is not 4 digits, it will be automatically generated.)', 
			'Extra info (optional)',
			// 'Email (optional)', 
			// 'Phone Number (optional)', 
			// 'ICX wallet address (optional)'
		], [
			'John Doe', 
			'1922', 
			'broof Developer',
			// 'hello@broof.io', 
			// '010-0000-0000', 
			// 'hx0000000000000000000000000000000000000000'
		]
	],
	ERROR: {
		alreadyUsername: 'Email address already exists',
		icxFailure: 'ICX payment failed.',
		krwFailure: 'Payment failed.',
		expiredJwt: 'Session has expired.',
		issueFailed: 'Purchase request has failed.',
		saveFailed: 'Failed to save.',
		deleteFailed: 'Failed to delete.',
		icxFailed: 'Failed to get ICON price.',
		changeFailed: 'Failed to change issue status.',
		invalidUser: 'Check your issuer information.',
		transactionFailed: 'Issue request has failed. Please try again.',
		projectExpired: 'The issuing period is over.',
		projectPaused: 'Issuance has been paused. Please contact your issuer.',
		projectStandby: 'Issuing period has not begun yet.',
		paymentRequired: "The issuer has insufficient credits. Please contact your issuer.",
		postUserDataFailed: 'Failed to modify user information.',

		paymentAlreadyUsed: 'Payment has already been processed.',
		paymentNotExist: 'Payment does not exist.',
		invalidResponse: 'Payment is invalid.',
		paymentDiffer: 'Transacted payment differs from<br />the specified pricing.',
		paymentStillProcessed: 'Payment is still being<br />processed.',
		paySuccessCountFailure: 'Purchase history update<br />failed.',

		invalidPassword: 'Please check your password.',
		noUsername: 'Please check your ID.',
		networkError: 'Please check your network connection.',
		unauthorized: 'Please finish the email verification process.',
		differentPassword: 'New password must be different from current password.',

		loadFailed: 'Failed to retrieve balance.',
	},
	TEMPLATE_SETTINGS: {
		projectName: 'Untitled',
		templateTitle: 'Enter a title.',
		templateSubTitle: 'Enter a description.',
		backgroundColor: COLOR_SET.blue,
		type: 'certificate',
		ownerAlias: 'broof',
		backgroundImage: undefined,
		logoImage: undefined,
		certificateImage: undefined,
	},	
	PROJECT_STATE_LABEL: {
		PAYMENT_REQUIRED: 'Saved',
		STANDBY: 'Ready',
		IN_PROGRESS: 'Issuing',
		PAUSED: 'Paused',
		FINISHED: 'Issued',
		EXPIRED: 'Expired'
	},
	document: {
		title: 'broof - Blockchain-based Certificate Issuance Service'
	},
	privacy: {
		title: 'Terms of use and privacy policy',
		purpose: 'IconLoop (“company”) collects and uses your personal information for the purposes outlined below.',
		purposeThead: 'Purpose',
		purposeTbody: issued => issued ? 'Viewing and issuing certificates' : 'Institute registration',
		infoThead: 'Collected information',
		infoTbody: issued => issued ? '(Required) Name, email address, birth info, information entered on certificate, etc.' : "(Required) Name of institute, email",
		periodThead: 'Stored until',
		periodTbody: issued => issued ? 'Termination of service' : 'Withdrawal',
		cautions1: '* As they are the minimum amount of personal information that are needed in order to use our service, your consent and agreement is required.',
		cautions2: issued => `* ${issued ? `The collected information may differ depending on the type of certificate. ` : ``}For more information please read the full text of our privacy policy.`
	},
	broof: {
		errorTitle: 'Oops!<br/>Wrong path.',
		errorDescription: 'Check the URL again.',
	},
	page1: {
		enterInfo: type => `Please fill out the below form in order to issue the ${type}.`,
		name: 'Name',
		placeholderName: 'John Doe',
		placeholderExtraInfo: 'Extra Info',
		code: 'Code',
		placeholderCode: '4-digit number received',
		next: 'Next',
	},
	page2: {
		issuing: type => `Issuing the ${type}<br />via blockchain.`,
		record: 'Record on blockchain',
		alias: 'Issuer',
		time: 'Time',
		contents: 'Cert.',
		from: `'s`,
	},
	page3: {
		move: 'Move',
		upload: `Upload the image, then drag the name of<br />the recipient to the desired location.`,
		check: type => `Check out the ${type} on the ICON Tracker`,
		description: type => `To save the ${type}, press and hold the image.`,
	},
	home: {
		start: 'Start Now',
		status: total => `${total} broofs have been issued`,
		service: 'Blockchain certificate service',
		description1: `We honor your time<br />and effort forever.`,
		description2: mobile => `By issuing certificates on the blockchain`,
		description3: `They are saved permanently`,
		description4: mobile => `And able to be easily issued/checked/verified<br />through mobile devices`,
		workTitle: 'How to',
		work1: 'When the recipient enters his information that matches an entry the organization has registered', 
		work2: 'The certificate is issued',
		work3: 'And saved inside a block',
		work4: 'It can be easily checked and verified',
		praise: [{
			text: "The people liked it a lot better since their certificates were issued on the blockchain.",
			agency: 'Seoul Metropolitan Government',
			project: 'Blockchain Governance Appointment Letter'
		}, {
			text: "My certificate feels special to me because I received it through the blockchain.",
			agency: 'Studypie',
			project: 'ICON SCORE Development Course'
		}],
		statusBottom: total => `A total of ${total}<br /><span>blockchain certificates have been issued.</span>`,
	},
	slider: {
		work1: `The recipient enters his information corresponding to the entry the organization has previously uploaded`,
		work2: `The certificate is issued`,
		work3: `And it is saved<br />in a block`,
		work4: `Easy issuing / checking / verifying<br />from anywhere.`,
		article1: 'Seoul Metropolitan Government issued a blockchain appointment letter.',
		article2: 'Postech held a ceremony for individuals who completed the "Blockchain MBA" course.',
		article3: 'ICON’s public blockchain<br /> used by Seoul Citizens Hall.',
		article4: 'Studypie issued a blockchain certificate for the ICON SCORE development course.',
		press1: 'Maeil business news, 2019.02.08',
		press2: 'Blockchain today, 2019.05.31',
		press3: 'Twitter',
		press4: 'ICON Tracker',
	},
	history: {
		time: 'Date',
		detail: 'Details',
		issue: 'Usage',
		count: 'Issue Count',
		purchased: 'Purchased'
	},
	information: {
		change: 'Change password',
		count: count => `Total ${count}`,
		tab: {
			usage: {
				icon: MdPayment,
				title: 'Balance',
				hash: '#usage'
			}
		}
	},
	payment: {
		resultToast: count => `${count} credits have been charged.`,
		ticketText: (count, bonus) => `${count}${bonus ?` + ${bonus}` : ``} Credits`,
		notChrome: {
			title: `Ooooops!<br/>Our service is only available in the Chrome browser.`,
			description: `Paying in ICX is only possible using Chrome browsers that have the ICONex extension installed in them.`,
			confirmText: 'OK',
		},
		notInstalled: {
			title: `Ooooops!<br/>You haven't installed ICONex yet.`,
			description: `Paying in ICX is only possible after you install ICONex.`,
			confirmText: 'OK',
		},
		notWallet: {
			title: `Ooooops!<br/>You don't have any wallets in ICONex.`,
			description: `Paying in ICX is only possible when you have one or more wallets in ICONex`,
			confirmText: 'OK',
		},
		typeError: error => ({
			title: `Ooooops!<br/>${error}`,
			description: `Please pay in KRW or try again.`,
			confirmText: 'OK',
		}),
		retry: error => ({
			title: `Ooooops!<br/>${error}`,
			description: `Try again.`,
			confirmText: 'OK',
			cancelText: 'Cancel'
		}),
		ask: error => ({
			title: `Ooooops!<br/>${error}`,
			description: `Please contact our customer service center.`,
			confirmText: 'OK',
		}),
		notAddress: address => ({
			title: `Ooooops!<br/>The registered ICON wallet hasn't been installed in ICONex.`,
			description: `Please add the address below in ICONex.<br/><b>${address}</b>`,
			confirmText: 'OK',
		}),
		notConnected: {
			title: `Ooooops!<br/>The ICX wallet<br/>has not been registered.`,
			description: `Please try again after registering your ICX wallet address.`,
			confirmText: 'Connect',
		},
		testPeriod: {
			title: `Ooooops!<br/>Payment function is<br />currently in trial.`,
			description: 'The payment will be cancelled automatically<br />tomorrow.',
			confirmText: 'OK',
		},
		testCompleted: {
			title: `Trial payment successful.`,
			description: `The payment will be cancelled automatically<br />tomorrow.`,
			confirmText: 'OK',
		},
		title: `We honor your time and effort forever.`,
		leftDescription: `Create unlimited templates<br />Free editing of logo, color, title, etc.<br />See and manage statistics of your certificates`,
		rightDescription: `Issue certificates instantly on mobile devices<br />Enhanced security for the certificate recipients<br />Save certificates on the blockchain indefinitely`,
		icxBonus: `Get extra credits by paying with ICX!`,
		type: currency => `Pay in ${currency ? 'KRW' : 'ICX'}`,
		upbit: `*According to UPbit`,
	},
	ticket: {
		krw: 'KRW',
		open: 'Special discount',
		pay: 'Purchase'
	},
	people: {
		searchPlaceholder: 'Name or password',
		templateFileName: 'Recipient list.csv',
		downloadFileName: projectName => `${projectName} list.csv`,
		count: count => `Total ${count}`,
		deduct: `*1 credit will be deducted per each recipient.`
	},
	preview: {
		previous: 'Prev',
		next: 'Next',
		page: 'Page'
	},
	data: {
		type: 'Type',
		background: 'Color',
		logo: 'Logo',
		logoRecommend: '* Recommendation : Height 20 px / PNG',
		attach: 'Upload',
		title: 'Title',
		subTitle: 'Sub title',
		certificate: 'Certificate',
		certificateRecommend: '* Recommendation : JPG, PNG',
		alert: {
			title: 'Warning against posting any personal information',
			description: 'Due to the immutable nature of the blockchain,<br/>the data will be unable to be deleted or modified.<br /><br />Please check again and make sure that<br/>there are <b>no sensitive information</b><br/>on the certificate image such as<br/><b>social security number, passport number,<br/>personal address, phone number, etc.</b>',
			confirmText: 'OK',
			cancelText: 'Cancel'
		}

	},
	confirm: {
		already: 'Already existing URL address.',
		short: {
			title: `Ooooops! You don't have enough credits.`,
			description: `You need 1 credit per each recipient. Please purchase one of our plans.`,
			confirmText: 'Purchase credits',
		},
		retry: error => ({
			title: `Ooooops!<br/>${error}`,
			description: `Please try again.`,
			confirmText: 'OK',
		}),
		wrongDate: 'Invalid date format.',
		startForm: 'Please set the start date correctly.',
		startToday: 'The start date must come after the current date.',
		startEnd: 'The start date must come before the end date.',
		endForm: 'Please set the end date correctly.',
		endStart: 'The end date cannot come before the start date.',
		endToday: 'The end date cannot be set to a date before the current date.',
		urlForm: 'Invalid URL format.',
		information: count => `Issuing certificates for<br />a total of ${count} recipients.`,
		whenWhere: 'Please enter the dates and URL.',
		period: 'Issuing period',
		causion: '*1 credit will be deducted per each recipient.',
		issue: 'Issue'
	},
	create: {
		noName: {
			title: `Ooooops!<br />Please enter the name of the certificate.`,
			description: `Certificate name is required.`,
			confirmText: 'OK'
		},
		noType: {
			title: `Ooooops!<br/>Please enter the type of the certificate.`,
			description: `Certificate type is required.`,
			confirmText: 'OK'
		},
		noLogo: {
			title: `Ooooops!<br/>Please upload the logo image.`,
			description: `Logo image is required.`,
			confirmText: 'OK'
		},
		noTitle: {
			title: `Ooooops!<br/>Please enter the title of the certificate.`,
			description: `Certificate title is required.`,
			confirmText: 'OK'
		},
		noSubTitle: {
			title: `Ooooops!<br/>Please enter the sub title.`,
			description: `Sub title is required.`,
			confirmText: 'OK'
		},
		noCertificate: {
			title: `Ooooops!<br />Please upload the certificate image.`,
			// description: `It was issued without a certificate image. Please upload a certificate image at ‘Template’.`,
			description: `Certificate image is required.`,
			confirmText: 'OK'
		},
		noRecipient: {
			title: `Ooooops! There are no recipients for the certificate.`,
			description: `There must be at least one recipient.`,
			confirmText: 'OK'
		},
		change: 'Change the name',
		toList: 'Go back',
		issue: 'Issue',
		tab: {
			template: {
				icon: MdWeb,
				title: 'Certificate',
				hash: '#template'
			},
			people: {
				icon: MdPeople,
				title: 'Recipients',
				hash: '#list'
			}
		},
		saveImageFail: 'Failed to save image.\nPlease try again.',
		addRecipients: count => `${count} recipient${count > 1 ? 's' : ''} has been added.`,
		addRecipientsFail: 'Failed to add recipients.\nPlease try again.',
		deleteRecipients: count => `${count} recipient${count > 1 ? 's' : ''} has been deleted.`,
		deleteRecipientsFail: 'Failed to delete recipients.\nPlease try again.',
	},
	card: {
		copied: '(Copied)',
		retry: error => ({
			title: `Ooooops!<br/>${error}`,
			description: `Please try again.`,
			confirmText: 'OK'
		}),
		pause: 'Stop',
		template: 'Edit template',
		recipient: 'Edit list',
		copy: 'Copy',
		delete: 'Delete',
		resume: 'Resume',
		finish: 'Complete setting it up',
		// finish: 'Complete issuing the certificate',
		start: day => `Issuing after ${day}.`,
		new: 'Create certificate',
		copySuccess: 'The project has been copied.',
        copyFail: 'Copy project failed.',
        deleteProject: () => ({
            title: 'Are you sure?',
            description: 'You might not be able to see this data<br />in your lifetime ever again.<br />Please consider it carefully.',
            confirmText: 'OK',
            cancelText: 'Nooooooo!'
        }),
        deleteSuccess: 'The project has been deleted.',
        deleteFail: 'Delete project failed.'
	},
	list: {
		keep: 'Keep your time and effort forever.'
	},
	change: {
		currentPasswordError: 'Please enter the current password.',
		newPasswordError: 'Please enter a new password.',
		confirmError: 'Please enter a new password again.',
		sameError: 'The password does not match. Please reenter password.',
		currentTitle: 'Current',
		newTitle: 'New',
		confirmTitle: 'Confirm',
		confirmText: 'Reset'
	},
	login: {
		successToast: 'You are logged in.',
		precious: email => ({
			title: 'We keep your privacy.',
			description: `Please send <b>your name</b> to <b>${email}</b>.<br/>We'll reset your password and let you know.`,
			confirmText: 'Send',
		}),
		congratulation: () => ({
			title: 'Congratulations!<br/>You are a broof member!',
			description: `Log in and issue your first<br />blockchain certificate.`,
			confirmText: 'Log in',
		}),
		expiredToken: () => ({
			title: 'Ooooops!<br/>You are one step late.',
			description: `Email verification has passed.<br />Resend verification email.`,
			confirmText: 'Resend email',
		}),
		usernameError: 'Please enter the email address you registered with.',
		passwordError: 'Please enter the correct password.',
		idTitle: 'ID',
		passwordTitle: 'Password',
		confirmText: 'OK',
		signup: 'Sign up',
		find: 'Find password'
	},
	signup: {
		emailError: 'Please enter an email address.',
		emailForm: 'Please enter a valid email address.',
		passwordError: 'Please enter a password.',
		passwordConfirm: 'Please enter the password again.',
		passwordForm: 'Please include at least one special character, one lowercase character, and a number.',
		passwordDifferent: 'Please check the password again.',
		nameError: 'Name of organization is required.',
		_congratulation: (alias, username) => ({
			title: 'Congratulations!<br/>Shall we issue a certificate now?',
			description: `Institute: ${alias}<br/>E-mail: ${username}`,
			confirmText: 'Start issuing certificates',
		}),
		checkEmail: username => ({
			title: 'We have sent a Verification<br />email to your email address.',
			description: `Click the verification button to complete.<br /><b>${username}</b>`,
			confirmText: 'Check email',
		}),
		title: `You decided to issue a blockchain certificate!`,
		emailTitle: 'Email',
		passwordTitle: 'Password',
		passwordConfirmTitle: 'Confirm',
		nameTitle: 'Organization',
		wallet: 'ICON wallet',
		option: 'Optional',
		iconex: 'Connect ICONex',
		confirmText: 'OK',
		login: 'Login'
	},
	footer: {
		keep: `We honor your time and effort forever.`,
		information: `ICONLOOP Inc. / CEO JH Kim\n3F, 100, Cheonggyecheon-ro, Jung-gu, Seoul, Republic of Korea`,
		plan: 'Plans',
		guide: 'Guide',
		notice: 'Notice & News',
		terms: 'Terms',
		policy: 'Privacy Policy',
		disclaimer: 'Disclaimer',
	},
	header: {
		start: 'Start now',
		pcOnly: 'Ooooops!\nPlease use a PC instead of a mobile device.',
		login: 'Log in',
		logout: 'Log out',
		signup: 'Sign up',
		issue: 'Issue',
		plans: 'Plans',
	},
	lefter: {
		hello: 'Hello,',
		user: name => `${name}`,
		new: 'Create certificate',
		mine: 'Balance',
		buy: 'Plans',
		balance: balance => `<span>${balance}</span><span></span>`,
		menu: {
			project: {
				icon: MdAssignment,
				name: 'Certificates',
				route: ROUTE.project
			},
			information: {
				icon: FaUserCircle,
				name: 'My Page',
				route: ROUTE.information
			}
		},
		logout: 'Log out'
	},
	table: {
		name: 'Name',
		password: 'Password',
		extra: 'Extra info',
		status: 'Issuance status'
	}
}