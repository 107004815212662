import React from 'react'
import './Oops.scss'
import oppsPerson from '../../images/oops-person.png'

const Oops = () => (
    <div className='oops-container'>
        <div>
            <img src={oppsPerson} alt='person' />
            <div>
                <div>
                    <p>Ooooops!</p>
                    <p>Service unavailable.</p>
                    <p>Access to this resource on the server is denied.</p>
                </div>
            </div>
        </div>
    </div>
)

export default Oops