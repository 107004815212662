import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { MdClose } from 'react-icons/md'
import { setPopup } from '../../../redux'
import { execute, setClassName, setRef } from '../../../others/util';
import './index.scss'
import Button, { ButtonType } from '../../parts/Button';

const Popup = ({ 
    setPopup, 
    title, 
    description, 
    SubComponent, 
    confirmText, 
    confirmCallback,
    cancelText, 
    cancelCallback
}) => {
    
    const close = _callback => {
        setPopup(undefined)
        execute(_callback)
    }

    const margin = 10
    const halfWidth = `calc(50% - ${margin}px / 2)`

    return (
        <div className='containers-popup'>
            <div className={setClassName(['contents', !SubComponent && 'default'])}>
                <div className='close' onClick={() => { close(cancelCallback) }}>
                    <MdClose />
                </div>
                {SubComponent}
                {!SubComponent && <div>
                    <div className='title' ref={setRef(title)}></div>
                    <div className='description' ref={setRef(description)}></div>
                    {cancelText && <Button content={cancelText} 
                        type={ButtonType.LINE} 
                        style={{ width: halfWidth, marginRight: margin }}
                        onClick={() => { close(cancelCallback) }}
                    />}
                    <Button content={confirmText} 
                        style={{ width: cancelText ? halfWidth : '100%' }}
                        onClick={() => { close(confirmCallback) }}
                    />
                </div>} 
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { ...state.popup.payload }
}

function mapDispatchToProps(dispatch) {
    return {
        setPopup: (payload) => dispatch(setPopup(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Popup))
