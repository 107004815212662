import React, { Component } from 'react'
import Draggable from 'react-draggable'
import './Setter.scss'

class Setter extends Component {
    state = {
        position: {
            top: this.props.positionY || 0,
            left: this.props.positionX || 0,
        },
        deltaPosition: {
            x: 0,
            y: 0
        },
    }

    handleDrag = (e, ui) => {
        const { deltaPosition } = this.state
        const { x, y } = deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        })
    }

    handleStop = () => {
        const { setPosition, dragContainer } = this.props
        if (typeof setPosition === 'function' && !!dragContainer) {
            const { position, deltaPosition } = this.state
            const { offsetWidth, offsetHeight } = dragContainer
            const { x, y } = deltaPosition;
            const { left, top } = position;
            const positionX = ((x + left * offsetWidth / 100) / offsetWidth) * 100;
            const positionY = ((y + top * offsetHeight / 100) / offsetHeight) * 100;
            setPosition(positionX, positionY)
        }
    }

    render() {
        const { printed, move, subClass, data } = this.props
        const { position } = this.state
        const { top, left } = position

        return (
            <Draggable
                bounds="parent"
                handle=".handle"
                onDrag={this.handleDrag}
                onStop={this.handleStop}
            >
                <div
                    className={`setter ${subClass}${!printed ? ` drag` : ` not-drag`}`}
                    style={{
                        top: `${top}%`,
                        left: `${left}%`
                    }}
                >
                    <p>{data}</p>
                    <p className='handle'>{move}</p>
                </div>
            </Draggable>
        )
    }
}

export default Setter