import React, { Component } from 'react'
import { MdCheck } from 'react-icons/md'
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go'
import './Privacy.scss'

class Privacy extends Component {
    
    state = {
        privacy: false,
        privacyToggle: false
    }

    onClickPrivacy = () => {
        this.setState({ privacy: !this.state.privacy }, () => {
            this.props.onClickCheck()
        })
    }

    onClickPrivacyToggle = () => {
        this.setState({ privacyToggle: !this.state.privacyToggle }, () => {
            this.props.onClickToggle && this.props.onClickToggle()
        })
    }
    
    render() {
        const { i18n, color, className } = this.props;
        const issued = className === 'issued'
        
        return (
            <span className={`privacy ${className ? className : ''}`}>
                <div
                    className={`check ${this.state.privacy ? 'active' : ''}`}
                    style={{ backgroundColor: this.state.privacy ? color : '#ffffff00' }}
                    onClick={this.onClickPrivacy}
                >
                    <MdCheck style={{ fill: this.state.privacy ? '#fff' : '#999' }} />
                </div>
                <p className='title' 
                    onClick={this.onClickPrivacyToggle}
                >
                    {i18n.privacy.title} {this.state.privacyToggle ? <GoTriangleDown/> : <GoTriangleUp/>}
                </p>
                <div className={`privacy-toggle ${this.state.privacyToggle ? "active" : ""}`}>
                    <p className='top-txt' onClick={this.onClickPrivacyToggle}>{i18n.privacy.purpose}</p>
                    <table>
                        <thead>
                            <tr>
                                <th>{i18n.privacy.purposeThead}</th>
                                <th>{i18n.privacy.infoThead}</th>
                                <th>{i18n.privacy.periodThead}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{i18n.privacy.purposeTbody(issued)}</th>
                                <th>{i18n.privacy.infoTbody(issued)}</th>
                                <th>{i18n.privacy.periodTbody(issued)}</th>
                            </tr>
                        </tbody>
                    </table>
                    <p className='bottom-txt'>{i18n.privacy.cautions1}</p>
                    <p className='bottom-txt mb'>{i18n.privacy.cautions2(issued)}</p>
                </div>
            </span>
        )
    }
}

export default Privacy