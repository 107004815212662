import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ROUTE, BASE_URL, PROJECT_STATE, PROJECT_ROUTE } from '../../../../others/const';
import './index.scss'
import Button, { ButtonType } from '../../../parts/Button';
import DivInput from '../../../parts/DivInput';
import { setPopup, getProjectList, addToast } from '../../../../redux';
import People from './People';
import Template from './Template';
import ApiManager from '../../../../ApiManager';
import Confirm from './Confirm'
import { MdContentCopy } from 'react-icons/md';
import copy from 'copy-to-clipboard';
import uuidv1 from 'uuid/v1'
import { delay, getExtraPosition, getExtraInfo, setExtra } from '../../../../others/util';
import withLanguage from '../../../../HOC/withLanguage';
import gtag from '../../../../others/gtag';

class ProjectCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projectId: '',
            recipients: [],
            settings: {},
            loading: !this.props.location.pathname.includes(PROJECT_ROUTE.create)
        }

        this.timeouts = {}
    }

    async componentWillMount() {
        const { i18n } = this.props
        let { projectId } = this.props.match.params
        let settings = {}, recipients = []
        const loading = !projectId
        if (projectId) {
            const { result: _settings } = await ApiManager.getProjectsSettings({ projectId })
            const { result: _recipients } = await ApiManager.getProjectsRecipients({ projectId })
            // TODO
            // ApiManager 로 옮겨야 할 듯
            const { extraPositionX, extraPositionY } = getExtraPosition(_settings)            
            settings = {
                ..._settings,
                extraPositionX,
                extraPositionY
            }
            recipients = [..._recipients.map(recipient => {
                recipient.extraInfo = getExtraInfo({ ...recipient })
                return recipient
            })]
        }
        else {
            projectId = uuidv1()
            this.saveProject(projectId)
        }

        settings.projectName = settings.projectName || i18n.TEMPLATE_SETTINGS.projectName

        this.setState({
            projectId,
            settings,
            recipients,
            loading
        })
    }

    saveProject = async projectId => {        
        const { i18n } = this.props
        await ApiManager.postProjectsSettings({ projectId, body: {} })
        const url = `${ROUTE.project}${PROJECT_ROUTE.edit}/${projectId}${i18n.create.tab.template.hash}`
        this.props.history.push(url)
    }

    changeTab = hash => {
        const { pathname } = this.props.location
        this.props.history.push(`${pathname}${hash}`)
    }

    setRecipents = (payload, type) => {
        const { i18n } = this.props
        const { projectId } = this.state

        switch (type) {
            case 'csv': {
                this.setState({ recipients: payload }, async () => {
                    const updated = []
                    payload.forEach(recipient => {
                        const _recipient = setExtra(recipient)
                        updated.push(_recipient)
                    })

                    await ApiManager.postProjectsRecipientsDeletionAll({
                        projectId
                    })
                    await delay(500)
                    const { result, error } = await ApiManager.postProjectsRecipients({
                        projectId,
                        body: updated
                    })
                    if (result) {
                        const text = i18n.create.addRecipients(updated.length)
                        this.props.addToast({ text })
                    }
                    else if (error) {
                        const text = i18n.create.addRecipientsFail
                        this.props.addToast({ text, error })
                    }
                })
                break
            }
            case 'delete':
            case 'delete-checked': {
                const { recipients } = this.state
                const remained = []
                recipients.forEach(recipient => {
                    const { broofId } = recipient
                    if (!payload.includes(broofId)) {
                        remained.push(recipient)
                    }
                })
                this.setState({ recipients: remained }, async () => {
                    const deleted = (payload || []).map(broofId => ({ broofId }))
                    if (deleted.length > 0) {
                        const { result, error } = await ApiManager.postProjectsRecipientsDeletionGroup({
                            projectId,
                            body: deleted
                        })

                        if (result) {
                            const text = i18n.create.deleteRecipients(deleted.length)
                            this.props.addToast({ text })
                        }
                        else if (error) {
                            const text = i18n.create.deleteRecipientsFail
                            this.props.addToast({ text, error })
                        }
                    }
                })
                break
            }
            case 'update': {
                const recipients = [...this.state.recipients]
                const updated = []
                payload.forEach(recipient => {
                    const { temporary, index } = recipient
                    if (!!temporary) {
                        delete recipient.temporary
                        recipients.push({ ...recipient })
                    }
                    else if (index !== undefined) {
                        delete recipient.index
                        recipients[index] = { ...recipient }
                    }
                    const _recipient = setExtra(recipient)
                    updated.push(_recipient)
                })

                this.setState({ recipients }, async () => {
                    if (updated.length > 0) {
                        await ApiManager.postProjectsRecipients({
                            projectId,
                            body: updated
                        })
                    }
                })
                break
            }
            default:
        }
    }

    setSettings = payload => {
        const { projectId } = this.state
        const settings = {
            ...this.state.settings,
            ...payload
        }
        const { _font, _extra } = settings
        if (_font) {
            settings.fontPositionX = _font.fontPositionX
            settings.fontPositionY = _font.fontPositionY
            delete settings._font
        }
        if (_extra) {
            settings.extraPositionX = _extra.extraPositionX
            settings.extraPositionY = _extra.extraPositionY
            delete settings._extra
        }

        this.setState({ settings }, () => {
            Object.keys(payload).forEach(key => {
                clearTimeout(this.timeouts[key])

                if (!payload[key]) {
                    return
                }

                this.timeouts[key] = setTimeout(async () => {
                    switch (key) {
                        case 'projectName':
                        case 'type':
                        case 'backgroundColor':
                        case 'templateTitle':
                        case 'templateSubTitle':
                        case 'startDate':
                        case 'endDate':
                        case 'projectCode': {
                            await ApiManager.postProjectsSettings({
                                projectId,
                                body: { [key]: payload[key] }
                            })
                            break
                        }
                        case '_font': {
                            await ApiManager.postProjectsSettings({
                                projectId,
                                body: {
                                    fontPositionX: payload._font.fontPositionX,
                                    fontPositionY: payload._font.fontPositionY,
                                }
                            })
                            break
                        }
                        case '_extra': {
                            await ApiManager.postProjectsSettings({
                                projectId,
                                body: {
                                    extra: [{
                                        idx: 0,
                                        fontPositionX: payload._extra.extraPositionX,
                                        fontPositionY: payload._extra.extraPositionY,
                                        fontSize: 14 // not used
                                    }]
                                }
                            })
                            break
                        }
                        case 'backgroundImage':
                        case 'logoImage':
                        case 'certificateImage': {
                            const type = key === 'logoImage' ? 'logo'
                                : key === 'certificateImage' ? 'cert'
                                : 'bg'
                            const { error } = await ApiManager.postProjectsImage({
                                projectId,
                                files: payload[key],
                                type
                            })
                            if (error) {
                                this.props.addToast({ text: '이미지 저장에 실패하였습니다.\n다시 업로드 해주세요.', error })
                            }
                            break
                        }
                        default:
                    }
                }, 1000)
            })
        })
    }

    onUrlClick = () => {
        window.open(`${BASE_URL}${this.state.settings.projectCode}`, '_blank')
    }

    beforeConfirm = () => {
        const { i18n } = this.props

        const {
            settings,
            recipients
        } = this.state

        const {
            projectId,
            projectName,
            type,
            logoImage,
            templateTitle,
            templateSubTitle,
            certificateImage
        } = settings

        if (!projectName || projectName === i18n.TEMPLATE_SETTINGS.projectName) {
            this.props.setPopup({
                ...i18n.create.noName
            })
            return false
        }

        if (!type) {
            this.props.setPopup({
                ...i18n.create.noType
            })
            return false
        }

        if (!logoImage) {
            this.props.setPopup({
                ...i18n.create.noLogo,
                confirmCallback: () => {
                    let url = ''
                    if (projectId) {
                        url = `${ROUTE.project}${PROJECT_ROUTE.edit}/${projectId}${i18n.create.tab.template.hash}`
                    }
                    else {
                        url = `${ROUTE.project}${PROJECT_ROUTE.create}/${i18n.create.tab.template.hash}`
                    }
                    this.props.history.push(url)
                }

            })
            return false
        }

        if (!templateTitle) {
            this.props.setPopup({
                ...i18n.create.noTitle
            })
            return false
        }

        if (!templateSubTitle) {
            this.props.setPopup({
                ...i18n.create.noSubTitle
            })
            return false
        }

        if (!certificateImage) {
            this.props.setPopup({
                ...i18n.create.noCertificate,
                confirmCallback: () => {
                    let url = ''
                    if (projectId) {
                        url = `${ROUTE.project}${PROJECT_ROUTE.edit}/${projectId}${i18n.create.tab.template.hash}`
                    }
                    else {
                        url = `${ROUTE.project}${PROJECT_ROUTE.create}/${i18n.create.tab.template.hash}`
                    }
                    this.props.history.push(url)
                }
            })
            return false
        }

        if (recipients.length === 0) {
            this.props.setPopup({
                ...i18n.create.noRecipient,
                confirmCallback: () => {
                    let url = ''
                    if (projectId) {
                        url = `${ROUTE.project}${PROJECT_ROUTE.edit}/${projectId}${i18n.create.tab.people.hash}`
                    }
                    else {
                        url = `${ROUTE.project}${PROJECT_ROUTE.create}/${i18n.create.tab.people.hash}`
                    }
                    this.props.history.push(url)
                }
            })
            return false
        }

        return true
    }

    handleClick = async e => {
        switch (e.target.name) {
            case 'project-confirm':
                const _beforeConfirm = this.beforeConfirm()
                if (!_beforeConfirm) {
                    return
                }

                gtag('event', 'issue')
                this.props.setPopup({
                    SubComponent: <Confirm
                        {...this.state}
                        setSettings={this.setSettings}
                    />
                })
                return

            case 'project-cancel':
                this.props.history.push(`${ROUTE.project}`)
                return

            default:
        }
    }

    render() {
        const { i18n } = this.props
        
        const {
            settings,
            loading
        } = this.state

        const {
            projectName,
            projectCode,
            startDate,
            endDate,
            state
        } = settings

        const { hash } = this.props.location
        const tabHash = hash ? hash : i18n.create.tab.template.hash
        const readyToIssue = !state || (state && state === PROJECT_STATE.PAYMENT_REQUIRED)
        const beforeFinished = !state || (state && state !== PROJECT_STATE.FINISHED)

        return (
            <div className='project-create'>
                <div className='contents'>
                    <div className='top'>
                        <DivInput
                            className={`name${loading ? ' loading' : ''}`}
                            title={i18n.create.change}
                            value={projectName}
                            setValue={projectName => { this.setSettings({ projectName }) }}
                            placeholder={projectName}
                            divFocus
                        />
                        {state && state !== PROJECT_STATE.PAYMENT_REQUIRED &&
                            <span className='right'>
                                <span onClick={this.onUrlClick}>
                                    <span className='url'>{BASE_URL}</span>
                                    <DivInput
                                        className='query'
                                        value={projectCode}
                                        div
                                    />
                                </span>
                                <span
                                    className='query-copy'
                                    onClick={() => {
                                        copy(`${BASE_URL}${projectCode}`)
                                    }}
                                >
                                    <MdContentCopy />
                                </span>
                                <DivInput
                                    className='date start'
                                    value={startDate}
                                    div
                                />
                                <span className='dash'>~</span>
                                <DivInput
                                    className='date end'
                                    value={endDate}
                                    div
                                />
                            </span>
                        }
                    </div>
                    <div className='tab'>
                        {Object.keys(i18n.create.tab).map((key, index) => {
                            const tab = i18n.create.tab[key]
                            const { icon, hash, title } = tab
                            const TabIcon = icon
                            const className = tabHash === hash ? 'selected' : ''
                            return (
                                <div key={index}
                                    className={className}
                                    onClick={() => { this.changeTab(hash) }}
                                >
                                    <TabIcon />{title}
                                </div>
                            )
                        })}
                    </div>
                    {tabHash === i18n.create.tab.template.hash &&
                        <Template
                            settings={this.state.settings}
                            setSettings={this.setSettings}
                            recipients={this.state.recipients}
                            loading={loading}
                            disabled={!readyToIssue}
                        />
                    }
                    {tabHash === i18n.create.tab.people.hash &&
                        <People
                            projectName={projectName}
                            recipients={this.state.recipients}
                            setRecipents={this.setRecipents}
                            loading={loading}
                            disabled={!beforeFinished}
                        />
                    }
                    <div className={`buttons${loading ? ' loading' : ''}`}>
                        <Button name='project-cancel' 
                            content={i18n.create.toList} 
                            type={ButtonType.TEXT}
                            onClick={this.handleClick} 
                        />
                        {readyToIssue && <Button name='project-confirm' 
                            content={i18n.create.issue} 
                            onClick={this.handleClick} 
                        />}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData.payload,
        projectList: state.projectList.payload.list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setPopup: payload => dispatch(setPopup(payload)),
        getProjectList: payload => dispatch(getProjectList(payload)),
        addToast: payload => dispatch(addToast(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(ProjectCreate)))