import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ProjectCard from './Card'
import CoffeeCup from '../../parts/CoffeeCup'
import { getProjectList } from '../../../redux';
import withLanguage from '../../../HOC/withLanguage';
import './List.scss'

// TODO 여기 정리 필요
class ProjectList extends Component {
    componentWillMount() {
        if (!this.props.parentLoading) {
            this.props.getProjectList()
        }
    }

    render() {
        const { i18n } = this.props
        const keys = Object.keys(this.props.list)
        if (this.props.parentLoading || this.props.loading) {
            return <CoffeeCup />
        }
        else if (keys.length === 0) {
            return (
                <div className='project-list empty'>
                    <h1>{i18n.list.keep}</h1>
                    <ProjectCard />
                </div>
            )
        }
        else {
            return (
                <div className='project-list'>
                    {keys.map(key => <ProjectCard key={key} data={this.props.list[key]} />)}
                    <ProjectCard />
                </div>
            )
        }
    }    
}

function mapStateToProps(state) {
    return { 
        loading: state.projectList.loading,
        list: state.projectList.payload.list 
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProjectList: payload => dispatch(getProjectList(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(ProjectList)))