import React, { Component } from 'react'
import { MdSearch, MdDelete, MdPlaylistAdd  } from 'react-icons/md'
import { FaFileUpload, FaFileDownload } from 'react-icons/fa'
import ReactFileReader from 'react-file-reader';
import Table from '../../../../parts/Table';
import { CSVLink } from "react-csv";
import { parseCsv, handleRecipients, makeCsvData } from '../../../../../others/util';
import './index.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import listDown from '../../../../../images/list_download.svg'
import CoffeeCup from '../../../../parts/CoffeeCup';
import uuidv1 from 'uuid/v1'
import withLanguage from '../../../../../HOC/withLanguage'

class People extends Component {
    state = {
        search: '',
        added: undefined,
        checkedList: [],
    }

    handleChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    toggleChecked = broofId => {
        const checkedList = [...this.state.checkedList]
        const checkedIndex = checkedList.indexOf(broofId)
        if (checkedIndex !== -1) {
            checkedList.splice(checkedIndex, 1)
        }
        else {
            checkedList.push(broofId)
        }

        this.setState({ checkedList })
    }

    toggleAllChecked = byForce => {
        if (byForce) {
            this.setState({ checkedList: [] })
            return
        }

        if (this.state.checkedList.length === 0) {
            const allCheckedList = []
            this.props.recipients.forEach(item => {
                if (!item.transactionHash) {
                    allCheckedList.push(item.broofId)
                }
            })
            this.setState({ checkedList: allCheckedList })
        }
        else {
            this.setState({ checkedList: [] })
        }
    }

    // TODO
    // addTemporary, removeTemporary 통합 가능 ?
    addTemporary = () => {
        if (!this.state.added) {
            this.setState({
                added: {
                    broofId: uuidv1(),
                    temporary: true
                }
            })
        }
    }

    removeTemporary = () => {
        this.setState({ added: undefined })
    }

    loadCsvFiles = files => {
        this.setState({ added: false }, async () => {
            const data = await parseCsv(files[0])
            const recipients = handleRecipients(data)
            this.props.setRecipents(recipients, 'csv')
        })
    }

    deleteChecked = () => {
        this.props.setRecipents(this.state.checkedList, 'delete-checked')
        this.setState({ checkedList: [] })
    }

    deleteRecipients = broofIds => {
        this.props.setRecipents(broofIds, 'delete')
    }

    updateRecipients = recipients => {
        this.props.setRecipents(recipients, 'update')        
    }

    render() {
        const { recipients, disabled, loading, projectName, i18n } = this.props
        const { checkedList, search, added } = this.state
        const filtered = recipients.filter(item => item.name.indexOf(search) !== -1 || item.code.indexOf(search) !== -1)
        const list = added ? [...filtered, added] : filtered
        const csvData = makeCsvData(i18n.CSV_HEADER, recipients)

        return (
            loading ? 
            <div className='project-create-people loading'>
                <CoffeeCup />
            </div>
            :
            <div className='project-create-people'>
                <div className='top'>
                    <div className='search'>
                        <MdSearch />
                        <input
                            name='search'
                            placeholder={i18n.people.searchPlaceholder}
                            onChange={this.handleChange}
                            value={this.state.search}
                        />
                    </div>
                    <div className='buttons'>
                        <CSVLink data={i18n.CSV_TEMPLATE} filename={i18n.people.templateFileName}>
                            <FaFileDownload />
                        </CSVLink>
                        <span className={(added || disabled) ? 'disabled' : ''}>
                            <ReactFileReader
                                handleFiles={this.loadCsvFiles}
                                multipleFiles={false}
                                fileTypes={[".csv"]}
                            >
                            <FaFileUpload/>
                            </ReactFileReader>
                        </span>
                        <div className='division'></div>
                        <div
                            onClick={this.addTemporary}
                            className={(added || disabled) ? 'disabled background' : 'background'}
                        >
                            <MdPlaylistAdd className='background'/>
                        </div>
                        <span className={recipients.length === 0 ? 'disabled' : ''}>
                            <CSVLink
                                data={csvData}
                                filename={i18n.people.downloadFileName(projectName)}
                            >
                                <img src={listDown} className="list-svg" alt="list-download-icon" />
                            </CSVLink>
                        </span>
                        <div className='division'></div>
                        <div
                            className={(checkedList.length === 0) ? 'disabled' : ''}
                            onClick={this.deleteChecked}
                        >
                            <MdDelete />
                        </div>
                    </div>
                </div>
                <Table
                    className={`table-box${added ? ` added` : ``}`}
                    toggleChecked={this.toggleChecked}
                    toggleAllChecked={this.toggleAllChecked}
                    checkedList={checkedList}
                    recipients={recipients}
                    list={list}
                    updateRecipients={this.updateRecipients}
                    deleteRecipients={this.deleteRecipients}
                    removeTemporary={this.removeTemporary}
                />
                <div className='bottom'>
                    <span className='count'>{i18n.people.count(recipients.length)}</span>
                    <span className='causion'>{i18n.people.deduct}</span>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(People)))