import React, { Component } from 'react'
import { MdNavigateBefore, MdNavigateNext, MdFiberManualRecord } from 'react-icons/md'
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import './index.scss'
import { PREVIEW_MODE } from '../../../../../../others/const';
import withLanguage from '../../../../../../HOC/withLanguage'
import i18nSet from '../../../../../../others/i18n'
import { getLanguage } from '../../../../../../others/util';

class Preview extends Component {
    onMoveClick = next => {
        const { page } = this.props
        if (next && page < 3) {
            this.props.setPreviewPage(page + 1)
        }
        else if (!next && page > 1) {
            this.props.setPreviewPage(page - 1)
        }
    }

    render() {
        const { mode, page } = this.props;
        const language = getLanguage()
        const i18n = mode === PREVIEW_MODE.broof ? i18nSet[language] : this.props.i18n
            
        switch(mode) {
            case PREVIEW_MODE.broof: {
                return (
                    <div className='project-create-preview single'>
                        {page === 1 && <Page1 {...this.props} i18n={i18n} />}
                        {page === 2 && <Page2 {...this.props} i18n={i18n} />}
                        {page === 3 && <Page3 {...this.props} i18n={i18n} />}                
                    </div>    
                )                
            }

            case PREVIEW_MODE.popup: {
                return (
                    <div className='project-create-preview single'>
                        <Page1 {...this.props} i18n={i18n} />
                    </div>    
                )
            }

            case PREVIEW_MODE.template:
            default: {
                return (
                    <div className='project-create-preview'>
                        <div className={`move${page === 1 ? ' disabled' : ''}`}>
                            <div 
                                onClick={() => { this.onMoveClick(false) }}
                                title={i18n.preview.previous}
                            >
                                <MdNavigateBefore />
                            </div>
                        </div>
                        {page === 1 && <Page1 {...this.props} mode={mode} i18n={i18n} />}
                        {page === 1 && <div className='dot'><div onClick={() => { this.props.setPreviewPage(2) }} title={`2 ${i18n.preview.page}`}><MdFiberManualRecord /></div></div>}
                        {page === 1 && <div className='dot'><div onClick={() => { this.props.setPreviewPage(3) }} title={`3 ${i18n.preview.page}`}><MdFiberManualRecord /></div></div>}
                        {page === 2 && <div className='dot'><div onClick={() => { this.props.setPreviewPage(1) }} title={`1 ${i18n.preview.page}`}><MdFiberManualRecord /></div></div>}
                        {page === 2 && <Page2 {...this.props} mode={mode} i18n={i18n} />}
                        {page === 2 && <div className='dot'><div onClick={() => { this.props.setPreviewPage(3) }} title={`3 ${i18n.preview.page}`}><MdFiberManualRecord /></div></div>}
                        {page === 3 && <div className='dot'><div onClick={() => { this.props.setPreviewPage(1) }} title={`1 ${i18n.preview.page}`}><MdFiberManualRecord /></div></div>}
                        {page === 3 && <div className='dot'><div onClick={() => { this.props.setPreviewPage(2) }} title={`2 ${i18n.preview.page}`}><MdFiberManualRecord /></div></div>}
                        {page === 3 && <Page3 {...this.props} mode={mode} i18n={i18n} />}
                        <div className={`move${page === 3 ? ' disabled' : ''}`}>
                            <div
                                onClick={() => { this.onMoveClick(true) }}
                                title={i18n.preview.next}
                            >
                                <MdNavigateNext />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default withLanguage(Preview)