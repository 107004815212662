import React, { Component, Fragment } from 'react'
import './index.scss'
import Button, { ButtonType } from '../../parts/Button';
import flowDescription from '../../../images/flow_description.svg'
import partnersList from '../../../images/partners_list.png'
import { GoQuote } from 'react-icons/go'
import { MdKeyboardArrowUp } from 'react-icons/md'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { ROUTE, EMAIL } from '../../../others/const';
import ApiManager from '../../../ApiManager';
import Footer from '../../parts/Footer'
import { setLoggedIn } from '../../../redux';
import { scrollToTop } from '../../../others/util';
import Slider from './Slider'
import partnersListMobile from '../../../images/partners_list_mobile.png'
import homeTopText from '../../../images/home-top-text.png'
import withLanguage from '../../../HOC/withLanguage';
import gtag from '../../../others/gtag';

class Home extends Component {
    state = {
        latestBroofs: [],
        totalBroofsIssued: 0,
        index: 0
    }

    interval = 0

    // TODO
    // saga 로 이동하면 필요 없음
    mounted = false
    set = data => {
        if (this.mounted) {
            this.setState(data)
        }
    }

    async componentWillMount() {
        // TODO
        // saga 로 이동
        this.mounted = true
        const { latestBroofs, totalBroofsIssued } = await ApiManager.getBroofsStatsCurrent()
        this.set({ latestBroofs, totalBroofsIssued })
        this.interval = setInterval(() => {
            let next = this.state.index + 1
            this.set({ index: next + 6 >= latestBroofs.length ? 0 : next })
        }, 6000)
    }

    componentWillUnmount() {
        this.mounted = false
        clearInterval(this.interval)
    }

    render() {
        const {
            loggedIn,
            history,
            i18n
        } = this.props

        const {
            latestBroofs,
            totalBroofsIssued,
            index
        } = this.state
        const mobile = window.innerWidth < 500

        return (
            <div className='home-container'>
                <div className='top-btn mobile' onClick={scrollToTop}>
                    <MdKeyboardArrowUp />
                </div>
                
                {/* <div className='box-container'>
                    <div className='box left'></div>
                    <div className='box right'></div>
                </div> */}
                
                {!mobile && <div className='top-back-container'>
                    <img src={homeTopText} alt='top' />
                </div>}

                <div className='content'>
                    <div>
                        <div className='left'>
                            <div>
                                We keep your<br />
                                time and honor
                            </div>
                            <Button
                                content={i18n.home.start}
                                style={{ width: 240 }}
                                type={ButtonType.FILL}
                                color='white'
                                onClick={() => {
                                    gtag('event', 'start_now')
                                    if (loggedIn) {
                                        history.push(ROUTE.project)
                                    }
                                    else {
                                        history.push(ROUTE.login)
                                    }
                                }}
                            />
                        </div>
                        <div className='right'>
                            <div className='description' ref={ref => {if (ref) ref.innerHTML = i18n.home.status(totalBroofsIssued)}}>
                            </div>
                            <div className='list'>
                                {(latestBroofs || []).slice(index, index + 7).reverse().map((item, index) => <div key={index}>{item.recipientName} / {item.projectName}</div>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mobile service'>
                    {i18n.home.service}<br />#broof
                </div>
                {!mobile &&
                    <Fragment>
                        <div className='flow back-line'></div>
                        <div className='flow bottom-image'>
                            <img src={flowDescription} alt='work-flow' />
                        </div>
                    </Fragment>
                }
                <div className='flow'>
                    <div>
                        <div className='top'>
                            <div ref={ref => {if (ref) ref.innerHTML = i18n.home.description1}}></div>
                            <div><div ref={ref => {if (ref) ref.innerHTML = i18n.home.description2(mobile)}}></div></div>
                            <div><div ref={ref => {if (ref) ref.innerHTML = i18n.home.description3}}></div></div>
                            <div><div ref={ref => {if (ref) ref.innerHTML = i18n.home.description4(mobile)}}></div></div>
                        </div>
                        <div className='center'>
                            {mobile &&
                                <Fragment>
                                    <p className='center-title'>#broof {i18n.home.workTitle}</p>
                                    <Slider position='center' />
                                </Fragment>
                            }
                            {!mobile &&
                                <Fragment>
                                    <div ref={ref => {if (ref) ref.innerHTML = i18n.home.work1}}></div>
                                    <div ref={ref => {if (ref) ref.innerHTML = i18n.home.work2}}></div>
                                    <div ref={ref => {if (ref) ref.innerHTML = i18n.home.work3}}></div>
                                    <div ref={ref => {if (ref) ref.innerHTML = i18n.home.work4}}></div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
                {!mobile &&
                    <div className='praise'>
                        {i18n.home.praise.map((item, index) => {
                            return (
                                <div key={index}>
                                    <GoQuote />
                                    <div>{item.text}</div>
                                    <div>{item.agency} / {item.project}</div>
                                </div>
                            )
                        })}
                    </div>
                }
                {mobile &&
                    <Fragment>
                        <div className='bottom'>
                            <div className='description' ref={ref => {if (ref) ref.innerHTML = i18n.home.statusBottom(totalBroofsIssued)}}></div>
                            <div className='partner_mobile'>
                                <img src={partnersListMobile} alt='partners'></img>
                            </div>
                            <Slider position='bottom' />
                            <div className='mobile-footer'>
                                <span># broof</span>
                                <a href={`mailto:${EMAIL}`}>{EMAIL}<span role="img" aria-label="">😍</span></a>
                            </div>
                        </div>
                    </Fragment>
                }
                {
                    !mobile &&
                    <Fragment>
                        <div className='partner'>
                            <div>
                                <img src={partnersList} alt='partners' />
                            </div>
                        </div>
                        <Footer />
                    </Fragment>
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn.payload,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLoggedIn: payload => dispatch(setLoggedIn(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Home)))