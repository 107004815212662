import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Lefter from '../../parts/Lefter'
import ProjectList from './List'
import ProjectCreate from './Create'
import './index.scss'
import { getProjectList } from '../../../redux';
import { PROJECT_ROUTE } from '../../../others/const';

class Project extends Component {
    componentWillMount() {
        this.props.getProjectList()
    }

    getContent = () => {
        const { pathname } = this.props.location
        if (pathname.indexOf(PROJECT_ROUTE.create) !== -1) {
            return <ProjectCreate />
        }

        const { loading, projectList } = this.props
        if (pathname.indexOf(PROJECT_ROUTE.edit) !== -1) {
            const { projectId } = this.props.match.params
            return <ProjectCreate {...projectList[projectId]} />
        }

        return <ProjectList parentLoading={loading} />
    }

    render() {
        return (
            <div className='project-container has-menu-container'>
                <Lefter />
                {this.getContent()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { 
        loading: state.projectList.loading,
        projectList: state.projectList.payload.list 
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProjectList: payload => dispatch(getProjectList(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Project))