import { createStore, compose, applyMiddleware} from 'redux'
import persistState from 'redux-localstorage'
import createSagaMiddleware from 'redux-saga'
import { reducer, initialState } from './'
import rootSaga from '../saga';
import ApiManager from '../ApiManager';
import { getLanguage } from '../others/util';

const sagaMiddleware = createSagaMiddleware()
const enhancer = compose(
    applyMiddleware(sagaMiddleware),
    persistState(null, {
        slicer: () => state => {
            return { 
                loggedIn: state.loggedIn.payload,
                language: state.language.payload
            }
        },
        merge: (initial, persist) => {
            const { loggedIn, language } = persist || {}
            return {
                ...initial,
                loggedIn: { 
                    payload: loggedIn && !!ApiManager.jwt
                },
                language: {
                    payload: language || getLanguage()
                }
            }
        }
    })
)

export const store = createStore(reducer, initialState, enhancer)
sagaMiddleware.run(rootSaga)