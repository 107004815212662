import React from 'react'
import Button, { ButtonType } from '../../parts/Button'
import { numberWithCommas, getPayAmount, getBonus } from '../../../others/util'
import withLanguage from '../../../HOC/withLanguage';
import './Ticket.scss'

const Ticket = ({ count, bonus, highlight, price, discount, currency, onPayClick, i18n }) => {
    const unit = currency ? 'ICX' : i18n.ticket.krw
    const original = getPayAmount(price, currency)
    const discounted = getPayAmount(price, currency, discount)
    const finalBonus = getBonus(bonus, currency)
    return (
        <div className='payment-ticket'>
            <div className={`top-header${highlight ? ' highlight' : ''}`}>
                {i18n.payment.ticketText(count, finalBonus)}
            </div>
            <div className='bottom-content'>
                <div className='price'>
                    <div>
                        <div className='label'>
                            <span>{i18n.ticket.open}</span>
                            <span>{numberWithCommas(original)} {unit}</span>
                        </div>
                        <div className='sum'>
                            <span>{numberWithCommas(discounted)}</span>
                            <span>{unit}</span>
                        </div>
                    </div>
                </div>
                <Button onClick={onPayClick} 
                    content={i18n.ticket.pay} 
                    type={highlight ? ButtonType.FILL : ButtonType.LINE}
                />
            </div>
        </div>
    )
}

export default withLanguage(Ticket)