import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ROUTE, PROJECT_ROUTE } from '../../others/const'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { MdAdd } from 'react-icons/md'
import './Lefter.scss'
import { getUserData, setLoggedIn } from '../../redux';
import logOut from '../../images/log_out.svg'
import ApiManager from '../../ApiManager'
import withLanguage from '../../HOC/withLanguage';
import gtag from '../../others/gtag';

class Lefter extends Component {
    render() {
        const { 
            history, 
            location, 
            userData,
            i18n
        } = this.props

        return (
            <div className='lefter'>
                <div className='title'>
                    <div className='hi'>{i18n.lefter.hello}</div>
                    <div className='user'>{i18n.lefter.user(userData.alias)}</div>
                    <div
                        className='new-button'
                        onClick={() => {
                            gtag('event', 'new')
                            history.push(`${ROUTE.project}${PROJECT_ROUTE.create}`)
                        }}
                    >
                        <MdAdd /><span>{i18n.lefter.new}</span>
                    </div>
                </div>
                <div className='coupon'>
                    <div className='top'>
                        <span>{i18n.lefter.mine}</span>
                        <span onClick={() => { 
                            gtag('event', 'buy')
                            history.push(ROUTE.payment) 
                        }}>
                            {i18n.lefter.buy} <MdKeyboardArrowRight />
                        </span>
                    </div>
                    <div className='bottom' ref={ref => { if (ref) ref.innerHTML = i18n.lefter.balance(userData.balance) }}></div>
                </div>
                {Object.keys(i18n.lefter.menu).map(key => {
                    const { icon, name, route } = i18n.lefter.menu[key]
                    const MenuIcon = icon
                    const selected = location.pathname.indexOf(route) !== -1
                    return (
                        <Link to={route} key={key} className={`menu${selected ? ' selected' : ''}`}>
                            <MenuIcon />
                            <span>{name}</span>
                            {selected && <MdKeyboardArrowRight />}
                        </Link>
                    )
                })}
                <div 
                    className='logout'
                    onClick={() => {
                        ApiManager.unsetToken()
                        this.props.setLoggedIn(false)
                        history.push(ROUTE.login)
                    }}
                >
                    <img src={logOut} alt='logout'/><span>{i18n.lefter.logout}</span>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userData.payload,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUserData: payload => dispatch(getUserData(payload)),
        setLoggedIn: payload => dispatch(setLoggedIn(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Lefter)))