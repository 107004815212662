import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import InputSet from '../parts/InputSet'
import Button from '../parts/Button'
import { ROUTE, EMAIL } from '../../others/const';
import ApiManager from '../../ApiManager';
import { setLoggedIn, setUserData, setPopup, addToast } from '../../redux'
import './Login.scss'
import Footer from '../parts/Footer'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import qs from 'qs'
import withLanguage from '../../HOC/withLanguage';
import gtag from '../../others/gtag';

class Login extends Component {
    state = {
        username: '',
        usernameError: '',
        password: '',
        passwordError: '',
        passwordVisible: false
    }

    async componentDidMount() {
        const { token } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        if (!token) return

        const { i18n, language } = this.props
        const { result, error } = await ApiManager.getUsersVerification(token)
        if (result) {
            const { email: username } = result
            this.setState({ username })
            this.props.setPopup({
                ...i18n.login.congratulation(username) 
            })
        }
        else {
            switch (error) {
                case 'expiredToken':
                    this.props.setPopup({
                        ...i18n.login[error](),
                        confirmCallback: async () => {
                            await ApiManager.postUsersEmailAccountVerification(token, language)
                        }
                    })
                    break
                default:
            }
        }
    }

    onClickPasswordEye = () => {
        this.setState({
            passwordVisible: !this.state.passwordVisible
        })
    }

    handleChange = e => {
        const { name, value } = e.target
        // this.setState({ [name]: value, [`${name}Error`]: '' })
        this.setState({ [name]: value, usernameError: '', passwordError: '' })
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.onConfirmClick()
        }
    }

    handleClick = () => {
        const { i18n } = this.props
        this.props.setPopup({
            ...i18n.login.precious(EMAIL),
            confirmCallback: () => {
                window.location.assign(`mailto:${EMAIL}`)
            }
        })
    }

    onConfirmClick = async () => {
        const { username, password } = this.state
        const { i18n } = this.props
        const { login, ERROR } = i18n
        const {
            usernameError,
            passwordError
        } = login

        if (!username) {
            this.setState({ usernameError })
            return
        }

        if (!password) {
            this.setState({ passwordError })
            return
        }

        gtag('event', 'login_ok')
        const { result, error } = await ApiManager.postUsersLogin({ username, password })

        if (error) {
            switch (error) {
                case 'invalidPassword':
                    this.setState({ passwordError: ERROR[error] })
                    break;
                case 'noUsername':
                case 'unauthorized':
                default:
                    this.setState({ usernameError: ERROR[error] })
            }
        }
        else {
            this.props.setLoggedIn(true)
            this.props.setUserData(result)
            this.props.addToast({ text: i18n.login.successToast })

            // parse query params
            const redirectParam = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true
            }).redirect;

            // redirect to '/certificate' if query params are empty
            const location = redirectParam || ROUTE.project;
            this.props.history.push(location)
        }
    }

    onLinkClick = () => {
        gtag('event', 'signup')
        this.props.history.push('signup')
    }

    render() {
        const { i18n } = this.props
        return (
            <Fragment>
                <div className='no-menu-container'>
                    <InputSet
                        name='username'
                        title={i18n.login.idTitle}
                        context='login'
                        type='text'
                        value={this.state.username}
                        error={this.state.usernameError}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                    />
                    <InputSet
                        name='password'
                        title={i18n.login.passwordTitle}
                        context='login'
                        type={this.state.passwordVisible ? 'text' : 'password'}
                        value={this.state.password}
                        error={this.state.passwordError}
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                        eye={this.state.passwordVisible ?
                            <FaEye onClick={this.onClickPasswordEye} /> :
                            <FaEyeSlash onClick={this.onClickPasswordEye} />
                        }
                    />
                    <Button
                        onClick={this.onConfirmClick}
                        content={i18n.login.confirmText}
                        style={{ width: '100%' }}
                    />
                    <button className="sign-up" onClick={this.onLinkClick}>{i18n.login.signup}</button>
                    <span className='span-division'>|</span>
                    <button className="find" onClick={this.handleClick}>{i18n.login.find}</button>
                </div>
                <Footer />
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        setLoggedIn: payload => dispatch(setLoggedIn(payload)),
        setUserData: payload => dispatch(setUserData(payload)),
        setPopup: payload => dispatch(setPopup(payload)),
        addToast: payload => dispatch(addToast(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLanguage(Login)))