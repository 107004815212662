import React, { Component } from 'react'
import { connect } from 'react-redux'
import Preview from '../Project/Create/Template/Preview';
import { getBroof } from '../../../redux'
import './index.scss'
import { PREVIEW_MODE } from '../../../others/const';
import CoffeeCup from '../../parts/CoffeeCup'
import { codeInputTest } from '../../../others/regexr';
import wrongWayFace from '../../../images/wrong_way_face.png'
import withLanguage from '../../../HOC/withLanguage';

class Broof extends Component {
	state = {
		page: 1,
		name: '',
		code: '',
		transactionHash: '',
		extraInfo: '',
	}

	componentWillMount() {
		const projectCode = window.location.pathname.split('/')[1]
		this.props.getBroof({ projectCode })
	}

	setPreviewPage = page => {
		this.setState({ page })
	}

	handleChange = e => {
		const { name, value } = e.target
		if (name === 'code' && !codeInputTest.test(value)) {
			return
		}
		this.setState({ [name]: value })
	}

	updateState = (key, value) => {
		this.setState({ [key]: value })
	}

	render() {
		const { i18n } = this.props
	
		const Content = () => {
			switch (true) {
				case this.props.loading:
					return <CoffeeCup />
				case this.props.error === 'ERROR':
					return <div className='error'>
						<div>
							<img src={wrongWayFace} alt='face' />
							<div>
								<p ref={ref => { if (ref) ref.innerHTML = i18n.broof.errorTitle }}></p>
								<p ref={ref => { if (ref) ref.innerHTML = i18n.broof.errorDescription }}></p>
							</div>
						</div>
					</div>
				default: {
					return <Preview
						{...this.props.broof}
						{...this.state}
						mode={PREVIEW_MODE.broof}
						handleChange={this.handleChange}
						setPreviewPage={this.setPreviewPage}
						updateState={this.updateState}
						sample={
							this.props.broof.projectCode === 'sample1' || 
							this.props.broof.projectCode === 'sample2' 
						}
					/>
				}
			}
		}

		return (
			<div className='broof-container'>
				{Content()}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		loading: state.broof.loading,
		broof: state.broof.payload,
		error: state.broof.error
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getBroof: payload => dispatch(getBroof(payload)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(Broof))