import { put, takeLatest, all, select } from 'redux-saga/effects'
import { ADD_TOAST, CLEAR_TOAST, setToast } from '../redux';
import uuidv4 from 'uuid/v4'
import { TOAST_LENGTH } from '../others/const';

function* addToast(action) {
    const toast = [ ...yield select(state => state.toast.payload) ]
    if (toast.length >= TOAST_LENGTH) {
        toast.pop()
    }
    action.payload.id = uuidv4()
    toast.unshift(action.payload)
    yield put(setToast(toast))
}

function* clearToast(action) {
    const toast = [ ...yield select(state => state.toast.payload) ]
    const index = toast.findIndex(item => item.id === action.payload)
    if (index !== -1) {
        toast.splice(index, 1);
        yield put(setToast(toast))    
    }
}

function* watchAddToast() {
    yield takeLatest(ADD_TOAST, addToast)
}

function* watchClearToast() {
    yield takeLatest(CLEAR_TOAST, clearToast)
}

export default function* root() {
    yield all([
        watchAddToast(),
        watchClearToast(),
    ])
}

